/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import isUrl from "is-url";
import { useField } from "formik";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ImageUploadStyled, ImageContainer, DeleteStyled } from "./styled";
import { Error } from "../Error";
import { reorderList } from "utils/reorderList";
import { showToast } from "utils/helpers";
import LabelForm from "components/RFQ/LabelForm/LabelForm";

export const Gallery = ({
	setFieldValue,
	name,
	images,
	max,
	isSubmitting,
	...props
}) => {
	const [files, setFiles] = useState(images || []);
	//eslint-disable-next-line
	const [field, meta] = useField(name);
	const [isDragging, setIsDragging] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const imageChecker = (arr1, arr2) => arr2.join("") === arr1.join("");

	//set the formik field value only once the internal state has been set
	useEffect(() => {
		if (!imageChecker(files, field.value)) {
			setFieldValue(name, files);
		} // eslint-disable-next-line
	}, [files]);

	useEffect(() => {
		if (!imageChecker(files, field.value)) {
			setFiles(field.value);
		} // eslint-disable-next-line
	}, [field.value]);

	const imageEl = useRef(null);

	const handleImgClick = (event, id) => {
		event.preventDefault();
		event.stopPropagation();
		setIsDeleting(true);

		setTimeout(() => {
			let filesModified = [...files];
			filesModified.splice(id, 1);
			setFiles(filesModified);
			setIsDeleting(false);
		}, 50);
	};

	const maxFileSize = 10485760;

	function nameSpecialValidator(file) {
		const specialRegex = /[!@#$%^&*+{}[\]:;<>,?~\\/]/;
		if (specialRegex.test(file.name)) {
			return {
				code: "special-characters",
				message: `File name have specials characters`,
			};
		}
		return null;
	}

	const { getRootProps, getInputProps } = useDropzone({
		multiple: true,
		disabled: isSubmitting,
		accept: "image/jpg",
		maxSize: maxFileSize,
		noClick: isDragging || isDeleting,
		validator: nameSpecialValidator,
		onDrop: (acceptedFiles, rejectedFiles) => {
			if (max && field.value.length + acceptedFiles.length > max) {
				return showToast(`Maximum of ${max} files allowed.`);
			}

			rejectedFiles.forEach((file) => {
				file.errors &&
					file.errors.forEach((error) => {
						if (error.code === "file-too-large") {
							showToast(`Maximum file size is ${maxFileSize / 1048576}MB.`);
						} else if (error.code === "special-characters") {
							showToast(
								`Image upload unsuccessful. Please ensure image file names do not contain any special characters.`
							);
						}
					});
			});

			setFiles([
				...files,
				...acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				),
			]);
		},
	});

	return (
		<div className={props.className}>
			<LabelForm label={"Images"} />
			<DragDropContext
				onDragEnd={(result) => {
					reorderList(files, result, setFiles);
					setIsDragging(false);
				}}
				onDragStart={() => {
					setIsDragging(true);
				}}
			>
				<Droppable
					droppableId="droppable-image"
					direction="horizontal"
				>
					{(provided) => (
						<ImageUploadStyled
							gallery
							{...getRootProps({
								className: "dropzone",
								onDragLeave: (e) => e.stopPropagation(),
							})}
							isSubmitting={isSubmitting}
							ref={provided.innerRef}
							className="!mb-1.5"
							{...provided.droppableProps}
						>
							<input
								{...getInputProps()}
								data-testid="image-upload"
							/>

							{files && files.length
								? files.map((file, i) => {
										return (
											<Draggable
												key={i}
												draggableId={`${i}`}
												index={i}
											>
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<ImageContainer
															key={i}
															ref={imageEl}
														>
															<img
																src={isUrl(file) ? file : file?.preview}
																alt={file?.name}
															/>
															<DeleteStyled
																onClick={(e) => {
																	if (!isSubmitting) {
																		e.preventDefault();
																		e.stopPropagation();
																		handleImgClick(e, i);
																	}
																}}
															/>
														</ImageContainer>
													</div>
												)}
											</Draggable>
										);
									})
								: "Drag and drop an image here, or click to select."}
						</ImageUploadStyled>
					)}
				</Droppable>
			</DragDropContext>

			<p className="mb-5 text-[13px]">
				A maximum of {max} images can be uploaded for each project. The file
				size for each image should not exceed {maxFileSize / 1048576}MB.
			</p>

			<Error meta={meta} />
		</div>
	);
};
