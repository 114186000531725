import React from "react";
import Select from "react-select";
import { useField, useFormikContext } from "formik";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import { ReactComponent as InfoIcon } from "../../../icons/info.svg";
import ReactTooltip from "react-tooltip";
import { Error } from "../Error";
import LabelForm from "components/RFQ/LabelForm/LabelForm";
import { cn } from "utils/helpers";
import { createSelectStyles } from "../styles/selectStyles";

const animatedComponents = makeAnimated();

export const MultiSelect = ({
	options,
	name,
	defaultValue,
	capitalize,
	value,
	freeText,
	guideLine,
	guideLineText,
	label,
	required,
	height,
	width,
	maxWidth,
	disabled,
	isGuideLineLink,
	errorLeftAlign,
	sortingValue,
	errorBottom,
	errorText,
	testId,
	...props
}) => {
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(name);
	const isError = meta.touched && meta.error;

	const customStyles = createSelectStyles({ isError, maxWidth });

	const handleOnChange = (values) => {
		//create array of values for formik
		const valuesArray = values ? values.map((value) => value.value) : [];
		const sortArray = valuesArray.sort((a, b) => a - b);
		setFieldValue(name, props.sortingValue ? sortArray : valuesArray);
	};

	const SelectComponent = props.creatable ? CreatableSelect : Select;

	if (options && field && Array.isArray(field.value)) {
		value = field.value.map((id) => {
			return options.find((option) => {
				return option.value === id;
			});
		});
	}

	return (
		<div className={cn("relative", props.className, isError && "pb-0.5")}>
			{label && (
				<LabelForm
					required={required}
					label={label}
				>
					<div>
						{guideLine && isGuideLineLink && (
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={guideLine}
							>
								<InfoIcon
									data-tip
									data-for={name}
								/>
							</a>
						)}
					</div>
					{guideLineText && (
						<InfoIcon
							data-tip
							data-for={name}
						/>
					)}
					{guideLineText && (
						<ReactTooltip
							className="guide"
							id={name}
							place="top"
							arrowColor="transparent"
						>
							{guideLineText}
						</ReactTooltip>
					)}
				</LabelForm>
			)}
			<SelectComponent
				isMulti
				styles={customStyles}
				closeMenuOnSelect={false}
				components={{
					...animatedComponents,
					IndicatorSeparator: () => null,
					// DropdownIndicator: () => null
				}}
				options={
					freeText && options
						? options.map((option) => ({ value: option, label: option }))
						: options
				}
				onChange={handleOnChange}
				//if using a creatable free text input - we need to structure the data properly for react-select e.g {value: '', label: ''}
				value={
					freeText && value
						? field.value.map((text) => ({ value: text, label: text }))
						: value
				}
				capitalize={capitalize}
				noOptionsMessage={() => null}
				className={name}
				data-testid={testId}
			/>
			<Error
				errorBottom={errorBottom}
				meta={meta}
				text={errorText}
			/>
		</div>
	);
};
