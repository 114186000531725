import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getListKeyPersonnels } from "actions/RFQ/Supplier/rfqActions";
import {
	SmartFieldQuestionnaireBase,
	useSmartFieldSelection,
	filterSmartFieldOptionForSubmittedRFQ,
} from "./SmartFieldQuestionnaireBase";
import {
	KeyPersonnel,
	KeyPersonnelSkeleton,
} from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/Questionnaire/_components/KeyPersonnel";
import { RFQContext } from "context/RFQContext";

export const SmartFieldKeyPersonnel = ({
	className,
	question,
	required,
	...props
}) => {
	const [search, setSearch] = useState("");
	const [selectedFilter, setSelectedFilter] = useState([]);
	const companyId = useSelector((state) => state?.search?.activeCompany?.id);
	const { rfqId } = useContext(RFQContext);

	const { value, error, touched, isSelected, selectHandler } =
		useSmartFieldSelection(props);

	// Prepare query parameters
	const queryParams = {
		search: search,
		filters: selectedFilter,
		replacement: 1,
		rfq_id: rfqId,
	};

	// Fetch key personnel data
	const {
		data,
		isLoading,
		error: queryError,
	} = useQuery({
		queryKey: ["keyPersonnel", queryParams],
		queryFn: getListKeyPersonnels,
	});

	// Process data
	const keyPersonnels = data?.key_personnels || [];
	const filteringList = data?.filters || [];

	// Filter data for preview mode
	const filteredData = filterSmartFieldOptionForSubmittedRFQ(
		keyPersonnels,
		value,
		props?.disabled
	);

	return (
		<SmartFieldQuestionnaireBase
			className={className}
			question={question}
			required={required}
			description="Please choose key personnel that might work on this project."
			searchPlaceholder="Search name, position or project"
			isLoading={isLoading}
			filteringList={filteringList}
			selectedFilter={selectedFilter}
			setSelectedFilter={setSelectedFilter}
			setSearch={setSearch}
			error={error}
			touched={touched}
			{...props}
		>
			{isLoading ? (
				[...Array(3)].map((_, index) => <KeyPersonnelSkeleton key={index} />)
			) : queryError ? (
				<div className="text-red-500">
					Error loading personnel: {queryError.message || "Unknown error"}
				</div>
			) : filteredData.length === 0 ? (
				<div className="text-gray-500">No personnel found</div>
			) : (
				filteredData.map((personnel) => (
					<KeyPersonnel
						key={personnel.id}
						companyId={companyId}
						values={personnel}
						rfqSubmission={true}
						selected={isSelected(personnel?.id)}
						selectHandler={() => selectHandler(personnel?.id)}
						isPreview={props?.disabled}
						supplierSmartField
					/>
				))
			)}
		</SmartFieldQuestionnaireBase>
	);
};
