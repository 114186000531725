import * as Yup from "yup";
import { isArray, isFile, isSmartField } from "utils/validation";

export const allValidationSchema = (
	returnable,
	quote,
	isGuest,
	hasDocuments,
	hasPasProjects,
	hasResources
) => {
	const quoteValidation = {
		quote_file: Yup.mixed().when("quote_type", {
			is: (value) => value === "file",
			then: Yup.mixed()
				.required()
				.test("len", "This field is required", (val) => val && val.length > 0),
		}),
		quote_answer: Yup.mixed().when("quote_type", {
			is: (value) => value === "form",
			then: Yup.object().shape(quotesSchema(quote)),
		}),
	};

	const returnableScheduleValidation = {
		returnable_schedule_file: Yup.mixed().when("returnables_type", {
			is: (val) => val === "file",
			then: Yup.mixed()
				.required()
				.test("len", "This field is required", (val) => val && val.length > 0),
		}),

		question_answer: Yup.mixed().when("returnables_type", {
			is: (val) => val === "form",
			then: Yup.object().shape(
				returnableSchema(
					returnable,
					isGuest,
					hasDocuments,
					hasPasProjects,
					hasResources
				)
			),
		}),
	};

	const validationSchema = Yup.object().shape({
		...quoteValidation,
		...returnableScheduleValidation,
	});
	return validationSchema;
};

export const returnableSchema = (
	returnableList,
	isGuest,
	hasDocuments,
	hasPasProjects,
	hasResources
) => {
	let returnableSchema = {};

	const validateTextAndTextArea = (required, maxLimit) => {
		if (required) {
			return Yup.string()
				.required("Required")
				.nullable()
				.test("len", "Input has exceeded the max character", (val) =>
					val ? val?.replace(/<\/?[^>]+>/gi, "").length <= maxLimit : false
				);
		}

		return Yup.string()
			.nullable()
			.test("len", "Input has exceeded the max character", (val) =>
				val ? val?.replace(/<\/?[^>]+>/gi, "").length <= maxLimit : true
			);
	};

	const schemaCheck = (
		type,
		required,
		hasDocuments,
		hasPasProjects,
		hasResources
	) => {
		if (isArray(type)) {
			return required
				? Yup.array()
						.of(Yup.string("Required").required("Required"))
						.min(1, "Required")
				: Yup.array().of(Yup.string());
		} else if (isSmartField(type)) {
			if (isGuest) {
				if (type === "corporate_documents") {
					return required
						? Yup.array()
								.of(Yup.mixed("Required").required("Required"))
								.test("empty", "Required", (value) => value?.length > 0)
						: Yup.array().of(Yup.mixed());
				} else {
					return required ? Yup.mixed().required("Required") : Yup.mixed();
				}
			} else {
				/**
				 * if supplier but no documents show upload field
				 */
				if (type === "corporate_documents" && !hasDocuments) {
					return required
						? Yup.mixed().required("Required")
						: Yup.array().of(Yup.mixed());
				} else if (
					(type === "projectexp" || type === "project_experience") &&
					!hasPasProjects
				) {
					return validateTextAndTextArea(required, 4500);
				} else if (
					(type === "keypersonel" || type === "key_personnel") &&
					!hasResources
				) {
					return validateTextAndTextArea(required, 4500);
				} else {
					return required
						? Yup.array()
								.nullable()
								.test(
									"is-non-empty-array",
									"Please select at least one",
									(val) => {
										if (val === null) {
											return false;
										}
										return Array.isArray(val) && val.length > 0;
									}
								)
						: Yup.array().of(Yup.number());
				}
			}
		} else if (isFile(type)) {
			return required
				? Yup.array()
						.of(Yup.mixed("Required").required("Required"))
						.test("empty", "Required", (value) => value?.length > 0)
				: Yup.array().of(Yup.mixed());
		} else if (type === "table") {
			return Yup.mixed().nullable();
		} else if (type === "text") {
			return validateTextAndTextArea(required, 500);
		} else if (type === "textarea") {
			return validateTextAndTextArea(required, 1500);
		} else {
			return required ? Yup.mixed().required("Required") : Yup.mixed();
		}
	};

	returnableList?.forEach((section) => {
		section.question?.forEach((question) => {
			const questionType = question?.fields?.type;

			if (questionType === "table") {
				question?.fields?.table[0]?.values?.forEach((_, index) => {
					returnableSchema[`question-${question.id}-${index}`] =
						Yup.string().required();
				});
			}

			if (
				isSmartField(questionType) &&
				((questionType === "corporate_documents" && hasDocuments) ||
					(questionType === "project_experience" && hasPasProjects) ||
					(questionType === "key_personnel" && hasResources)) &&
				!isGuest
			) {
				returnableSchema[`question-${question.id}`] = Yup.mixed().test(
					"either-main-or-additional",
					"Please select at least one",
					function (value) {
						const additionalValue =
							this.parent?.[`question-${question.id}.additional`];
						return (
							(Array.isArray(value) && value.length > 0) ||
							(Array.isArray(additionalValue) && additionalValue.length > 0)
						);
					}
				);
			} else {
				returnableSchema[`question-${question.id}`] = schemaCheck(
					questionType,
					question.required,
					hasDocuments,
					hasPasProjects,
					hasResources
				);
			}
		});
	});

	return returnableSchema;
};

export const quotesSchema = (quoteList) => {
	let quotesSchema = {};

	quoteList?.forEach((quote) => {
		return quote.items.forEach((item) => {
			return (quotesSchema[`quote-${item.id}`] = Yup.object().shape({
				quantity: Yup.number("Required")
					.required("Required")
					.test("is-positive", "Required", (value) => parseFloat(value) > 0),
				rate: Yup.number("Required")
					.required("Required")
					.test("is-positive", "Required", (value) => parseFloat(value) > 0),
				unit: Yup.number("Required")
					.required("Required")
					.test("is-positive", "Required", (value) => parseInt(value) > 0),
			}));
		});
	});
	return quotesSchema;
};
