import React, { useEffect, useState, useRef } from "react";
import { useField, useFormikContext } from "formik";
import styled from "styled-components";
import { Error } from "../Error";
import { ReactComponent as InfoIcon } from "../../../icons/info.svg";
import ReactTooltip from "react-tooltip";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles-input.scss";
import {
	TextAreaContainer,
	WordCountStyled,
	TextAreaContainerWhite,
} from "../TextArea/styled";
import { cn } from "utils/helpers";
import LabelForm from "components/RFQ/LabelForm/LabelForm";
import { Icon } from "assets/icons/Icon";

export const FormLabel = styled.label`
	font-size: "14px";
	color: "#344054";
	font-weight: 600;
	display: grid;
	margin-bottom: ${(props) => props.theme.space.xxs};
	letter-spacing: ${(props) => (props.rfq ? "0px" : "1.125px")};

	> span {
		color: #dc2538;
		margin-left: 3px;
	}

	> svg {
		margin-left: 4px;
	}

	.guide {
		border: 0.5px solid #8492a6;
		border-radius: 4px;
		letter-spacing: 0.1px;
		font-family: ${(props) => props.theme.fonts.open_sauce_two};
		font-size: 13px;
		text-transform: none;
		padding: 4px 8px;
		font-weight: normal;
		max-width: 400px;
	}
`;

const TextCountComp = ({ characterCount, characterLimit }) => {
	const remainingCharacters = characterLimit - characterCount;
	const isOverLimit = remainingCharacters < 0;
	const sufix = Math.abs(remainingCharacters) === 1 ? "" : "s";

	return (
		<>
			<WordCountStyled isOverLimit={isOverLimit}>
				{`${Math.abs(remainingCharacters)} character${sufix} ${isOverLimit ? "over the limit" : "remaining"}`}
			</WordCountStyled>
		</>
	);
};

export const betterClearHTMLTags = (strToSanitize) => {
	let myHTML = new DOMParser().parseFromString(strToSanitize, "text/html");
	return myHTML.body.textContent || "";
};

export const FormatedInput = ({
	label,
	required,
	name,
	errorBottom,
	characterLimit, //number
	guideLine,
	setErrors,
	onMaxCharacter,
	rfq = false,
	placeholder,
	className,
	setFieldError,
	subTitle,
	background,
	capitalize,
	hideError,
	onChangeHandler,
	inputTestId,
	disabled = false,
	...props
}) => {
	const { setFieldTouched } = useFormikContext();
	const quillRef = useRef(null);
	const inputRef = useRef(null);
	const [field, meta, helpers] = useField(name);
	// height state for textarea
	const [height, setHeight] = useState(140);
	const [characterCount, setCharacterCount] = useState(
		meta.value ? betterClearHTMLTags(meta.value).length : 0
	);
	const limited = characterLimit - characterCount <= 0;
	if (characterLimit - characterCount <= -1 && !meta.error) {
		if (setFieldError) {
			setFieldError(name, "Input has reached max character");
		}
	}
	useEffect(() => {
		if (characterLimit - characterCount <= 0 && onMaxCharacter) {
			onMaxCharacter();
		}
	}, [characterCount, characterLimit, onMaxCharacter]);

	const onChange = (e) => {
		const newVal = e;
		const newLength = betterClearHTMLTags(newVal).length;
		helpers.setValue(newVal);
		setCharacterCount(newLength);
		// this props is required because helpers.setValue does not trigger formik form onchange event
		onChangeHandler && onChangeHandler();
		if (newLength === 0) {
			helpers.setValue("");
		}
	};
	function isValidKey(e) {
		if (limited) {
			var charCode = e.keyCode || e.which;
			if (charCode === 8 || charCode === 46) {
				return true;
			}
			e.preventDefault();
			return false;
		}
	}

	useEffect(() => {
		const editor = quillRef.current.getEditor();
		const quillRoot = editor.root;

		const handleKeyDown = (event) => {
			if (event.key === "Tab") {
				event.preventDefault();
				if (inputRef.current) {
					inputRef.current.focus();
				}
			}
		};

		quillRoot.addEventListener("keydown", handleKeyDown);

		return () => {
			quillRoot.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	const handleMouseDown = (e) => {
		e.preventDefault();
		const startY = e.clientY;
		const startHeight = height;

		const doDrag = (e) => {
			setHeight(Math.max(startHeight + e.clientY - startY, 140));
		};

		const stopDrag = () => {
			window.removeEventListener("mousemove", doDrag);
			window.removeEventListener("mouseup", stopDrag);
		};
		window.addEventListener("mousemove", doDrag);
		window.addEventListener("mouseup", stopDrag);
	};

	const textArea = (
		<>
			<ReactQuill
				onBlur={() => setFieldTouched(name, true)}
				ref={quillRef}
				style={{ height: height }}
				placeholder={placeholder}
				readOnly={disabled}
				modules={{
					toolbar: ["bold", "italic", { list: "ordered" }, { list: "bullet" }],
				}}
				className={`textarea custom-quill mb-4 [&_.ql-blank::before]:!text-gray-400 test_${name}`}
				value={field.value}
				onChange={onChange}
				onKeyPress={isValidKey}
				onKeyDown={isValidKey}
			/>

			<input
				ref={inputRef}
				type="text"
				placeholder="Next input field"
				style={{ width: "10px", height: "10px", opacity: 0 }}
			/>
			<div
				onMouseDown={handleMouseDown}
				style={{
					width: 24,
					height: 24,
					position: "absolute",
					bottom: 24,
					right: 24,
					cursor: "ns-resize",
				}}
			>
				<Icon
					icon="handle-corner"
					style={{ height: 24, width: 24 }}
				/>
			</div>
		</>
	);

	return (
		<div
			className={cn("group relative", className)}
			data-testid={inputTestId}
		>
			{label && (
				<>
					<LabelForm
						htmlFor={props.id || props.name}
						label={label}
						required={required}
						subTitle={subTitle}
						guideLine={guideLine}
					>
						<div>
							{guideLine && (
								<InfoIcon
									data-tip
									data-for={name}
								/>
							)}
						</div>
					</LabelForm>
					{subTitle && (
						<p className="-mt-1 mb-3 whitespace-pre-wrap text-sm font-normal text-gray-text-400">
							{subTitle}
						</p>
					)}
					<ReactTooltip
						className="guide"
						id={name}
						place="top"
						arrowColor="transparent"
					>
						{guideLine}
					</ReactTooltip>
				</>
			)}

			{characterLimit ? (
				<>
					{background !== undefined ? (
						<TextAreaContainerWhite
							isError={meta.error && meta.touched}
							background={background}
							className={props?.containerClassName}
						>
							{textArea}
							<TextCountComp
								characterCount={characterCount}
								characterLimit={characterLimit}
							/>
						</TextAreaContainerWhite>
					) : (
						<TextAreaContainer
							isError={meta.error && meta.touched}
							background={background}
							rfq={rfq}
							className={props?.containerClassName}
						>
							{textArea}
							<TextCountComp
								characterCount={characterCount}
								characterLimit={characterLimit}
							/>
						</TextAreaContainer>
					)}
				</>
			) : (
				textArea
			)}
			{!hideError && (
				<Error
					errorBottom={errorBottom}
					meta={meta}
					className={rfq && "!-mt-5 text-sm !text-utility-error-500"}
				/>
			)}
		</div>
	);
};
