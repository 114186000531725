import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { DatePicker } from "components/DatePicker/DatePicker";
import { cn } from "utils/helpers";
import useOnClickOutside from "utils/useClickOutside";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import { useField } from "formik";
import { Icon } from "assets/icons/Icon";
import { Flex } from "@chakra-ui/react";
import styled from "styled-components";
import LabelForm from "components/RFQ/LabelForm/LabelForm";

export const FormLabel = styled.label`
	font-size: ${(props) => (props.rfq ? "14px" : props.theme.fontSizes.xs)};
	color: ${(props) => (props.rfq ? "#344054" : props.theme.colors.grayLight)};
	font-weight: ${(props) => (props.rfq ? "600" : "700")};
	display: grid;
	margin-bottom: ${(props) => props.theme.space.xxs};
	letter-spacing: ${(props) => (props.rfq ? "0px" : "1.125px")};

	> span {
		color: #dc2538;
		margin-left: 3px;
	}

	> svg {
		margin-left: 4px;
	}

	.guide {
		border: 0.5px solid #8492a6;
		border-radius: 4px;
		letter-spacing: 0.1px;
		font-family: ${(props) => props.theme.fonts.open_sauce_two};
		font-size: 13px;
		text-transform: none;
		padding: 4px 8px;
		font-weight: normal;
		max-width: 400px;
	}
`;

export const DateInput = ({
	value,
	icon,
	placeholder,
	minDate,
	className,
	inputClassName,
	required,
	ranged,
	label,
	form,
	applyFormated,
	disabled = false,
	...props
}) => {
	const [field, meta, { setValue, setTouched }] = useField(props);
	const inputRef = useRef(null);
	const [dateValue, setDateValue] = useState(meta.value || null);
	const [showDate, setShowDate] = useState(false);
	const renderDate = dateValue
		? ranged
			? dateValue
					.map((d) => (d ? moment(d).format("MMM D, YYYY") : "  "))
					.join(" - ")
			: moment(dateValue).format("MMM D, YYYY")
		: placeholder || "Select Date";
	const closeHandler = () => {
		setShowDate(false);
	};
	const applyHandler = (value, close) => {
		setDateValue(value);
		setTouched(true);
		close && setShowDate(false);
		if (setValue) {
			if (applyFormated) {
				setValue(moment(value).format(applyFormated));
			} else {
				setValue(value);
			}
		}
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};
	const datePickerRef = useRef(null);

	useOnClickOutside(datePickerRef, () => {
		setShowDate(false);
	});

	useEffect(() => {
		if (meta.value === "") {
			setDateValue(null);
		}
	}, [meta.value]);

	const handleKeyDown = (event) => {
		if (disabled) {
			return;
		}
		if (event.key === "Enter" || event.key === "ArrowDown") {
			if (dateValue === null) {
				let cdate = new Date();
				setDateValue(cdate);
			}
			setShowDate(true);
			setTimeout(() => {
				var div = document.getElementById("today");
				if (div) {
					div.focus();
					div.scrollIntoView({ behavior: "smooth", block: "center" });
				}
			}, 100);
		}
	};

	return (
		<div className={cn("relative z-20 h-max w-full", className)}>
			<Popover
				isOpen={showDate}
				closeHandler={() => setShowDate(false)}
				openHandler={() => (!disabled ? setShowDate(true) : false)}
			>
				<PopoverTrigger>
					{label && (
						<LabelForm
							label={label}
							required={required}
						/>
					)}
					<div
						onKeyDown={handleKeyDown}
						className={`focusable rounded-md border p-4 focus:outline focus:outline-utility-blue-light-200 ${disabled ? "pointer-events-none bg-gray-300 bg-opacity-25" : ""} ${meta.error && meta.touched ? "border-red-400" : "border-fg-senary-300"} ${showDate && (meta.error && meta.touched ? "!border-utility-error-300 ring-4 ring-ring-red-primary" : "!border-utility-brand-300 ring-4 ring-ring-blue-primary")} ${inputClassName} `}
						data-testid={`date-selection-${props?.name}`}
					>
						<input
							type="date"
							className={cn("invisible absolute w-0")}
							value={dateValue}
							{...field}
							{...props}
						/>
						<Flex
							gap={3}
							className=""
						>
							{icon && <Icon icon="calendar" />}
							<p
								className={
									dateValue
										? "text-secondary-500"
										: "text-base text-placeholder"
								}
							>
								{renderDate}
							</p>
						</Flex>
					</div>
					{meta.error && meta.touched && (
						<p className="mt-1 text-sm text-utility-error-500">{meta.error}</p>
					)}
				</PopoverTrigger>
				<PopoverContent>
					<div>
						<DatePicker
							dates={dateValue}
							minDate={minDate}
							className="top-1 w-max"
							onApply={applyHandler}
							onCancel={closeHandler}
						/>
					</div>
				</PopoverContent>
			</Popover>
			<input
				ref={inputRef}
				type="text"
				placeholder="Next input field"
				style={{ width: "10px", height: "10px", opacity: 0 }}
			/>
		</div>
	);
};
