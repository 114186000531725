import React, { useState } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Heading } from "../Heading";
import { Button } from "../Button";
import { CompanySidebarStyled, CompanyImage, CompanySummary } from "./styled";
import { ContactDetails } from "../ContactDetails";
import { Modal } from "../Modal";
import { CompanyAboutCard } from "../CompanyAboutCard";
import { addToList } from "../../actions/searchActions";
import { TabContent } from "../Tabs/styled";
import { betterClearHTMLTags } from "components/FormInputs/FormatedInput";

export const CompanySidebarComponent = ({
	company,
	dispatch,
	addToList,
	groupId,
	...props
}) => {
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const openInfoModal = () => {
		setModalIsOpen(true);
	};

	const closeInfoModal = () => {
		setModalIsOpen(false);
	};
	const textDescription = betterClearHTMLTags(company?.description);
	const globalPresence = company.global_presence
		? company.global_presence.map((g) => g.name)
		: [];

	return (
		<TabContent className="px-0 pb-5 pt-0">
			<CompanySidebarStyled>
				{!props.isLoading ? (
					<>
						<CompanyImage>
							<img
								src={company.logo_path}
								alt=""
							/>
						</CompanyImage>
						<Heading>{company.name}</Heading>
						<CompanySummary className="font-roboto">
							{textDescription?.substring(0, 120)}
							{textDescription?.length > 119 && "..."}
						</CompanySummary>
						<Button
							size="sm"
							data-testid="read-more-profile"
							onClick={() => openInfoModal()}
							style={{ alignSelf: "flex-start" }}
						>
							Read More
						</Button>

						<div>
							{(company.years_trading || globalPresence.length > 0) && (
								<h5 className="mt-8">Company Information</h5>
							)}
							{company.years_trading && (
								<p className="mb-1 text-xs">
									Years Of Trading -{" "}
									<span className="font-normal">{company.years_trading}</span>
								</p>
							)}
							{globalPresence.length > 0 && (
								<p className="text-xs">
									Global Presence -{" "}
									<span className="font-normal">
										{globalPresence.join(", ")}
									</span>
								</p>
							)}
							<h5 className="mt-8">Contact Information</h5>
							<ContactDetails
								phone={company.phone}
								email={company.email}
								address={company.locations && company.locations[0]?.address}
								website={company.website}
							/>
						</div>

						<Modal
							isOpen={modalIsOpen}
							onRequestClose={closeInfoModal}
							width="800"
						>
							<CompanyAboutCard
								content={company.description}
								logo={company.logo_path}
								bg={company.banner_path}
							/>
						</Modal>
					</>
				) : (
					<Skeleton
						count={5}
						width={window.width < 1600 ? 350 : 250}
						duration={1}
					/>
				)}
			</CompanySidebarStyled>
		</TabContent>
	);
};

const mapStateToProps = (state) => {
	return {
		groupId: state.search.groupId,
		company: state.search.activeCompany,
		isLoading: state.search.isLoading,
	};
};

export const CompanySidebar = connect(mapStateToProps, { addToList })(
	CompanySidebarComponent
);
