import { cn } from "utils/helpers";
import { useField } from "formik";
import { MultipleCheckbox } from "components/RFQ/MultipleCheckbox";

export const MultipleChoiceQuestionnaire = ({
	className,
	description,
	question,
	required,
	options,
	fieldValue,
	...props
}) => {
	const [, { error }] = useField(props);

	return (
		<div
			className={cn(!props?.rfqSubmission && "space-y-2", className)}
			data-testid={`header-${props?.name}`}
		>
			<h1 className="text-lg font-semibold text-secondary-700">
				{question}
				<span
					className={cn(
						"font-normal text-tertiary-600",
						error && props?.touchedField && "font-semibold text-red-600"
					)}
				>
					{required && <span className="text-red-600">{`*`}</span>}
					{required ? " (Required)" : " (Optional)"}
				</span>
			</h1>
			{props?.rfqSubmission && (
				<p className="text-sm text-tertiary-600">(Select one or more option)</p>
			)}
			<div>
				<MultipleCheckbox
					name={props?.name || "questionnaire"}
					checkboxes={fieldValue.map((item, i) => ({
						label: item,
						value: i.toString(),
					}))}
					className="my-4 w-full"
					noAttribute
					border
					disabled={props?.disabled}
					{...props}
				/>
			</div>
		</div>
	);
};
