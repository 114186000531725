import React from "react";
import { Input } from "../Input";
import { connect } from "react-redux";

const SelectTemplate = ({
	selectedRFQOptions,
	setSelectedRFQOptions,
	setSelectedTemplate,
	template,
}) => {
	const createTemplateOptions = [
		{ value: "createRFQ", label: "Create my own RFx", icon: "text-input" },
		{ value: "templateRFQ", label: "Import from template", icon: "template" },
	];

	const templateOptions = template?.map((item) => ({
		value: item.id,
		label: item.name,
	}));

	const handleChangeRFQOption = (e) => {
		setSelectedRFQOptions(e);
		if (e.value === "createRFQ") {
			setSelectedTemplate(null);
		}
	};
	const handleChangeRFQTemplate = (e) => {
		setSelectedTemplate(e);
	};

	return (
		<div>
			<div className="mb-8 gap-5 rounded-md bg-utility-blue-light-100 p-8">
				<div className="mb-8">
					<p className="font-normal">Select an an option below</p>
				</div>
				<div className="space-y-5">
					<Input
						onChange={handleChangeRFQOption}
						type="select"
						options={createTemplateOptions}
						isContainImg={true}
						checkIcon={true}
						placeholder="Select an option"
						value={selectedRFQOptions}
						testId="select-rfq-option"
					/>

					{selectedRFQOptions?.value === "templateRFQ" && (
						<Input
							onChange={handleChangeRFQTemplate}
							type="select"
							options={templateOptions}
							isDisabled={template?.length === 0}
							checkIcon={true}
							placeholder={
								template?.length === 0
									? "No template available"
									: "Select a template"
							}
							testId="select-rfq-template"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		template: state.rfq.template?.template,
		isLoading: state.rfq.projects.loading,
	};
};

export default connect(mapStateToProps)(SelectTemplate);
