import { Box, Flex, Spinner, useDisclosure } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import ReturnableSchedulesCard from "components/Pages/CreateRFQ/ReturnableSchedulesCard/ReturnableSchedulesCard";
import React, { useContext, useEffect, useState } from "react";
import tw, { styled } from "twin.macro";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AddSectionModal } from "components/Pages/CreateRFQ/AddSectionModal";
import { reorderList } from "utils/reorderList";
import { useField } from "formik";
import { Heading } from "components/Heading";
import { Button } from "components/Button";
import { getSortedReturnables } from "./ReturnableSchedulesHelpers";
import { FieldForm } from "components/RFQ/Input";
import { FileUpload } from "components/RFQ/FileUpload";
import { RFQContext } from "context/RFQContext";
import { removeProgressFromUploading } from "utils/rfxLargeFileUploadHelper";

const ProjectDetailContainer = styled.div(() => [tw`flex flex-col w-full`]);

const ButtonAddContent = ({
	onOpen,
	setModalType,
	addSectionHandler,
	disableAddQuestion,
}) => {
	const onClickAddHandler = (type) => {
		setModalType(type);
		onOpen();
	};

	const onKeyDown = (event, menu) => {
		if (event.key === "Enter") {
			if (menu) {
				event.preventDefault();
				onClickAddHandler(menu);
			} else {
				addSectionHandler();
			}
		}
	};

	return (
		<Box className="card-shadow z-40 flex-col rounded-md bg-white p-2">
			<Button
				type="button"
				btntype="link"
				className={`[transition-duration: 0.18s] relative !flex !w-full cursor-pointer items-center !justify-start gap-2 p-3 transition-all hover:rounded-md hover:bg-utility-blue-light-50 ${disableAddQuestion && "pointer-events-none opacity-50"}`}
				onClick={() => onClickAddHandler("question")}
				onKeyDown={(event) => onKeyDown(event, "question")}
				data-testid="add-question"
			>
				<Icon
					icon="plus-circle"
					style={{ color: "#475467" }}
				/>
				<p
					className="font-roboto font-medium text-secondary-700"
					style={{ fontSize: "14px", marginTop: "1px" }}
				>
					Add Question
				</p>
			</Button>
			<Button
				type="button"
				btntype="link"
				className="[transition-duration: 0.18s] relative !flex !w-full cursor-pointer items-center !justify-start gap-2 p-3 transition-all hover:rounded-md hover:bg-utility-blue-light-50"
				onClick={() => addSectionHandler()}
				onKeyDown={(event) => onKeyDown(event)}
				data-testid="add-section"
			>
				<Icon
					icon="section"
					style={{ color: "#475467" }}
				/>
				<p
					className="font-roboto font-medium text-secondary-700"
					style={{ fontSize: "14px", marginTop: "1px" }}
				>
					Add Section
				</p>
			</Button>
		</Box>
	);
};

const QuestionerContainer = styled.div(({ isDisabled }) => [
	!isDisabled ? tw`opacity-100` : tw`opacity-50 pointer-events-none`,
]);

const returnableSchedulesType = [
	{
		label: "Create my own returnable schedules format",
		value: "form",
		icon: "text-input",
	},
	{ label: "Upload Template", value: "file", icon: "template" },
];

const ReturnableSchedules = ({
	titlePage,
	subTitlePage,
	setFieldError,
	setFieldValue,
}) => {
	const isLoading = false;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [modalType, setModalType] = useState("section");
	const [, { value: sectionType }] = useField("returnable_schedule_type");
	const { rfqId, setUploadInProgresses } = useContext(RFQContext);

	const [, meta, helper] = useField(`sections`);
	const sectionsValue = meta.value;

	useEffect(() => {
		sectionType === "form"
			? setFieldError("returnable_schedule_file", undefined)
			: setFieldError("sections", undefined);

		// eslint-disable-next-line
	}, [sectionType]);

	const addSectionHandler = () => {
		const newSection = {
			id: null,
			name: "",
			description: "",
			isRemove: 0,
			questionnaire: [
				{
					id: null,
					question: "",
					rfq_question_type_id: 2,
					required: 1,
					fields: [""],
					name: "",
					sort_order: 1,
				},
			],
			sort_order: meta.value?.length + 1,
		};

		helper.setValue(getSortedReturnables([...meta.value, newSection]));

		setTimeout(() => {
			const div = document.getElementById(
				`section-container-${meta?.value?.length}`
			);

			div.scrollIntoView({ behavior: "smooth" });
		}, 100);
	};

	const deleteSectionsHandler = (index) => {
		const formValue = meta.value[index];
		if (formValue.id) {
			let newSections = [...meta.value];
			let deletedQuestion = newSections[index].questionnaire.map(
				(question) => ({ ...question, name: "deleted", isRemove: 1 })
			);
			newSections[index] = {
				...newSections[index],
				name: "deleted",
				description: "deleted",
				questionnaire: deletedQuestion,
				isRemove: 1,
			};
			helper.setValue(getSortedReturnables(newSections));
			typeof meta.error !== "string" && helper.setError(undefined);
		} else {
			helper.setValue(meta.value.filter((_, i) => i !== index));
			typeof meta.error !== "string" && helper.setError(undefined);
		}
	};

	const copySectionsHandler = (index) => {
		const formSection = { ...meta.value[index], id: null };
		helper.setValue(getSortedReturnables([...meta.value, formSection]));
		const sectionLastIndex = meta.value.length || 0;
		setTimeout(() => {
			const cardElement = document.getElementById(
				`section-container-${sectionLastIndex}`
			);
			cardElement.scrollIntoView({ behavior: "smooth", block: "start" });
		}, 200);
	};

	const addQuestionHandler = (sectionIndex) => {
		const currentQuestions = sectionsValue[sectionIndex]?.questionnaire || [];
		const newSortOrder = currentQuestions.length + 1;

		const newQuestion = {
			id: null,
			question: "",
			rfq_question_type_id: 2,
			required: 1,
			fields: [],
			isRemove: 0,
			name: "",
			sort_order: newSortOrder,
			uuid: Math.floor(1000 + Math.random() * 9000),
		};

		let oldValue = [...meta.value];
		oldValue[sectionIndex].questionnaire.push(newQuestion);
		helper.setValue(getSortedReturnables(oldValue));
	};

	const sectionTotalChecker = () => {
		let total = 0;
		for (const section of sectionsValue) {
			if (!section?.isRemove) {
				total++;
			}
		}
		return total;
	};
	const dragSectionHandler = (result) => {
		let value = sectionsValue;
		let valueSetter = (newValue) => (value = newValue);
		reorderList(value, result, valueSetter);
		const reordered = value.map((item, index) => ({
			...item,
			sort_order: index + 1,
		}));
		helper.setValue(reordered);
	};

	const handleFileUploadComplete = (uploadedFile) => {
		setFieldValue(
			"returnable_schedule_file",
			uploadedFile?.returnableScheduleFilePath?.file_name
		);
		setFieldValue(
			"returnable_schedule_file_path",
			uploadedFile?.returnableScheduleFilePath?.file_path
		);
		setUploadInProgresses((prev) => removeProgressFromUploading(prev, true));
	};

	return (
		<ProjectDetailContainer>
			<div
				id="subheader-page"
				className="py-8"
			>
				<div
					id="subheader-page-title"
					className="text-xl font-semibold text-primary-900"
				>
					<Heading
						fontSize={"36px"}
						color="#003CE9"
					>
						{" "}
						{titlePage}
					</Heading>
				</div>
				<div
					id="subheader-page-subtitle"
					className="mt-1 font-roboto text-sm text-tertiary-600"
				>
					{subTitlePage}
				</div>
			</div>
			<div>
				<div className="mb-8 space-y-5 rounded-md bg-[#EEF5FFB2] p-8">
					<p className="text-base text-tertiary-600">
						Select an option below to get started:
					</p>
					<FieldForm
						name="returnable_schedule_type"
						type="select"
						isContainImg={true}
						options={returnableSchedulesType}
						placeholder="Select"
						className="!max-w-[440px]"
					/>
				</div>
			</div>
			<div
				id="project-detail-content"
				className="relative"
			>
				{!isLoading ? (
					sectionType !== "file" ? (
						<>
							<QuestionerContainer>
								<DragDropContext
									onDragEnd={dragSectionHandler}
									className="my-10"
								>
									<Droppable
										droppableId="questioner-container"
										direction="vertical"
									>
										{(provided) => (
											<div
												id="questioner-container"
												className="mb-8"
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												{sectionsValue?.map((section, index) =>
													!section.isRemove ? (
														<React.Fragment key={`section-${index}`}>
															<Draggable
																key={`section-${index}`}
																draggableId={`section-${index}`}
																index={index}
															>
																{(provided) => (
																	<div
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																	>
																		<ReturnableSchedulesCard
																			index={index}
																			dragHandle={provided.dragHandleProps}
																			section={section}
																			deleteSectionsHandler={
																				deleteSectionsHandler
																			}
																			copySectionsHandler={copySectionsHandler}
																			sectionList={meta.value}
																			setFieldError={setFieldError}
																		/>
																	</div>
																)}
															</Draggable>
															{sectionsValue.length !== index + 1 ? (
																<Flex className="my-10 items-center">
																	<div className="w-full border-b border-gray-200 text-gray-200" />
																	<div className="mx-2 w-[300px] text-center font-roboto text-lg font-bold text-primary-900">
																		Section break
																	</div>
																	<div className="w-full border-b border-gray-200 text-gray-200" />
																</Flex>
															) : null}
														</React.Fragment>
													) : null
												)}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
								<Flex className="-mt-1">
									<ButtonAddContent
										onOpen={onOpen}
										setModalType={setModalType}
										addSectionHandler={addSectionHandler}
										disableAddQuestion={!sectionTotalChecker()}
									/>
								</Flex>
								<AddSectionModal
									modalType={modalType}
									sections={sectionsValue}
									type={modalType}
									isOpen={isOpen}
									onClose={onClose}
									addSectionHandler={addSectionHandler}
									addQuestionHandler={addQuestionHandler}
								/>
							</QuestionerContainer>
						</>
					) : (
						<FileUpload
							name="returnable_schedule_file"
							types={["pdf", "docx", "doc", "xlsx", "xls"]}
							required
							singleFile
							setFieldValue={setFieldValue}
							label="Upload Returnable Schedules Template"
							description="Please upload your returnable schedule template"
							fileExtraData={{
								model: "rfq_returnable_schedule_file",
								rfq_id: rfqId,
							}}
							handleFileUploadComplete={handleFileUploadComplete}
							isProcurerRFx
							testId="returnable-schedule-file"
						/>
					)
				) : (
					<Spinner
						className="absolute left-1/2 top-[30vh] -translate-x-1/2"
						size="xl"
					/>
				)}
			</div>
		</ProjectDetailContainer>
	);
};

export default ReturnableSchedules;
