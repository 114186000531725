import React from "react";
import { navigate } from "@reach/router";
import { TabHeader } from "components/TabHeader";
import { Flex, Spinner } from "@chakra-ui/react";
import { Button } from "components/RFQ/Button";
import MenuBarComp from "./MenuBarComp";
import { BackButton } from "components/RFQ/BackButton";

import NoBorderButton from "components/RFQ/NoBorderButton/NoBorderButton";
import { cn } from "utils/helpers";
import { SkeletonBase } from "components/Skeleton";

import {
	STATUS_ACCEPTED,
	STATUS_DECLINE,
	STATUS_IN_PROGRESS,
	STATUS_INVITED,
} from "utils/constants";

const ButtonActions = ({
	isBottom,
	isLoading,
	loadingSave,
	setShowDialog,
	currentTab,
	continueTab,
	saveHandler,
	isRFxClosed,
	submitHandler,
	submissionStatus,
	isFileUploadInProgress,
	validForm,
}) => {
	const isInvited = submissionStatus === STATUS_INVITED;
	const isAccepted =
		submissionStatus === STATUS_ACCEPTED ||
		submissionStatus === STATUS_IN_PROGRESS;
	const isDecliend = submissionStatus === STATUS_DECLINE;

	return isLoading ? (
		<div className="mt-4 flex justify-end gap-3">
			<SkeletonBase
				width="128px"
				height="46"
			/>
			<SkeletonBase
				width="128px"
				height="46"
			/>
		</div>
	) : !isDecliend && !isRFxClosed ? (
		<TabHeader
			noDivider={true}
			icon={
				!isBottom && <BackButton handleBackButtonClick={() => navigate("..")} />
			}
		>
			{isAccepted ? (
				<Flex
					gap={3}
					className={cn("items-center", isBottom && "py-8")}
				>
					{loadingSave ? (
						<Spinner />
					) : (
						<NoBorderButton
							onClick={() => saveHandler()} //
							text="Save"
							className="bg-white font-roboto text-[14px] font-medium text-tertiary-600"
							disabled={isFileUploadInProgress}
						/>
					)}
					{currentTab !== "Addendums" && (
						<Button
							type="button"
							btntype="secondary"
							disabled={loadingSave || isFileUploadInProgress}
							onClick={() => {
								continueTab();
							}} //
							data-testid="continue-button"
						>
							Continue
						</Button>
					)}
					<Button
						type="button"
						onClick={() => {
							submitHandler(currentTab);
						}}
						disabled={loadingSave || !validForm}
						data-testid="submit-button"
					>
						Submit Response
					</Button>
				</Flex>
			) : (
				isInvited && (
					<MenuBarComp
						setShowDialog={setShowDialog}
						className={isBottom && "py-8"}
					></MenuBarComp>
				)
			)}
		</TabHeader>
	) : (
		!isBottom && (
			<TabHeader
				noDivider={true}
				icon={<BackButton handleBackButtonClick={() => navigate("..")} />}
			/>
		)
	);
};

export default ButtonActions;
