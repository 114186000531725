import styled from "styled-components";
import { width, margin, typography, height, padding } from "styled-system";

import { theme } from "../../theme";

export const ButtonStyled = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	text-transform: capitalize;
	font-size: ${(props) => (props.textfont ? props.textfont : "1rem")}!important;
	font-weight: 600;
	font-family: roboto;
	padding: 15px 20px;
	border-radius: ${(props) => (props.radius ? props.radius : "4px")};
	width: ${(props) => (props.widthExpand ? "100%" : "auto")};
	letter-spacing: normal;
	&:disabled {
		color: ${theme.colors.grayLight} !important;
		background: ${theme.colors.snowDark} !important;
		border-color: #d2d5da !important;
		cursor: not-allowed !important;
		pointer-events: all !important;
		&::hover {
			background-color: ${theme.colors.snowDark};
		}
		svg path {
			stroke: ${theme.colors.grayLight};
		}
	}

	cursor: ${(props) => (props.disablePointer ? "no-drop" : "pointer")};
	pointer-events: ${(props) => (props.disablePointer ? "none" : "auto")};
	line-height: 1.5;
	background: ${theme.colors.royalBlue};
	color: white;
	border: 2px solid ${theme.colors.royalBlue};
	box-shadow: ${(props) =>
		!props.boxShadow ? "none" : "4px 4px 8px 2px #edeef0"};
	transition: all 0.1s ease;
	${width}
	${height}
  ${margin}
  ${padding}
  ${typography}
  &::hover {
		background: #003acd;
	}
	svg {
		margin-right: 6px;
	}
	span {
		margin-left: 6px;
	}
	&:focus {
		outline: none;
	}

	${(props) =>
		props.btntype === "primary" &&
		`
    &:hover, &:focus-within {
      background-color:  ${theme.colors.blueHover};
      border-color:  ${theme.colors.blueHover};
      color:#fff;
    }
  `}
	${"" /* Primary Alt */}
  ${(props) =>
		props.btntype === "primary" &&
		props.alt &&
		`
    background-color: ${theme.colors.mintDark};
    border-color: ${theme.colors.mintDark};
  `}
  ${(props) =>
		props.btntype === "plain" &&
		`
    color: #475467;
    background-color: transparent;
    border-color: transparent;
    &:hover, &:focus-within {
      background-color: ${theme.colors.snowDark};
    }

  `}
${(props) =>
		props.btntype === "link" &&
		`
  color: #475467;
  background-color: transparent;
  border-color: transparent;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease-in;
  &::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1px;
    bottom: 0px;
    left: 0;
    background-color: ${theme.colors.royalBlue};
    transition: width 0.3s; 
  }
  &:hover, &:focus-within {
    filter: brightness(0.7);
    background-color: rgba(0, 69, 245, 0.2);
  }
`}

${(props) =>
		props.btntype === "linkDanger" &&
		`
  color: #475467;
  background-color: transparent;
  border-color: transparent;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease-in;
  &::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1px;
    bottom: 0px;
    left: 0;
    background-color: ${theme.colors.royalBlue};
    transition: width 0.3s; 
  }
  &:hover, &:focus-within {
    background-color: rgba(225, 25, 0, 0.5);
  }
`}

${(props) =>
		props.btntype === "tab" &&
		`
    border: none;
    font-family: roboto;
    font-weight: 600;
    letter-spacing: 0;
    height: 44px;
    padding: 17px 8px !important;
    text-transform: none;
    color: ${props.active ? "#0031DD" : "#667085"};
    background-color: ${props.active ? "#DEEBFF" : "transparent"};
    &:hover, &:focus-within {
        background-color: #DEEBFF;
        color: #0031DD;
        outline: none;
    }
    &:focus {
      outline: solid #B9E6FE;
    }
    &:disabled {
        color: #B9C2CC;
        background-color: transparent;
        cursor: not-allowed;
        opacity: 0.5;
    }
  `}
 



    ${"" /* Secondary */}
    ${(props) =>
		props.btntype === "secondary" &&
		`
    color: ${theme.colors.royalBlue};
    border-color: rgba(0, 69, 245, 0.1);
    background-color: rgba(0, 69, 245, 0.1);

    &:hover, &:focus-within {
        background-color: rgba(0, 69, 245, 0.2);
    }
  `}

    ${(props) =>
		props.btntype === "cancel" &&
		`
    color: #344054;
    border-color: #D0D5DD;
    background-color: white;

    &:hover, &:focus-within {
        background-color: #fafafa;
    }
  `}

    ${"" /* Secondary Danger*/}
    ${(props) =>
		props.btntype === "secondary-danger" &&
		`
        color: ${theme.colors.danger};
        border-color: rgba(225, 25, 0, 0.1);
        background-color: rgba(225, 25, 0, 0.1);

        &:hover, &:focus-within {
          background-color: rgba(225, 25, 0, 0.5);
        }
    `}

    ${"" /* Secondary Alt */}
    ${(props) =>
		props.btntype === "secondary" &&
		props.alt &&
		`
    color: ${theme.colors.mintDark};
    background: rgba(11, 208, 182, 0.1);
    border-color: rgba(11, 208, 182, 0.1);
    `}


    ${"" /* Danger */}
    ${(props) =>
		props.danger &&
		`
        color: white;
        background: ${theme.colors.red};
        border-color: ${theme.colors.red};

        &::hover, &:focus-within {
            background: ${theme.colors.redDark};
            border-color: ${theme.colors.redDark};
        }
    `}


    ${"" /* Secondary */}
    ${(props) =>
		props.btntype === "blueBorder" &&
		`
    color: ${theme.colors.royalBlue};
    border-color: ${theme.colors.royalBlue};
    background: transparent;

    &::hover {
        background-color: rgba(0, 69, 245, 0.2);
    }
  `}

${"" /* Secondary */}
    ${(props) =>
		props.btntype === "no-background-border" &&
		`
    color: ${theme.colors.brown};
    border: none !important;
    background: none !important;
  `}

  ${"" /***** disabledStyle *****/}
    ${(props) =>
		props.disabledStyle &&
		props.btntype === "primary" &&
		`
    pointer-events: none;
    color: ${theme.colors.grayLight};
    background: ${theme.colors.snowDark};
    border-color: ${theme.colors.snowDark};
    opacity: 0.5;
  `}

    ${(props) =>
		props.disabledStyle &&
		props.btntype === "secondary" &&
		`
    pointer-events: none;
    color: ${theme.colors.grayLight};
    background: ${theme.colors.snowDark};
    border-color: #D2D5DA;
  `}

  ${(props) =>
		props.disabledStyle &&
		props.btntype === "secondary-danger" &&
		`
        pointer-events: none;
        color: ${theme.colors.grayLight};
        background: ${theme.colors.snowDark};
        border-color: #D2D5DA;
        cursor: not-allowed;
        pointer-events: all !important;
        &::hover, &:focus-within {
            background-color: ${theme.colors.snowDark};
        }

    `}

  ${"" /***** Sizes *****/}
  ${(props) =>
		props.size === "xs" &&
		`
    font-size: 0.75rem !important;
    padding: 7px 16px 6px;
  `}

  ${(props) =>
		props.size === "sm" &&
		`
    font-size: 0.75rem !important;
    padding: 13px 21px 12px;
  `}

  ${(props) =>
		props.size === "md" &&
		`
    padding: 17px 18px;
  `}

  ${(props) =>
		props.size === "lg" &&
		`
    padding: 25px 46px;
  `}

  ${"" /* Download */}
      ${(props) =>
		props.btntype === "iconInline" &&
		`
      color: ${theme.colors.royalBlue};
      border-color: transparent;
      background: transparent;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      box-shadow: none;
      padding-left: 0;

      span.iconInline{
          display: inline-flex;
          background-color: #ffffff;
          width: 64px;
          height: 64px;
          border-radius: 50%;
          margin-right: 17px;
          align-items: center;
          justify-content: center;
          svg{
            margin-right: 0;
          }
      }

      &::hover, &:focus-within {
          background-color: transparent;

          span.iconInline{
              background-color: ${theme.colors.royalBlue};

              path{
                  stroke: #ffffff;
              }
          }
      }
  `}
`;

export const ButtonWithIconStyled = styled.button`
	color: ${theme.colors.royalBlue};
	border-color: rgba(0, 69, 245, 0.1);
	background-color: rgba(0, 69, 245, 0.1);
	font-size: 1rem;
	display: flex;
	border-radius: 1.5rem;
	align-content: flex-end;
	align-items: center;
	justify-content: space-evenly;
	font-weight: 600;
	cursor: pointer;
	width: ${(props) => (props.width ? props.width : "7rem")};
	height: ${(props) => (props.height ? props.height : "2.5rem")};

	&:hover,
	&:focus-within {
		background-color: rgba(0, 69, 245, 0.2);
	}
`;
