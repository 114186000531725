import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { showToast } from "../../utils/helpers"; // Updated import
import { TextInput } from "../FormInputs/TextInput";
import { EditUserProfileStyled } from "./styled";
import {
	changePasswordActions,
	update_user,
} from "../../actions/AccountManagementActions";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { TabHeader } from "../TabHeader";
import { Select } from "../FormInputs/Select";
import { SocialInvitation } from "../SocialInvitation/SocialInvitation";
import { userRoles, findUserRole, isPAPLUsers } from "../../utils/helpers";
import { getPasswordStrength } from "../../utils/helpers";
import { PasswordValidator } from "../FormInputs/PasswordValidator";
import { FlashError } from "../FormInputs/FlashError";
import { Button } from "components/Button";
import { ReactComponent as PhoneLock } from "./../../icons/phone-lock.svg";
import { ReactComponent as Copy } from "./../../icons/copy.svg";
import {
	deactivateTwoFactor,
	enableTwoFactor,
	enablingTwoFactor,
	submitTwoFactorCode,
} from "actions/authActions";
import ModalDeactivate2FA from "./ModalDeactive2FA";
import ModalEnabled2FA from "./ModalEnabled2FA";
import FormHeader from "components/FormHeading/FormHeader";
import { Icon } from "assets/icons/Icon";
import { Spinner } from "@chakra-ui/react";
import { Submit } from "components/FormInputs/Submit";

export const EditUserProfileComponent = ({
	company,
	viewOnly,
	current_user,
	twoFactorEnabled,
	enableTwoFactor,
	deactivateTwoFactor,
	changePasswordActions,
	...props
}) => {
	const [isChangingPassword, setIsChangingPassword] = useState(false);
	const [passwordRules] = useState({ letter: false });
	const [passwordRulesConfirmation] = useState({ letter: false });

	const isPAPLUser = isPAPLUsers(current_user);

	const initialValues = {
		id: current_user.id,
		first_name: current_user.first_name || "",
		last_name: current_user.last_name || "",
		phone: current_user.phone || "",
		email: current_user.email || "",
		position: current_user.position || "",
	};

	const validationSchema = Yup.object({
		first_name: Yup.string().required("This is a required field"),
		last_name: Yup.string().required("This is a required field"),
		email: Yup.string().required("This is a required field"),
		phone: Yup.string()
			.required("This is a required field")
			.matches(
				/^(?=.*)((?:\+?61) ?(?:\((?=.*\)))?([2-47-8])\)?|(?:\((?=.*\)))?([0-1][2-47-8])\)?) ?-?(?=.*)((\d{1} ?-?\d{3}$)|(00 ?-?\d{4} ?-?\d{4}$)|( ?-?\d{4} ?-?\d{4}$)|(\d{2} ?-?\d{3} ?-?\d{3}$))/,
				"Invalid Phone No."
			),
		position: Yup.string().required("This is a required field"),
	});

	const passwordSchema = Yup.object({
		current_password: Yup.string().required("* This Field is required"),
		password: Yup.string()
			.required("* This Field is required")
			.test(
				"strong password",
				"*The supplied password does not meet the requirements",
				(value) => {
					let validRules = passwordRules;
					if (value) {
						validRules = getPasswordStrength(value, passwordRules, isPAPLUser);
						return Object.values(validRules).every((val) => val === true);
					} else {
						validRules.letter = false;
						validRules.number = false;
						validRules.capital = false;
						validRules.count = false;
						return false;
					}
				}
			),
		confirmation_password: Yup.string()
			.required("* This Field is required")
			.test(
				"strong password",
				"*The supplied password does not meet the requirements",
				(value) => {
					let validRules = passwordRulesConfirmation;
					if (value) {
						validRules = getPasswordStrength(value, passwordRulesConfirmation);
						return Object.values(validRules).every((val) => val === true);
					} else {
						validRules.letter = false;
						validRules.number = false;
						validRules.capital = false;
						validRules.count = false;
						return false;
					}
				}
			)
			.test({
				name: "not match",
				exclusive: false,
				params: {},
				message:
					"Confirmation password not match. Ensure both passwords are match.",
				test: function (value) {
					return value === this.parent.password;
				},
			}),
	});

	const handleChangePasswordSubmit = async (values, helpers) => {
		try {
			const passwordChanged = await changePasswordActions(
				values,
				current_user.id
			);
			if (passwordChanged) {
				setIsChangingPassword(false);
				helpers.resetForm();
			}
		} catch (e) {
			return;
		}
	};

	const [data2fa, setData2fa] = useState(null);
	const [enabled2FA, setEnabled2FA] = useState(twoFactorEnabled || false);
	const [enabling2FA, setEnabling2FA] = useState(false);
	const [modalDeactivate, setModalDeactivate] = useState(false);
	const [modalEnabled, setModalEnabled] = useState(false);

	const secretMatch = data2fa ? data2fa?.url.match(/secret=([A-Z0-9]+)/) : null;
	const handle2FA = () => {
		enablingTwoFactor().then((data) => {
			setEnabling2FA(true);
			setData2fa(data);
		});
	};
	// const handleCancel2FA = () => {
	//   setModalDeactivate(true)
	// }
	const handleConfirmDeactivate = async () => {
		try {
			await deactivateTwoFactor();
			setEnabled2FA(false);
			setModalDeactivate(false);
		} catch (e) {
			console.log(e);
		}
	};
	const copyToClipboard = () => {
		navigator.clipboard.writeText(secretMatch?.[1]).then(() => {
			showToast("Copied to clipboard", "Success", true);
		});
	};

	return company !== undefined ? (
		<EditUserProfileStyled>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={async (values, { setErrors }) => {
					try {
						await props.update_user(values, true);
					} catch (e) {
						const error = await e.response.json();
						showToast(error.message, "Error");
						error.errors !== undefined && setErrors(error.errors);
					}
				}}
			>
				{(props) => {
					const { isSubmitting, values, status, setStatus, isValid } = props;
					const onSubmitValidationError = (e) => {
						setStatus("Please review errors in form");
					};
					const submitHandler = async () => {
						if (!isSubmitting) {
							await props.submitForm();
						}
					};
					return (
						<>
							<TabHeader heading="My Profile">
								<Button
									data-testid="save-details-button"
									style={{ height: "48px" }}
									onClick={submitHandler}
									disabled={!isValid || isSubmitting}
								>
									{isSubmitting ? (
										<div className="flex items-center gap-2">
											<Spinner /> Saving
										</div>
									) : (
										"Save Details"
									)}
								</Button>
							</TabHeader>

							<Form>
								<div className="row">
									<div style={{ marginLeft: "-46px", marginRight: "-46px" }}>
										<div className="grid grid-cols-12 gap-6 gap-y-0">
											<FormHeader
												className="col-span-12 mb-6"
												header="Your Details"
												subHeader="Update your personal details here."
											/>
											<TextInput
												label="First Name"
												placeholder="Enter your first name"
												required={!viewOnly}
												name="first_name"
												className="col-span-6"
												disabled={viewOnly}
												data-testid="first-name-input"
											/>
											<TextInput
												label="Last Name"
												placeholder="Enter your last name"
												required={!viewOnly}
												name="last_name"
												className="col-span-6"
												disabled={viewOnly}
												data-testid="last-name-input"
											/>
											<TextInput
												label="Phone Number"
												placeholder="Enter your phone number"
												required={!viewOnly}
												name="phone"
												className="col-span-6"
												disabled={viewOnly}
												data-testid="phone-number-input"
											/>
											<TextInput
												label="Email Address"
												placeholder="Enter your email address"
												required={!viewOnly}
												name="email"
												className="col-span-6"
												disabled
											/>
											<Select
												required="true"
												options={userRoles}
												name={"position"}
												label={"User Type"}
												className={"col-span-6"}
												value={findUserRole(values.position)}
												disabled
											/>
											<div className="col-span-6">
												{status && (
													<FlashError
														heading={"Required Field"}
														text={
															"There are incomplete required fields. Please complete them."
														}
														margin="20px 0px 0px 0px"
													/>
												)}
											</div>
											{!viewOnly && (
												<>
													<div className="social-invitations">
														<SocialInvitation />
													</div>
												</>
											)}
										</div>
									</div>
								</div>
								<OnSubmitValidationError callback={onSubmitValidationError} />
							</Form>
						</>
					);
				}}
			</Formik>
			<div className="mb-14">
				<Formik
					enableReinitialize
					initialValues={{
						password: "",
						confirmationPassword: "",
						current_password: "",
					}}
					validationSchema={passwordSchema}
					onSubmit={handleChangePasswordSubmit}
				>
					{({ setFieldValue, isValid, resetForm, isSubmitting }) => {
						const handleCancel = () => {
							resetForm();
							setIsChangingPassword(false);
						};

						return (
							<Form style={{ padding: 0 }}>
								<FormHeader
									className="col-span-12 my-8"
									header="Change Password"
									subHeader="This your personal password linked to your Procuracon account."
								/>
								{!isChangingPassword ? (
									<Button
										btntype="base"
										type="button"
										className="items-center gap-2"
										data-testid="open-password-modal-button"
										onClick={() => setIsChangingPassword(true)}
										style={{ height: "48px" }}
									>
										<Icon icon="lock" />
										Change password
									</Button>
								) : (
									<div className="w-1/2">
										<TextInput
											data-tip
											label="Current Password"
											placeholder={"********"}
											name="current_password"
											type={"password"}
											className="col-span-6"
											inputClassName="!h-12 !max-h-12 !pl-10"
											rfq
											rfqIcon="lock"
											disableToggler
											icon
											setFieldValue={setFieldValue}
										/>
										<TextInput
											data-tip
											data-for="pass_validation"
											label="New Password"
											placeholder={"********"}
											name="password"
											type={"password"}
											inputClassName="!h-12 !max-h-12 !pl-10"
											rfq
											rfqIcon="lock"
											disableToggler
											icon
											setFieldValue={setFieldValue}
										/>
										<TextInput
											data-tip
											data-for="pass_validation_confirmation"
											label="Confirm Password"
											placeholder={"********"}
											name="confirmation_password"
											type={"password"}
											inputClassName="!h-12 !max-h-12 !pl-10"
											rfq
											rfqIcon="lock"
											disableToggler
											icon
											setFieldValue={setFieldValue}
										/>
										<PasswordValidator
											dataId={"pass_validation"}
											passwordRules={passwordRules}
										/>
										<PasswordValidator
											dataId={"pass_validation_confirmation"}
											passwordRules={passwordRulesConfirmation}
										/>

										<div className="mt-8 flex items-center gap-4">
											<Button
												btntype="base"
												type="button"
												onClick={handleCancel}
												className="max-h-12"
											>
												Cancel
											</Button>
											<Button
												disabled={!isValid || isSubmitting}
												className="max-h-12"
												data-testid="submit-password-button"
											>
												{" "}
												Change Password
											</Button>
										</div>
									</div>
								)}
							</Form>
						);
					}}
				</Formik>
			</div>
			{isPAPLUser && (
				<div className="twofactor-container w-1/2 !p-0">
					<p className="twofactor-title">Two-Factor Authentication (2FA)</p>
					<p className="twofactor-desc">
						For added security on Procuracon, enable and manage two-factor
						authentication (2FA). Two-factor authentication provides an extra
						layer of protection by requiring both your password and a unique
						code generated by the authenticator app each time you log in. This
						helps safeguard your personal information and ensures that only you
						can access your account.{" "}
					</p>
					<div style={{ margin: "20px 0" }}>
						{!enabling2FA && (
							<Button
								btntype={enabled2FA ? "secondary" : "primary"}
								style={{ height: "48px", padding: "0 16px" }}
								onClick={handle2FA}
								className={enabled2FA ? "enabled-button" : ""}
							>
								<PhoneLock />
								{enabled2FA ? "Status : Enabled" : "Enable 2FA"}
							</Button>
						)}
					</div>
					{enabling2FA && (
						<div>
							<StepBox
								step={1}
								title="Get the app"
							>
								<p style={{ marginBottom: 0 }}>
									Download and install the 
									<a
										href="https://m.google.com/authenticator"
										rel="noreferrer"
										target="_blank"
									>
										Google Authenticator
									</a>
									,
									<a
										href="https://guide.duosecurity.com/third-party-accounts"
										rel="noreferrer"
										target="_blank"
									>
										{" "}
										Duo Mobile
									</a>
									,
									<a
										href="https://authy.com"
										rel="noreferrer"
										target="_blank"
									>
										{" "}
										Authy{" "}
									</a>
									or{" "}
									<a
										href="https://support.microsoft.com/en-us/account-billing/download-microsoft-authenticator-351498fc-850a-45da-b7b6-27e523b8702a"
										rel="noreferrer"
										target="_blank"
									>
										{" "}
										Microsoft Authenticator{" "}
									</a>
									app for your phone or tablet.
								</p>
							</StepBox>
							<StepBox
								step={2}
								title="Scan this barcode"
							>
								<p style={{ marginBottom: 0 }}>
									Open the authentication app and:
								</p>
								<ul>
									<li>{"Open the scan tool. (Top right of the app)"}</li>
									<li>{"Scan the QR code and select 'Continue' below"}</li>
								</ul>
								<div
									className="qr-container"
									dangerouslySetInnerHTML={{ __html: data2fa?.svg }}
								/>
								<div className="separator-text">
									<p>OR enter the code manually</p>
								</div>
								<div className="copy-field">
									<span>{secretMatch?.[1] || null}</span>
									<button onClick={copyToClipboard}>
										<Copy />
									</button>
								</div>
								<p style={{ marginBottom: 0 }}>
									Instead of scanning, use your authentication app’s “Manual
									entry” or equivalent option and provide the following
									time-based key.
								</p>
							</StepBox>
							<StepBox
								step={3}
								title="Enter the code"
							>
								<p>
									Once the barcode above is scanned, enter the 6-digit
									verification code generated by the app.
								</p>
								<Formik
									onSubmit={(values) => {
										submitTwoFactorCode(values.code).then((data) => {
											if (data) {
												enableTwoFactor();
												setModalEnabled(true);
												setEnabled2FA(true);
												setEnabling2FA(false);
											}
										});
									}}
									initialValues={{
										code: "",
									}}
								>
									{({ values }) => (
										<Form style={{ padding: 0 }}>
											<TextInput
												required
												name="code"
												placeholder="Code"
												fixedPlaceholder
												maxLength={6}
											/>
											<div
												style={{
													display: "flex",
													justifyContent: "start",
													gap: "16px",
												}}
											>
												<Button
													type="button"
													style={{ height: "48px" }}
													btntype="secondary"
													onClick={() => setEnabling2FA(false)}
												>
													Cancel
												</Button>
												<Submit
													type="submit"
													isSubmitting={false}
													text="Verify and enable 2FA"
													submittingText="Verifying..."
													style={{ height: "48px" }}
													disabled={values.code.length !== 6}
												/>
											</div>
										</Form>
									)}
								</Formik>
							</StepBox>
						</div>
					)}
				</div>
			)}
			<ModalEnabled2FA
				isOpen={modalEnabled}
				onCLose={() => setModalEnabled(false)}
			/>
			<ModalDeactivate2FA
				onConfirm={handleConfirmDeactivate}
				isOpen={modalDeactivate}
				onCLose={() => setModalDeactivate(false)}
			/>
		</EditUserProfileStyled>
	) : (
		<Skeleton
			count={5}
			duration={0.5}
		/>
	);
};

const mapStateToProps = (state) => {
	const company = state.search.activeCompany;
	const current_user = state.auth.user;

	return {
		isRequesting: state.companyProfile.isRequesting,
		company: company,
		current_user: current_user,
		twoFactorEnabled: state.auth.two_factor_enabled,
	};
};

export const EditUserProfile = connect(mapStateToProps, {
	update_user,
	changePasswordActions,
	enableTwoFactor,
	deactivateTwoFactor,
})(EditUserProfileComponent);

const StepBox = ({ step, title, children }) => {
	return (
		<div className={`step-box`}>
			<p className="step-header">Step {step}</p>
			<h4>{title}</h4>
			{children}
		</div>
	);
};
