import React, { useState, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import debounce from "lodash.debounce";
import { TextInput } from "../FormInputs/TextInput";
import { TextArea } from "../FormInputs/TextArea";
import { CheckboxStyled } from "../FormInputs/Checkbox";
import { Select } from "../FormInputs/Select";
import { Sectors } from "../FormInputs/Sectors";
import { Location } from "../FormInputs/Location";
import { ProcurementMethod } from "../FormInputs/ProcurementMethod";
import { YearCompleted } from "../FormInputs/YearCompleted";
import { FormButtonRow } from "../FormButtonRow";
import { Button } from "../Button";
import { Currency } from "../FormInputs/Currency";
import { Trades } from "../FormInputs/Trades";
import { TabHeader } from "../TabHeader";
import { TabContent } from "../Tabs/styled";
import { FlashError } from "../FormInputs/FlashError";
import { Spinner } from "@chakra-ui/react";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { Modal } from "../Modal";
import { ModalBodyStyled, ModalHeader } from "../ShareProfile/styled";
import { Heading } from "../Heading";
import { TradeValue } from "components/FormInputs/TradeValue";
import lodash from "lodash";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { isEmptyObj } from "utils/helpers";
import { FileUpload } from "components/RFQ/FileUpload";
import LabelForm from "components/RFQ/LabelForm/LabelForm";
import { useSelector } from "react-redux";
import { Gallery } from "components/FormInputs/ImageUpload/Gallery";
import { VStack, Grid, GridItem } from "@chakra-ui/react";
import { isFormStateAndSavedFormStateSame } from "./ProjectFormHelper";
import { SmartFieldNote } from "components/RFQ/SmartFieldEditor/_components/SmartFieldNote";

const fundingTypeOptions = [
	{ label: "N/A", value: "" },
	{ label: "Public Project", value: "Public Project" },
	{ label: "Private Project", value: "Private Project" },
	{ label: "Public and Private Project", value: "Public and Private Project" },
	{ label: "Confidential", value: "Confidential" },
];

const AreaCheckboxWrapper = styled.div`
	display: flex;
	align-items: center;
	min-height: 65px;

	.container-checkbox {
		margin-right: 15px;
	}
`;

const AutoSave = ({ debounceMs, isSubmitting }) => {
	const formik = useFormikContext();
	formik.values.status = "published";

	// eslint-disable-next-line
	const debouncedSubmit = React.useCallback(
		debounce(() => formik.submitForm(), debounceMs),
		[formik.submitForm]
	);

	useEffect(() => {
		if (!isSubmitting) {
			debouncedSubmit();
		}
	}, [debouncedSubmit, isSubmitting]);

	return true;
};
export const ProjectForm = ({
	profileTypeId,
	handleSubmit,
	initialValues,
	isRequesting,
	setAutoUpdateForm,
	autoUpdateForm,
	editForm,
	handleDelete,
	errorStatus,
	setErrorStatus,
	savedField,
	setInitialValue,
	setSavedField,
	additionalFields,
	customButtons,
	hideDefaultButtons = false,
	supplierSmartField = false,
	...props
}) => {
	const [siteAreaChecked, setSiteAreaChecked] = useState(false);
	const [grossAreaChecked, setGrossAreaChecked] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const procurementMethods = useSelector(
		(state) => state?.companyProfile?.procurementMethods
	);

	const closeInfoModal = () => {
		setModalIsOpen(false);
	};
	useEffect(() => {
		setSiteAreaChecked(initialValues.site_area ? true : false);
		setGrossAreaChecked(initialValues.gross_floor_area ? true : false);
	}, [initialValues]);

	useEffect(() => {
		setModalIsOpen(true);
	}, []);

	const validationSchema = Yup.object({
		name: Yup.string().required(),
		location: Yup.string().required(),
		summary: Yup.string().required(),
		value: profileTypeId === 3 ? Yup.string() : Yup.string().required(),
		year_completed: Yup.string().required(),
		head_contractor: Yup.string(),
		client_name: Yup.string().required(),
		sector_id: Yup.string().required(),
		trade_value: profileTypeId === 3 ? Yup.string().required() : Yup.string(),
		// images: Yup.array().min(1, 'You must add at least one image'),
		// testimonials: Yup.array().min(1, 'You must add at least one file')
	});
	return initialValues.name !== undefined ? (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{(props) => {
				const {
					setFieldValue,
					isSubmitting,
					values,
					submitForm,
					setStatus,
					status,
					setErrors,
					errors,
				} = props;
				const onSubmitValidationError = () => {
					setStatus("required");
					setErrorStatus(true);
				};
				const onMaxCharacter = () => {
					setStatus("maxCharacter");
					setErrorStatus(true);
				};

				return (
					<Form
						onChange={() => {
							setErrorStatus(false);
							setStatus(null);
						}}
					>
						{editForm &&
							!autoUpdateForm &&
							isEmptyObj(errors) &&
							!errorStatus &&
							!isFormStateAndSavedFormStateSame(savedField, values) && (
								<AutoSave
									isSubmitting={isSubmitting}
									debounceMs={3000}
								/>
							)}
						<TabHeader
							heading={
								supplierSmartField
									? `Edit ${initialValues.name}`
									: editForm
										? "Edit Project"
										: "Create Project"
							}
						/>
						{supplierSmartField && <SmartFieldNote type="project" />}
						<Modal
							isOpen={modalIsOpen}
							onRequestClose={closeInfoModal}
							width="800"
						>
							<ModalHeader>
								<Heading fontSize="32px">USER TIP</Heading>
							</ModalHeader>
							<ModalBodyStyled>
								<div>
									<h2>
										To save your project, make sure you complete all required
										fields marked as <span className="required">*</span>
									</h2>
								</div>
							</ModalBodyStyled>
						</Modal>
						<TabContent
							noPadding
							className="!pb-12"
						>
							<VStack
								id="wrapper__supplier-search-form"
								spacing={2}
								align="stretch"
							>
								<Grid
									templateColumns="repeat(2, 1fr)"
									gap={3}
									columnGap={6}
								>
									<TextInput
										label="Project Name"
										data-testid="cprofile-project-name"
										required="true"
										name="name"
										type="text"
									/>
									<Location
										label="Project Location"
										required="true"
										name="location"
										setFieldValue={setFieldValue}
										initialValue={initialValues.location}
										rfq
										inputTestId={"primary-address-input"}
										dropdownTestId={"primary-address-dropdown"}
										rfqIcon="search-lg"
										projectForm={false}
										allowInternational
									/>
									<GridItem colSpan={2}>
										<FormatedInput
											label="Project Summary"
											required="true"
											name="summary"
											type="text"
											inputTestId="cprofile-project-summary"
											rfq
											characterLimit={1500}
											setErrors={setErrors}
											onMaxCharacter={onMaxCharacter}
											onChangeHandler={() => {
												setErrorStatus(false);
												setStatus(null);
											}}
											guideLine={
												profileTypeId === 3
													? "Tell us about this project and your company's role"
													: "Tell us about the project, your company's role, scope and/or services provided."
											}
										/>
									</GridItem>
									{profileTypeId === 3 && (
										<Trades
											name="trade_ids"
											testId="cprofile-project-trades"
											guideLine="Identify the trade package/s your company delivered"
										/>
									)}
									{profileTypeId !== 3 && (
										<Currency
											label={
												profileTypeId === 3
													? "Trade Package Value(Total)"
													: "Project Construction Value (AUD) "
											}
											required="true"
											name="value"
											type="number"
											lang="en_EN"
											setFieldValue={setFieldValue}
											values={values}
											guideLine={
												profileTypeId !== 3 &&
												"If construction value is confidential, enter '0'"
											}
										/>
									)}

									{profileTypeId === 3 && (
										<TradeValue
											name="trade_value"
											testId="cprofile-project-trade-value"
											required="true"
											values={values}
											setFieldValue={setFieldValue}
											defaultValue={initialValues.trade_value}
										/>
									)}
									<YearCompleted
										name="year_completed"
										testId="cprofile-project-year-completed"
										required="true"
										values={values}
										setFieldValue={setFieldValue}
										defaultValue={initialValues.year_completed}
									/>
									{profileTypeId !== 3 && (
										<ProcurementMethod
											name="procurement_method_ids"
											testId="cprofile-project-procurement-method"
											required="true"
											setFieldValue={setFieldValue}
											values={values}
											methods={procurementMethods}
											guideLine="https://s3.ap-southeast-2.amazonaws.com/assets.procuracon.juicebox.com.au/public/Procurement_Model___Guidance_Notes.pdf"
										/>
									)}
									<TextInput
										label="Client Name"
										data-testid="cprofile-project-client-name"
										required="true"
										name="client_name"
										type="text"
									/>
									<Sectors
										label="Primary Sector"
										testId="cprofile-project-sector"
										required="true"
										name="sector_id"
										defaultValue={initialValues.sector_id}
										setFieldValue={setFieldValue}
										values={values}
										singleValue
									/>

									{profileTypeId !== 3 && (
										<Select
											label="Type of Funding"
											testId="cprofile-project-funding-type"
											name="funding_type"
											defaultValue={initialValues.funding_type}
											setFieldValue={setFieldValue}
											options={fundingTypeOptions}
											value={fundingTypeOptions.filter((option) => {
												return values.funding_type === option.value;
											})}
										/>
									)}
									{profileTypeId !== 3 && (
										<GridItem colSpan={2}>
											<LabelForm
												htmlFor="site_area"
												label="Project Area"
											/>
											<Grid
												templateColumns="repeat(2, 1fr)"
												columnGap={6}
											>
												<AreaCheckboxWrapper>
													<CheckboxStyled
														className="container-checkbox"
														fontSize="15px"
														checkmarkTop="2px"
													>
														Site Area (Ha)
														<input
															type="checkbox"
															checked={siteAreaChecked}
															name="sitearea"
															onChange={() => {
																if (!siteAreaChecked === false) {
																	values.site_area = "";
																}
																setSiteAreaChecked(!siteAreaChecked);
															}}
														/>
														<span
															className="checkmark"
															data-testid="checkmark-area"
														></span>
													</CheckboxStyled>
													{siteAreaChecked && (
														<TextInput
															testId={"cprofile-area-input"}
															label=""
															flexGrow="1"
															placeholder="Enter Site Area"
															name="site_area"
															type="text"
															marginBottom="0px"
															className="max-w-[300px]"
															noMargin
														/>
													)}
												</AreaCheckboxWrapper>
												<AreaCheckboxWrapper>
													<CheckboxStyled
														className="container-checkbox"
														fontSize="15px"
														checkmarkTop="2px"
													>
														Gross Floor Area (m2)
														<input
															type="checkbox"
															className="grossfloor"
															name="grossfloor"
															checked={grossAreaChecked}
															onChange={() => {
																if (!grossAreaChecked === false) {
																	values.gross_floor_area = "";
																}

																setGrossAreaChecked(!grossAreaChecked);
															}}
														/>
														<span
															className="checkmark"
															data-testid="checkmark-gross"
														></span>
													</CheckboxStyled>
													{grossAreaChecked && (
														<TextInput
															testId={"cprofile-gross-input"}
															label=""
															flexGrow="1"
															placeholder="Enter Gross Floor Area"
															name="gross_floor_area"
															type="text"
															className="max-w-[300px]"
															marginBottom="0px"
															noMargin
														/>
													)}
												</AreaCheckboxWrapper>
											</Grid>
										</GridItem>
									)}
									<GridItem colSpan={2}>
										<Gallery
											label="Images"
											images={initialValues.images}
											name="images"
											setFieldValue={setFieldValue}
											max={10}
											isSubmitting={
												values.status === "published" && isSubmitting
											}
										/>
									</GridItem>
									{profileTypeId !== 3 && (
										<>
											<GridItem colSpan={2}>
												<FormatedInput
													label="Innovation Summary"
													name="innovation_value_summary"
													type="text"
													className="innovation_value_summary mt-6"
													inputTestId="cprofile-project-innovation-summary"
													characterLimit={1500}
													setErrors={setErrors}
													onMaxCharacter={onMaxCharacter}
													guideLine="Tell us about any innovation / point of difference / value added to the project or how you overcame any challenges during the project."
												/>
											</GridItem>
											<GridItem colSpan={2}>
												<TextArea
													label="Awards"
													testId="cprofile-project-awards"
													name="awards"
													className="col xl-12 awards"
													characterLimit={350}
													rfq
												/>
											</GridItem>
											<GridItem colSpan={2}>
												<FileUpload
													label="Client Testimonial"
													testId="cprofile-upload-testimonials"
													name="testimonials"
													className="col xl-12"
													types={["jpg", "jpeg", "png", "svg", "pdf"]}
													maxFileSize={10}
												/>
											</GridItem>
										</>
									)}

									{status && !lodash.isEmpty(errors) && (
										<GridItem colSpan={2}>
											<FlashError
												heading={errorMessages[status]?.heading || status}
												text={errorMessages[status]?.description || status}
												margin="0px 0px 20px 0px"
											/>
										</GridItem>
									)}
								</Grid>
							</VStack>

							{additionalFields}
							{supplierSmartField && <SmartFieldNote type="project" />}

							{customButtons ||
								(!hideDefaultButtons && (
									<FormButtonRow className="!mt-12">
										<div>
											<Button
												type="button"
												isSubmitting={
													values.status === "published" && isSubmitting
												}
												text="Save to Profile"
												data-testid="cprofile-save-to-profile"
												disablePointer={isSubmitting}
												disabledStyle={isSubmitting}
												disabled={!lodash.isEmpty(errors) || isSubmitting}
												onClick={() => {
													setFieldValue("status", "published", false);
													editForm && setAutoUpdateForm(true);
													submitForm();
												}}
											>
												{values.status === "published" && isSubmitting ? (
													<Spinner className="spinner" />
												) : (
													"Save to Profile"
												)}
											</Button>

											<Button
												type="button"
												data-testid="cprofile-save-draft"
												btntype="secondary"
												onClick={() => {
													editForm && setAutoUpdateForm(true);
													setFieldValue("status", "draft", false);
													submitForm();
												}}
												disablePointer={isSubmitting}
												disabledStyle={isSubmitting}
												isSubmitting={values.status === "draft" && isSubmitting}
												style={{ marginLeft: "1rem" }}
											>
												{values.status === "draft" && isSubmitting
													? "Saving..."
													: "Save Draft"}
											</Button>
										</div>

										{handleDelete && (
											<Button
												type="button"
												btntype="button"
												danger
												onClick={handleDelete}
												disablePointer={isRequesting}
												disabledStyle={isRequesting}
												data-testid="delete-project"
											>
												{!isRequesting ? "Delete Project" : "Deleting..."}
											</Button>
										)}
									</FormButtonRow>
								))}
							<OnSubmitValidationError callback={onSubmitValidationError} />
						</TabContent>
					</Form>
				);
			}}
		</Formik>
	) : (
		<Skeleton
			count={5}
			duration={0.5}
		/>
	);
};
const errorMessages = {
	required: {
		heading: "* Required Field",
		description: "There are incomplete required fields. Please complete them.",
	},
	connection: {
		heading: "Connection not established",
		description: "Unable to process your request",
	},
	maxCharacter: {
		heading: "Input has reached max character",
		description: "Input has reached max character",
	},
};
