import { Spinner } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";

import { Button } from "../../Button";

const ButtonStyled = styled(Button)`
	align-self: flex-start;
	position: relative;
	height: 60px;
	font-size: ${(props) => props.fontSize} !important;
	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};
	width: ${(props) => (props.widthExpand ? "100%" : "auto")};
	&:hover {
		background-color: #0031dd;
		border-color: #0031dd;
	}
	&:focus {
		background-color: #003ce9;
		border-color: #003ce9;
	}
`;

export const Submit = ({
	type,
	text,
	isSubmitting,
	marginTop,
	marginBottom,
	refineResults,
	fontSize,
	spinner,
	disabled,
	testId = "submit-button",
	...props
}) => {
	return (
		<ButtonStyled
			marginTop={marginTop}
			marginBottom={marginBottom}
			fontSize={fontSize}
			type={type || "submit"}
			disabled={isSubmitting || disabled}
			disablePointer={isSubmitting || disabled}
			disabledStyle={isSubmitting || disabled}
			refineResults={refineResults}
			data-testid={testId}
			{...props}
		>
			{isSubmitting ? (
				spinner ? (
					<Spinner />
				) : (
					props.submittingText || "Submitting..."
				)
			) : text ? (
				text
			) : (
				"Submit"
			)}
		</ButtonStyled>
	);
};
