import React from "react";

import { ConfirmDeleteStyled, ConfirmHeading, ButtonRow } from "./styled";
import { Button } from "../Button";
import { Heading } from "../Heading";
import { ReactComponent as Trash } from "./../../icons/trash-2.svg";
import { Icon } from "assets/icons/Icon";

export const ConfirmDelete = ({
	itemName,
	heading,
	onConfirm,
	onCancel,
	reverse,
	isRequesting,
	btnText,
	btnClassName,
	btnIcon,
	btnIconProps,
	actionName,
	...props
}) => {
	return (
		<ConfirmDeleteStyled {...props}>
			<Heading
				marginBottom={"16px"}
				fontSize="2rem"
			>
				{heading}
			</Heading>
			<ConfirmHeading {...props}>
				Are you sure you wish to{" "}
				{btnText[0] === "Delete" ? "delete" : actionName || "change"}{" "}
				{itemName || "record"}?
			</ConfirmHeading>
			<ButtonRow
				noborder={true}
				{...props}
			>
				<div>
					<Button
						size={"lg"}
						danger
						className="cancel-btn"
						btntype={"secondary"}
						width={"218px"}
						height={"60px"}
						textfont={"14px"}
						boxshadow={false}
						onClick={onCancel}
						type="button"
					>
						Cancel
					</Button>
					<Button
						size={"lg"}
						danger
						className={btnClassName || "delete-btn"}
						btntype={btnClassName || "secondary"}
						width={"218px"}
						height={"60px"}
						textfont={"14px"}
						boxshadow={false}
						onClick={onConfirm}
						type="button"
						data-testid="confirm-delete-btn"
					>
						{btnIcon ? (
							<Icon
								icon={btnIcon}
								{...btnIconProps}
							/>
						) : (
							<Trash />
						)}
						<span>{!isRequesting ? btnText[0] : btnText[1]}</span>
					</Button>
				</div>
			</ButtonRow>
		</ConfirmDeleteStyled>
	);
};
