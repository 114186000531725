import { FileUpload } from "components/RFQ/FileUpload";
import { RFQContext } from "context/RFQContext";
import { useField } from "formik";
import { useContext } from "react";
import { cn } from "utils/helpers";
import { AcceptedFileTypesForRFx } from "utils/rfxLargeFileUploadHelper";
import { removeProgressFromUploading } from "utils/rfxLargeFileUploadHelper";

export const FileInputQuestionnaire = ({
	className,
	question,
	required,
	fieldValue,
	singleFile,
	isCorporateDocument,
	...props
}) => {
	const [field, meta] = useField(props);
	const { error } = meta;
	const { rfqId } = useContext(RFQContext);
	const { setUploadInProgresses } = useContext(RFQContext);

	const existingFiles = field.value || [];

	const handleFileUploadComplete = () => {
		setUploadInProgresses((prev) => removeProgressFromUploading(prev, true));
	};

	return (
		<div
			className={cn(className)}
			{...props}
		>
			<h1 className="text-lg font-semibold text-secondary-700">
				{question}
				<span
					className={cn(
						"font-normal text-tertiary-600",
						error && props?.touchedField && "font-semibold text-red-600"
					)}
				>
					{required && <span className="text-red-600">{`*`}</span>}
					{required ? " (Required)" : " (Optional)"}
				</span>
			</h1>
			<div>
				<FileUpload
					type="file"
					className="my-4 w-full"
					types={AcceptedFileTypesForRFx}
					defaultFileName="Document"
					isDisabled={true}
					maxFileSize={100}
					singleFile={singleFile}
					multiple={isCorporateDocument}
					existingFiles={existingFiles}
					handleFileUploadComplete={handleFileUploadComplete}
					fileExtraData={{
						model: "rfq_returnable_schedule_questionnaire_file_response",
						question_id: Number(props?.name?.match(/\d+/g)), //abstract question id from question field name ( question_answer.question-question_id)
						rfq_id: rfqId,
					}}
					{...props}
					testId={`input-${props?.name}`}
				/>
			</div>
		</div>
	);
};
