import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useField, useFormikContext } from "formik";
import { createSelectStyles } from "../FormInputs/styles/selectStyles";
import { Error } from "../FormInputs/Error";
import LabelForm from "components/RFQ/LabelForm/LabelForm";
import { createMultiSelectCustomTestIdComponents } from "utils/selectHelper";

const animatedComponents = makeAnimated();

export const GlobalPresenceMulti = ({
	options,
	name,
	setFieldValue,
	values,
	noLabel,
	maxOptions,
	disabled,
	testId,
	...props
}) => {
	//eslint-disable-next-line
	const [field, meta] = useField(name);
	const { setFieldTouched } = useFormikContext();
	const isError = meta.touched && meta.error;

	const customStyles = createSelectStyles({ isError });

	const handleOnChange = (options) => {
		const sortArray = options.sort((a, b) => a.value - b.value);
		setFieldValue(name, props.sortingValue ? sortArray : options);
	};

	const customComponents = createMultiSelectCustomTestIdComponents(testId);

	return (
		<div
			className={props.className}
			style={{ position: "relative" }}
		>
			{props.label && !noLabel && (
				<LabelForm
					required={props?.required}
					label={props.label}
				/>
			)}

			<Select
				isMulti
				styles={customStyles}
				closeMenuOnSelect={false}
				components={{ ...animatedComponents, ...customComponents }} // Use custom components
				options={options}
				onChange={handleOnChange}
				onBlur={() => setFieldTouched(name, true)}
				value={values}
				placeholder="Select.."
			/>

			<Error
				meta={meta}
				errorBottom={"8px"}
			/>
		</div>
	);
};
