import { useContext, useCallback } from "react";
import { SkeletonCircle } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Button } from "components/RFQ/Button";
import { SkeletonBase } from "components/Skeleton";
import { basePathAuth } from "utils/helpers";
import { RFQContext } from "context/RFQContext";
import { navigate } from "@reach/router";
import { SubmissionSmartFieldPreviewContext } from "context/RFQSubmittedResponseContext";

export const KeyPersonnelSkeleton = ({ rfqSubmission }) => {
	return (
		<div className="my-3 min-w-[410px] animate-pulse overflow-hidden rounded-md bg-gray-100 pb-6">
			<div className="space-y-6 px-6 py-2">
				<div className="mb-3 mt-5 flex items-center justify-start gap-3">
					<SkeletonCircle
						size="56px"
						startColor="lightgray"
					/>
					<SkeletonBase width="160px" />
				</div>
				<div className="space-y-4 pb-3 pt-3">
					<SkeletonBase width="100%" />
					<SkeletonBase width="100%" />
					<SkeletonBase width="100%" />
				</div>
				{rfqSubmission && (
					<SkeletonBase
						width="100%"
						height="46px"
					/>
				)}
			</div>
		</div>
	);
};

export const KeyPersonnel = ({
	values,
	rfqSubmission,
	selected,
	selectHandler,
	companyId,
	isPreview,
	supplierSmartField,
}) => {
	const { rfqId } = useContext(RFQContext);
	const submissionSmartFieldContext = useContext(
		SubmissionSmartFieldPreviewContext
	);

	const handleViewClick = useCallback(() => {
		submissionSmartFieldContext.setModalDetail({
			data: values,
			type: "resource",
		});
		submissionSmartFieldContext.setIsModalOpen(true);
	}, [submissionSmartFieldContext, values]);

	const handleKeyPersonnelOpen = () => {
		if (supplierSmartField && rfqId) {
			navigate(
				`${basePathAuth(companyId)}request-for-quote/${rfqId}/rfx-key-personnel-editor/${values.id}`
			);
		} else {
			navigate(
				`${basePathAuth(companyId)}company-profile/resources/edit-resource/${values?.id}`
			);
		}
	};

	return (
		<div className="my-3 h-max min-w-[410px] overflow-hidden rounded-md bg-white pb-3 shadow-md">
			<div
				className="relative flex items-center justify-between"
				style={{ height: "6px" }}
			>
				<div className="absolute left-0 top-0 h-[6px] w-full bg-royal-blue2"></div>
			</div>
			<div className="space-y-6 px-6">
				<div className="mb-3 mt-5 flex items-center justify-start">
					<div className="">
						<div
							className="rounded-full"
							style={{
								width: "56px",
								height: "56px",
								backgroundImage: `url(${values?.avatar_location || "/img/user-01.svg"})`,
								backgroundSize: "cover",
							}}
						></div>
					</div>
					<div
						className="ml-4 font-roboto font-semibold capitalize text-tertiary-900"
						style={{ fontSize: "20px", lineHeight: "30px" }}
					>
						{(values?.first_name || "") + "  " + (values?.last_name || "")}
					</div>
				</div>
				<div className="pb-3 pt-1">
					<div
						className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Position
						</div>
						<div className="font-roboto text-[14px] font-semibold text-primary-900">
							{values?.position || " - "}
						</div>
					</div>
					<div
						className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Industry experience
						</div>
						<div className="font-roboto text-[14px] font-semibold text-primary-900">
							{values?.experience || 0} years
						</div>
					</div>
					<div
						className="flex items-center justify-between space-x-0"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Most Recent Project
						</div>
						<div className="line-clamp-1 max-w-[50%] font-roboto text-[14px] font-semibold text-primary-900">
							{values?.projects_completed.length > 0
								? values?.projects_completed[0]?.name
								: " - "}
						</div>
					</div>
				</div>
				<div className="flex gap-3">
					{rfqSubmission && !isPreview && (
						<>
							<Button
								type="button"
								btntype={selected ? "primary" : "secondary"}
								className="!w-full items-center gap-1.5"
								onClick={() => selectHandler(values?.id)}
								data-testid={`select-key-personnel-${values?.id}`}
							>
								{selected ? "Selected" : "Select"}
								{selected && (
									<Icon
										icon="check-only"
										width={20}
										height={20}
									/>
								)}
							</Button>
							<Button
								as="a"
								btntype="secondary"
								className={supplierSmartField ? "w-[42px]" : "!w-full"}
								onClick={handleKeyPersonnelOpen}
								target="_blank"
							>
								{supplierSmartField ? (
									<Icon icon="pencil-02" />
								) : (
									"Open Key Personnel"
								)}
							</Button>
						</>
					)}

					{isPreview && (
						<Button
							btntype="secondary"
							className="!w-full"
							type="button"
							onClick={handleViewClick}
						>
							View Key Personnel
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};
