// import { FileCard } from "components/RFQ/FileCard"

import { DEFAULT_IMAGE_BANNER } from "utils/constants";

const CardListItem = ({ title, value }) => {
	return (
		<div
			className="flex items-center justify-between gap-8 space-x-0 border-b border-solid border-[#EAECF0]"
			style={{ height: "40px" }}
		>
			<h2 className="font-roboto text-[14px] font-normal text-primary-500">
				{title}
			</h2>
			<h2 className="font-roboto text-[14px] font-semibold text-primary-900">
				{value}
			</h2>
		</div>
	);
};

const ResourceDetailCard = ({ data }) => {
	return (
		<div
			className="m-auto h-max overflow-hidden bg-white"
			style={{ minWidth: "435px" }}
		>
			<div className="space-y-8 px-6 py-8">
				<div className="flex flex-col items-center justify-start gap-8">
					<div className="">
						<div
							className="rounded-full"
							style={{
								width: "120px",
								height: "120px",
								backgroundImage: `url(${data?.avatar_location || "/img/user-01.svg"})`,
								backgroundSize: "cover",
							}}
						></div>
					</div>
					<div
						className="ml-4 text-center font-roboto font-semibold capitalize text-tertiary-900"
						style={{ fontSize: "20px", lineHeight: "30px" }}
					>
						<p>{(data?.first_name || "") + "  " + (data?.last_name || "")}</p>
						<p>{data?.email || ""}</p>
					</div>
				</div>
				<div className="pb-3 pt-1">
					<h2 className="pt-8 font-bold text-primary-900">
						Professional Detail
					</h2>
					<CardListItem
						title="Position"
						value={data?.position || "-"}
					/>
					<CardListItem
						title="Industry Experience"
						value={`${data?.experience || 0} years`}
					/>
					<CardListItem
						title="Total Projects"
						value={data?.projects_completed.length || 0}
					/>
					<h2 className="pt-8 font-bold text-primary-900">Primary Sectors</h2>
					<div className="flex gap-2 text-primary-900">
						{data?.sectors?.map((sector) => {
							return (
								<span className="rounded-xl bg-[#EAECF0] p-2">
									{sector.name}
								</span>
							);
						})}
					</div>

					{data?.projects_completed.length > 0 && (
						<>
							<h2 className="pt-8 font-bold text-primary-900">
								Recent Project
							</h2>
							<div className="flex gap-4">
								{data?.projects_completed?.map((project) => {
									return (
										<div className="rounded shadow">
											<img
												src={DEFAULT_IMAGE_BANNER}
												className="h-[80px] w-full rounded"
												alt=""
											/>
											<div className="p-4">
												<CardListItem
													title="Project Name"
													value={project?.name}
												/>
												<CardListItem
													title="Role"
													value={project?.role}
												/>
												<CardListItem
													title="Value"
													value={project?.value_humanized}
												/>
											</div>
										</div>
									);
								})}
							</div>
						</>
					)}

					{/* {data?.resume?.length>0 && <>
                    <h2 className="font-bold text-primary-900 pt-8">Resume</h2>
                    <FileCard file={{name:data?.resume[1],path:data?.resume[0]}} isPreview/>
                </>} */}
				</div>
			</div>
		</div>
	);
};

export default ResourceDetailCard;
