import React, { useContext, useEffect } from "react";
import { Router } from "@reach/router";
import { connect } from "react-redux";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { ContentContainer } from "../components/Layout";
import { ContextMenu } from "../components/ContextMenu";
import {
	getCompany,
	getDocuments,
	getPastProjects,
	getProcurementMethods,
	getResources,
} from "actions/companyActions";
//Routes
import { MyAccount } from "../routes/account/MyAccount";
import { Breadcrumbs } from "components/Breadcrumb";

import { NotFound } from "./NotFound";
import CompanyProfileV2 from "./account/companyProfile/CompanyProfileV2";
import AccountSettings from "./accountsettings/AccountSettings";
import { AddResource } from "components/ResourceForm/AddResource";
import { EditResource } from "components/ResourceForm/EditResource";
import { AddProject } from "components/ProjectForm/AddProject";
import { EditProject } from "components/ProjectForm/EditProject";
import { DocumentRequest } from "components/DocumentRequest";
import { EditUserProfile } from "components/EditUserProfile";
import { UpdateCreditCard } from "components/CreditCardDetail/CreditCardDetail";
import { CreateUserForm } from "components/UserProfile";

import { ProfileContext } from "context/ProfileContext";
import RFQPreview from "pages/RFQSubmission/RFQPreview/RFQPreview";
import RfqOverview from "pages/SupplierFlow/RfqOverviews/RfqOverviews";
import { Search } from "./search/Search";
import { Invoice } from "components/Invoice";
import { useSelector } from "react-redux";
import { EditUserAccount } from "components/EditUserAccount";
import SmartFieldProjectEditor from "components/RFQ/SmartFieldEditor/SmartFieldProjectEditor";
import SmartFieldKeyPersonnelEditor from "components/RFQ/SmartFieldEditor/SmartFieldKeyPersonnelEditor";

const AccountPageWrap = styled.div`
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	min-height: 100vh;
	${ContentContainer} {
		/* margin-left: 320px; */
	}
`;

export const SupplierComponent = ({
	company,
	getCompany,
	getPastProjects,
	getResources,
	getDocuments,
	loading,
	companyId,
	getProcurementMethods,
	getCompanySizeOptions,
	...props
}) => {
	const profileContext = useContext(ProfileContext);
	const { user, current_company } = useSelector((state) => state?.auth);
	useEffect(() => {
		//Set this company ID from the route variable - this allows anyone to access/edit all companies
		//When auth system is implemented, the getCompany action will be dispatched during login action,
		//and this data will be ready in the store

		const _companyId = user?.is_admin ? companyId : current_company?.id;

		if (_companyId) {
			getCompany(_companyId);
			getPastProjects(_companyId);
			getResources(_companyId);
			getDocuments(_companyId);
			getProcurementMethods();
		}
	}, [
		user,
		current_company,
		getCompany,
		getPastProjects,
		getDocuments,
		companyId,
		getResources,
		company,
		getProcurementMethods,
	]);

	return (
		<>
			{!loading ? (
				<AccountPageWrap>
					<ContextMenu
						name={company.name}
						logo={company.logo_path}
					/>

					<ContentContainer
						id="element-scroll"
						className="px-1.5"
					>
						<Breadcrumbs ignoreCompany={false} />
						<Router primary={false}>
							<MyAccount path="my-account" />

							{/* <CompanyProfile path="company-profile/*" company={company} /> */}
							{profileContext?.isRfqFeatureEnabled && (
								<>
									<RfqOverview path="/request-for-quote" />
									<RFQPreview path="/request-for-quote/:id/:currentTab" />
								</>
							)}
							{/* Existing Route */}
							{/* START OF ACCOUNT SETTING MENU */}
							<AccountSettings
								path="account-settings"
								company={company}
							/>
							<EditUserAccount path="edit-user-account" />
							<Invoice
								path="account-settings/invoice"
								company={current_company}
							/>
							<CreateUserForm path="account-settings/create-user" />
							<UpdateCreditCard path="account-settings/update-card-details" />
							{/* END OF ACCOUNT SETTING MENU */}
							<EditUserProfile
								path="company-profile/edit-user-profile"
								company={"My Profile"}
							/>
							<CompanyProfileV2
								path="company-profile/:activeTab"
								company={company}
							/>
							<AddResource path="company-profile/:activeTab/new-resource" />
							<EditResource path="company-profile/:activeTab/edit-resource/:resourceId" />
							<AddProject
								path="company-profile/:activeTab/create-project"
								profileTypeId={company?.profile_type?.id}
							/>
							<EditProject
								path="company-profile/:activeTab/edit-project/:projectId"
								profileTypeId={company?.profile_type?.id}
							/>
							<SmartFieldProjectEditor path="request-for-quote/:rfxId/rfx-project-editor/:projectId" />
							<SmartFieldKeyPersonnelEditor path="request-for-quote/:rfxId/rfx-key-personnel-editor/:keyPersonnelId" />
							<DocumentRequest path="company-profile/:activeTab/request-view" />
							<Search
								path="search/*"
								title="Search"
							/>

							<NotFound default />
						</Router>
					</ContentContainer>
				</AccountPageWrap>
			) : (
				<div style={{ padding: 50, flexGrow: 1 }}>
					<Skeleton
						duration={0.5}
						count={10}
						width="100%"
					/>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		company: state.auth.current_company,
		loading: false, //removed because same redux state value is used in 2 different component
	};
};

export const Supplier = connect(mapStateToProps, {
	getCompany,
	getPastProjects,
	getResources,
	getDocuments,
	getProcurementMethods,
})(SupplierComponent);
