import { Flex } from "@chakra-ui/react";
import { QUESTION_TYPES } from "components/Pages/CreateRFQ/ReturnableSchedulesCard/QuestionTypeComponent";
import DocumentCategorySelections from "./DocumentCategorySelections";

export const SmartContent = ({ smartFieldId, formik, name }) => {
	let smartFieldMessage;

	switch (smartFieldId) {
		case QUESTION_TYPES.PROJECT_EXPERIENCE:
			smartFieldMessage =
				"This feature allows respondents to select up to 3 specific projects from their profile to provide evidence of project experience as part of their submission.";
			break;
		case QUESTION_TYPES.KEY_PERSONNEL:
			smartFieldMessage =
				"This feature allows respondents to select up to 3 Key Personnel from their profile to demonstrate evidence of relevant expertise that can be applied to the project as part of their submission.";
			break;
		case QUESTION_TYPES.CORPORATE_DOCUMENTS:
			smartFieldMessage =
				"This feature allows respondents to select Corporate Documents from their profile and provide evidence as part of their submission.";
			break;
		default:
			smartFieldMessage = "Smartfield";
			break;
	}

	return (
		<Flex
			direction="column"
			gap={4}
			className="my-4 text-sm font-normal"
		>
			<div>
				<p className="text-tertiary-600">{smartFieldMessage}</p>
			</div>

			{smartFieldId === QUESTION_TYPES.CORPORATE_DOCUMENTS && (
				<DocumentCategorySelections
					formik={formik}
					name={name}
				/>
			)}
		</Flex>
	);
};
