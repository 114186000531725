import { Button } from "components/Button";
import React, { useState } from "react";
import { ReactComponent as Copy } from "./../../icons/copy.svg";
import { showToast } from "utils/helpers";

import { styled } from "styled-components";
import { Heading } from "components/Heading";

const TwoFactorComponent = styled.div`
	.twofactor-container {
		padding: 24px 0px 24px 48px;
	}
	.twofactor-title {
		font-weight: 500;
		color: #344054;
		margin-bottom: 4px !important;
	}
	.step-box {
		padding: 24px;
		border: solid 1px #d0d5dd;
		box-shadow: 0px 1px 2px 0px #1018280d;
		border-radius: 8px;
		margin: 20px 0;
		.step-header {
			margin: 2px 0 !important;
			font-size: 14px;
			font-weight: 400;
		}
		h4 {
			margin: 0 0 8px 0 !important;
			font-size: 16px;
			font-weight: 700;
			color: #344054;
		}
	}
	.qr-container {
		display: flex;
		justify-content: center;
		padding: 24px;
	}
	.separator-text,
	.non-separator-text {
		display: flex;
		justify-content: center;
		border: 1px solid #d0d5dd;
		width: 100%;
		height: 1px;
		margin: 18px 0 30px 0;
		p {
			margin-top: -16px;
			padding: 4px 8px;
			background-color: white;
			font-size: 14px;
			height: 18px;
		}
	}
	.non-separator-text {
		border: none;
	}
	.copy-field {
		padding: 0px 0 0 12px;
		display: flex;
		border: 1px solid #d0d5dd;
		align-items: center;
		border-radius: 8px;
		span {
			width: 100%;
			border-right: 1px solid #d0d5dd;
			height: 40px;
			line-height: 3;
			color: #d92d20;
		}
		button {
			cursor: pointer;
			min-width: 48px;
			border: none;
			background-color: transparent;
			margin-top: 2px;
			height: 20px;
		}
	}
	.enabled-button {
		background-color: white !important;
		border: 1px solid #d0d5dd !important;
		box-shadow: 0px 1px 2px 0px #1018280d;
		color: #00976b !important;
	}
`;

const TwoFactorStart = ({ data2fa, setCodeScreen }) => {
	const [previewCode, setPreviewCode] = useState(false);

	const secretMatch = data2fa ? data2fa?.url.match(/secret=([A-Z0-9]+)/) : null;

	const copyToClipboard = () => {
		navigator.clipboard.writeText(secretMatch?.[1]).then(() => {
			showToast("Copied to clipboard", "Success", true);
		});
	};

	return (
		<TwoFactorComponent className="twofactor-container">
			<Heading marginBottom="40px">
				<span>Add TWO-FACTOR</span>
				<span>Authentication (2FA)</span>
			</Heading>

			<div>
				<p style={{ marginBottom: 0 }}>
					Download and install the
					<a
						href="https://m.google.com/authenticator"
						rel="noreferrer"
						target="_blank"
					>
						Google Authenticator
					</a>
					,
					<a
						href="https://guide.duosecurity.com/third-party-accounts"
						rel="noreferrer"
						target="_blank"
					>
						{" "}
						Duo Mobile
					</a>
					,
					<a
						href="https://authy.com"
						rel="noreferrer"
						target="_blank"
					>
						{" "}
						Authy{" "}
					</a>
					or{" "}
					<a
						href="https://support.microsoft.com/en-us/account-billing/download-microsoft-authenticator-351498fc-850a-45da-b7b6-27e523b8702a"
						rel="noreferrer"
						target="_blank"
					>
						{" "}
						Microsoft Authenticator{" "}
					</a>
					app for your phone or tablet.
				</p>
				<p style={{ marginBottom: 0 }}>Open the authentication app and:</p>
				<ul>
					<li>{"Open the scan tool. (Top right of the app)"}</li>
					<li>{"Scan the QR code and select 'Continue' below"}</li>
				</ul>
				<div
					className="qr-container"
					dangerouslySetInnerHTML={{ __html: data2fa?.svg }}
				/>
				<div className={previewCode ? "separator-text" : "non-separator-text"}>
					{previewCode ? (
						<p>OR enter the code manually</p>
					) : (
						<p onClick={() => setPreviewCode(true)}>
							OR{" "}
							<span style={{ color: "#0045F5", cursor: "pointer" }}>
								enter the code manually
							</span>
						</p>
					)}
				</div>
				{previewCode && (
					<div className="copy-field">
						<span>{secretMatch?.[1] || null}</span>
						<button onClick={copyToClipboard}>
							<Copy />
						</button>
					</div>
				)}
				<Button
					onClick={() => setCodeScreen(true)}
					style={{ width: "100%", margin: "32px 0" }}
				>
					Continue
				</Button>
			</div>
		</TwoFactorComponent>
	);
};

export default TwoFactorStart;
