import React from "react";
import { EmailComponentType } from "./EmailComponents.type";

export const SubmissionDeadlineExtensionEmailContent: React.FC<
	EmailComponentType
> = ({ date, projectTitle, rfqTitle, time, timeZone, additionalMessage }) => {
	return (
		<div>
			<strong>
				Subject:
				{projectTitle} and {rfqTitle} Submission Deadline Extension
			</strong>
			<div>
				<p className="py-5">Dear [Participant's Name/Team],</p>
				<p className="py-2">
					We’d like to inform you that the deadline for the the RFx submission
					has been extended to{" "}
					<b>
						{date} {time} {timeZone}
					</b>
				</p>
				<p>Additional Message:</p>
				<p>{additionalMessage}</p>
				<p className="py-2">
					Please don’t hesitate to reach out if you have any questions. We look
					forward to reviewing your submission by the new date.
					<p className="py-1">Best regards,</p>
					<p className="py-1">[Your Name]</p>
					<p className="py-1">[Your Position]</p>
				</p>
			</div>
		</div>
	);
};
