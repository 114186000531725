import styled from "styled-components";

export const CompanyHeaderMediaStyled = styled.picture`
	display: flex;
	position: relative;
`;

export const CompanyBannerImage = styled.div`
	width: 100%;

	img {
		width: 100%;
		height: 200px;
		object-fit: cover;
	}
`;

export const CompanyHeaderLogo = styled.div`
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateY(50%) translateX(-50%);
`;
