import { Spinner } from "@chakra-ui/react";
import {
	PrivacyPolicyLink,
	TermsAndConditionLink,
} from "components/ContextMenu/MenuTerms";
import { Button } from "components/RFQ/Button";
import { CustomModal } from "components/RFQ/Modal/CustomModal";
type TermsAndConditionModalPropType = {
	isModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	handleOnAccept: () => void;
	handleClose?: () => void;
	showCloseButton?: boolean;
	isLoading?: boolean;
};

export const TermsAndConditionModal: React.FC<
	TermsAndConditionModalPropType
> = ({
	handleClose,
	handleOnAccept,
	isModalOpen,
	showCloseButton = true,
	isLoading,
}) => {
	return (
		<CustomModal
			isOpen={isModalOpen}
			onClose={handleClose}
			header={{
				title: `Important notice`,
				icon: `request-for-quote`,
				bgColor: "bg-brand-secondary",
				iconColor: "text-utility-brand-700",
			}}
			footerComponent={
				<div className="flex w-full justify-between gap-2">
					{/* @ts-ignore */}
					<Button
						className="!w-full !normal-case"
						btntype="primary"
						onClick={handleOnAccept}
					>
						{isLoading ? <Spinner /> : "Yes"}
					</Button>
					{showCloseButton && (
						<>
							{/* @ts-ignore */}
							<Button
								className="!w-full"
								btntype={"secondary"}
								onClick={handleClose}
							>
								Close
							</Button>
						</>
					)}
				</div>
			}
		>
			<span>
				This is an annual reminder that we periodically update our Terms of Use
				and Policies. To continue, please confirm that you have read and agree
				to the current{" "}
				<TermsAndConditionLink className="text-black underline" /> and{" "}
				<PrivacyPolicyLink className="text-black underline" />
			</span>
		</CustomModal>
	);
};
