import React, { useContext } from "react";
import { Box } from "@chakra-ui/react";
import { renderQuestion } from "components/RFQ/QuestionComponent";
import { SectionComponent } from "components/RFQ/QuestionComponent/_components/SectionComponent";
import { fieldTypeQuestion } from "helpers/Enum/FieldTypeList";
import { useSelector } from "react-redux";
import ReturnableSchedulesFileUploadComp from "./ReturnableSchedulesFileUploadComp";
import { filterFieldValues } from "utils/helpers";
import { RFQContext } from "context/RFQContext";
import { useFormikContext } from "formik";
import { hasDocumentsForCategories } from "./RFQUtils/RFQHelper";

const ReturnableSchedulesComp = ({
	questionnaire,
	touchedForm,
	isGuest,
	isReturnableSchedulesTypeFile,
	companyName,
	templateReturnableScheduleFile,
	disabled,
}) => {
	const { documents, resources, pastProjects } = useSelector(
		(state) => state?.companyProfile
	);
	const formik = useFormikContext();

	let hasResources = resources?.length > 0;
	let hasPasProjects = pastProjects?.length > 0;
	const { rfqId, guestUserId, currentCompanyId } = useContext(RFQContext);

	return (
		<div className="space-y-10">
			{isReturnableSchedulesTypeFile === "file" ? (
				<ReturnableSchedulesFileUploadComp
					companyName={companyName}
					templateReturnableScheduleFile={templateReturnableScheduleFile}
					currentCompanyId={currentCompanyId}
					rfqId={rfqId}
					guestUserId={guestUserId}
					disabled={disabled}
				/>
			) : (
				questionnaire?.map((section, sectionID) => {
					return (
						<Box
							className="block min-h-80 w-full space-y-8 rounded-xl border p-10 pt-4 shadow-xl"
							key={sectionID}
						>
							<SectionComponent
								sectionTitle={section?.name || " - "}
								description={section?.description}
								rfqSubmission={true}
							/>
							{section.question?.map((question, index) => {
								let nameField = `question_answer.question-${question.id}`;
								// project_experience나 key_personnel이면서 리소스가 없는 경우
								if (
									(question?.fields?.type === "project_experience" &&
										!hasPasProjects) ||
									(question?.fields?.type === "key_personnel" && !hasResources)
								) {
									nameField = `question_answer.question-${question.id}.additional`;
								}
								const questionProps = {
									type: fieldTypeQuestion[question.type]?.type || "table",
									name: nameField,
									question: question.name,
									typeQuestion:
										fieldTypeQuestion[question.type]?.type || "table",
									fieldValue: filterFieldValues(question) || [],
									description: fieldTypeQuestion[question.type]?.description,
									rfqSubmission: true,
									required: question.required,
									touchedField: touchedForm,
									isGuest: isGuest,
									disabled: disabled,
									formik: formik,
								};

								// Get hasDocuments value for this specific question
								const hasDocuments =
									question?.fields?.type === "corporate_documents"
										? hasDocumentsForCategories(question, documents)
										: false;

								return renderQuestion(
									questionProps,
									index,
									hasDocuments,
									hasResources,
									hasPasProjects
								);
							})}
						</Box>
					);
				})
			)}
		</div>
	);
};

export default ReturnableSchedulesComp;
