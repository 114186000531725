import React, { useContext, useEffect, useState } from "react";
import { ContextMenuStyled } from "./styled";
import { connect } from "react-redux";
import { ShareProfileModal } from "../ShareProfile/ShareProfileModal";
import ContextMenuClient from "./ContextMenuClient";
import ContextMenuSupplier from "./ContextMenuSupplier";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import ContextMenuGuest from "./ContextMenuGuest";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { sidebarCollapse } from "actions/sidebarActions";
import { ProfileContext } from "context/ProfileContext";
import { TermsAndConditionModal } from "components/SignUpForm/SignupTermsModal";
import { acceptTermsAndConditions } from "actions/authActions";
import { showToast } from "utils/helpers";
export const ContextMenuComponent = ({
	user,
	company,
	company_current,
	profile_type,
	isDefaultCollapsed,
	...props
}) => {
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { sidebarcollapse } = useSelector((state) => state.sidebar);
	const [isCollapseNav, setIsCollapseNav] = useState(
		isDefaultCollapsed || sidebarcollapse
	);
	const dispatch = useDispatch();
	const { isClientProfileActive } = useContext(ProfileContext);

	if (!props.name) {
		props.name = company_current.name;
	}
	if (!props.logo) {
		props.logo = company_current.logo_path;
	}

	useEffect(() => {
		setIsCollapseNav(sidebarcollapse);
	}, [sidebarcollapse]);

	const showTermsAndConditionsModal = () => {
		return Object.keys(company).length > 1
			? !company?.feature_flags?.find((obj) => obj.name === "t&c")
			: false;
	};

	const handleAcceptTermsAndConditions = async () => {
		try {
			setIsLoading(true);
			await dispatch(acceptTermsAndConditions());
			setIsLoading(false);
			showToast("Terms and Conditions accepted", "Success", true);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
			showToast("Failed to accept terms and conditions", "Error", false);
		}
	};

	return (
		<>
			<div
				style={{ position: "relative", width: "fit-content" }}
				className="group"
			>
				<div
					id="notch"
					className={`notch z-50 flex items-center justify-center rounded-br-md rounded-tr-md ${!isCollapseNav ? `left-[379px] w-[10px] bg-gray-utility-100 group-hover:w-[20px] group-hover:bg-utility-blue-light-50` : `left-[60px] w-[26px] bg-royal-blue group-hover:w-[36px]`}`}
					onClick={() => {
						const elements = document.getElementsByClassName("sidemuchild");
						const profile = document.getElementById("profile");
						const notch = document.getElementById("notch");

						if (isCollapseNav) {
							notch.classList.toggle("moved");
							Array.from(elements).forEach((element) => {
								element.classList.add("sidemuchildanimate");
							});
							if (profile) {
								profile.classList.remove("profile-hide");
								profile.classList.add("fadeInProfileAnimation");
							}
						} else {
							notch.classList.toggle("moved");
							Array.from(elements).forEach((element) => {
								element.classList.remove("sidemuchildanimate");
							});
							if (profile) {
								profile.classList.remove("fadeInProfileAnimation");
								profile.classList.add("profile-hide");
							}
						}
						dispatch(sidebarCollapse(!isCollapseNav));
					}}
				>
					<div
						style={{ height: "44px", transition: "all 0.4s ease-in-out" }}
						className={`absolute ${!isCollapseNav ? "right-2 w-6 bg-gray-utility-100 group-hover:w-11 group-hover:bg-utility-blue-light-50" : "right-5 w-4"} } z-50 flex items-center rounded-bl-md rounded-tl-md`}
					>
						<div
							className={
								isCollapseNav
									? "ml-2 w-40 text-2xl"
									: `ml-1 text-2xl text-royal-blue`
							}
							style={{ transition: "all 0.4s ease-in-out" }}
						>
							{isCollapseNav ? (
								<ChevronRightIcon
									alt="arrow-right"
									style={{
										verticalAlign: "middle",
										color: "#fff",
										marginTop: "-6px",
									}}
									className="chevron-right"
								/>
							) : (
								<ChevronLeftIcon
									alt="arrow-left"
									style={{ marginTop: "-6px" }}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			<div
				className="relative"
				style={{
					height: "max-content",
					position: "sticky",
					top: 0,
					zIndex: isCollapseNav ? 0 : 2,
				}}
			>
				<ContextMenuStyled
					company={company}
					collapse={isCollapseNav}
				>
					{/* */}
					{isClientProfileActive ? (
						<ContextMenuClient
							setIsCollapseNav={setIsCollapseNav}
							isCollapseNav={isCollapseNav}
							props={props}
						/>
					) : (
						<>
							{localStorage.getItem("role") === "guest" ? (
								<ContextMenuGuest
									setIsCollapseNav={setIsCollapseNav}
									isCollapseNav={isCollapseNav}
									props={props}
								/>
							) : (
								<ContextMenuSupplier
									setIsCollapseNav={setIsCollapseNav}
									isCollapseNav={isCollapseNav}
									props={props}
								/>
							)}
						</>
					)}
				</ContextMenuStyled>
				<ShareProfileModal
					isOpen={isOpenModal}
					setIsOpen={setIsOpenModal}
				/>
				<TermsAndConditionModal
					isModalOpen={showTermsAndConditionsModal()}
					handleOnAccept={handleAcceptTermsAndConditions}
					handleClose={() => {}}
					showCloseButton={false}
					isLoading={isLoading}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		company_current: state.auth.current_company,
		user: state.auth.user,
		company: state.search.activeCompany,
		profile_type: state.profile_type,
	};
};

export const ContextMenu = connect(mapStateToProps, {})(ContextMenuComponent);
