import { Icon } from "assets/icons/Icon";
import {
	DropdownItem,
	DropdownMenu,
	DropdownMenuDrawer,
	DropdownMenuTrigger,
} from "components/RFQ/MenuDropdown";
import React from "react";

const EditDropdown = ({ id, className, removeHandler, noBg }) => {
	return (
		<div
			className={className}
			data-testid={`edit-dropdown-${id}`}
		>
			<DropdownMenu>
				<DropdownMenuTrigger
					icon
					noBg={noBg}
				></DropdownMenuTrigger>
				<DropdownMenuDrawer>
					<DropdownItem
						className="w-full"
						onClick={removeHandler}
					>
						<Icon icon="delete" />
						<p data-testid={`detach-${id}`}>Detach List</p>
					</DropdownItem>
				</DropdownMenuDrawer>
			</DropdownMenu>
		</div>
	);
};

export default EditDropdown;
