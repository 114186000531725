import api from "api";
import * as constants from "./actionTypes";
import {
	clearSignupData,
	isEmptyObj,
	isPAPLUsers,
	processFormData,
	showToast,
} from "../utils/helpers";
import { navigate } from "@reach/router";
import { getCompany } from "./companyActions";
import { toFormData } from "axios";
import apiV2 from "api-v2";
import { getCookie, removeAllCookie, setCookie } from "utils/cookiesHelper";
import { setUserRole } from "utils/authHelper";

const profileType = getCookie("profile_type");

function getApiWithToken() {
	const token = getCookie("access_token");
	return api.extend({
		hooks: {
			beforeRequest: [
				(request) => {
					request.headers.set("Authorization", `Bearer ${token}`);
				},
			],
		},
	});
}

export const authenticateUser = (values) => async (dispatch) => {
	const formData = processFormData(values);

	try {
		const user = await api.post("auth/login", { body: formData }).json();
		if (user.success === true) {
			if (user?.user?.two_factor_enabled) {
				await dispatch({
					type: constants.AUTHENTICATE_TWO_FACTOR,
					payload: {
						access_token: user.access_token,
						two_factor_enabled: true,
					},
				});
				localStorage.setItem("two_factor_enabled", true);
				localStorage.setItem("two_factor_verified", false);
				setCookie("access_token", user.access_token);
				localStorage.setItem("user", JSON.stringify(user.user));
				navigate("/two-factor");
			} else {
				localStorage.setItem("two_factor_enabled", false);
				localStorage.setItem("two_factor_verified", false);
				setCookie("access_token", user.access_token);
				// localStorage.setItem("access_token", user.access_token);
				localStorage.setItem("user", JSON.stringify(user.user));
				if (isPAPLUsers(user.user)) {
					navigate("/two-factor-setup");
				} else {
					saveUserSecret(user);

					// store user info on cookies
					setCookie("users", JSON.stringify(user));

					const userProfile = await getApiWithToken()
						.get("auth/user-profile")
						.json();

					const companyProfile = await getApiWithToken()
						.get(`companies/${userProfile.data.company_id}`)
						.json();

					saveRoleToCookie(companyProfile?.data?.plan);

					setCookie(
						"rfq_feature",
						companyProfile?.data?.enable_rfx_functionality
					);

					localStorage.setItem("company_id", userProfile.data.company_id);
					localStorage.setItem("user", JSON.stringify(userProfile.data));

					localStorage.setItem(
						"sidebar",
						JSON.stringify({ sidebarcollapse: false })
					);
					await dispatch({
						type: constants.AUTHENTICATE_USER,
						payload: {
							access_token: user.access_token,
							two_factor_enabled: user?.user?.two_factor_enabled,
						},
					});
					await dispatch({
						type: constants.GET_USER,
						payload: userProfile.data,
					});
					await dispatch(getCurrentUser());
				}
			}
		}
	} catch (e) {
		throw e;
	}
};

export const signup = (
	id,
	first_name,
	last_name,
	email,
	password,
	agreed,
	setSubmitting
) => {
	return async (dispatch) => {
		const formData = processFormData({
			id: id,
			first_name: first_name,
			last_name: last_name,
			email: email,
			password: password,
			isAgree: agreed,
		});
		try {
			const user = await api
				.post("auth/users/create", {
					body: formData,
				})
				.json();

			if (user.success === true) {
				await dispatch({
					type: constants.GET_SIGNUP_DETAILS,
					payload: user.user,
				});
				setCookie("access_token", user.access_token);
				return user;
			} else {
				setSubmitting(false);
				return user;
			}
		} catch (e) {
			const error = await e.response.json();
			const errorMessages = error.errors?.password;
			const errorMessage =
				errorMessages.length < 2
					? errorMessages[0]
					: errorMessages[errorMessages.length - 1];
			showToast(errorMessage || error.error, "Error");
		}
	};
};

export const saveBusinessDetails = (
	id,
	company_id,
	business_name,
	phone,
	abn,
	setSubmitting
) => {
	return async (dispatch) => {
		const formData = processFormData({
			id: id,
			company_id: company_id,
			business_name: business_name,
			phone: phone,
			abn: abn,
		});
		try {
			const user = await api
				.post("auth/business/create", {
					body: formData,
				})
				.json();

			if (user.success === true) {
				await dispatch({
					type: constants.GET_SIGNUP_DETAILS,
					payload: user.user,
				});
				return user;
			} else {
				setSubmitting(false);
				return user;
			}
		} catch (e) {
			const error = await e;
			if (error.response.status === 404) {
				let errorMessage =
					"Please refresh the page and start the process again. If you continue to have issues, feel free to email us at support@procuracon.com.au";
				showToast(errorMessage, "Error");
				clearSignupData();
				return error.response.status;
			} else showToast(error.response, "Error");
			showToast(error.error, "Error");
		}
	};
};

export const setSignupData = (data) => {
	return { type: constants.GET_SIGNUP_DETAILS, payload: data };
};

export const completeRegistration = (values, setSubmitting) => {
	return async (dispatch) => {
		const formData = processFormData({
			id: values.id,
			account_type_id: parseInt(values.account_type_id),
			profile_type_id: values.profile_type_id
				? parseInt(values.profile_type_id)
				: null,
			payment_method: values.payment_method,
			token_id: values.token_id,
			discount_code: values.discount_code,
			automatic_subscription: values.automatic_subscription,
		});
		try {
			const res = await api
				.post("auth/users/complete-registration", {
					body: formData,
					timeout: false,
				})
				.json();
			if (res.success === true) {
				clearSignupData();
				await dispatch({
					type: constants.GET_SIGNUP_DETAILS,
					payload: {},
				});
				showToast("Registration Completed", "Success", true);
				return res;
			} else {
				setSubmitting(false);
				return res;
			}
		} catch (e) {
			const error = await e;
			if (error.response.status === 404) {
				let errorMessage =
					"Please refresh the page and start the process again. If you continue to have issues, feel free to email us at support@procuracon.com.au";
				showToast(errorMessage, "Error");
				clearSignupData();
				return error.response.status;
			} else showToast(error.response, "Error");
		}
	};
};

export const forgotPassword = (values, setSubmitting) => {
	return async () => {
		const formData = processFormData(values);

		try {
			const status = await api
				.post("auth/forget-password", { body: formData })
				.json();

			if (status.status) {
				setSubmitting(false);
				return status;
			} else {
				setSubmitting(false);
				return status;
			}
		} catch (e) {
			const error = await e.response.json();
			console.log(error.error);
		}
	};
};

export const resetPassword = (values, setSubmitting) => {
	return async () => {
		const formData = processFormData(values);

		try {
			const status = await api
				.post("auth/reset-password", { body: formData })
				.json();

			if (status.status) {
				showToast("Reset Password. Redirecting now.", "Success", true);
				navigate("/login");
			} else {
				showToast(status.message, "Error");
			}
		} catch (e) {
			const error = await e.response.json();
			const errorMessages = error.errors?.password;
			const errorMessage =
				errorMessages.length < 2
					? errorMessages[0]
					: errorMessages[errorMessages.length - 1];
			showToast(errorMessage || error.error, "Error");
		}

		setSubmitting(false);
	};
};

export const applyDiscount = (id) => {
	return async () => {
		try {
			const result = await api
				.get(`apply-discount/${encodeURIComponent(id)}`)
				.json();
			return result;
		} catch (e) {
			const error = await e.response.json();
			showToast(error.error, "Error");
		}
	};
};

export const createCompanyUser = (values) => {
	return async () => {
		const formData = processFormData(values);
		try {
			const result = await api
				.post("users/create-company-user", {
					body: formData,
				})
				.json();
			if (result.success === true) {
				return result;
			} else {
				showToast(result.message, "Error");
				return result;
			}
		} catch (e) {
			const error = await e.response.json();
			const errorMessages = error.errors?.password;
			const errorMessage =
				errorMessages.length < 2
					? errorMessages[0]
					: errorMessages[errorMessages.length - 1];
			throw errorMessage || error.error;
		}
	};
};

export const verifyEmail = (email, token) => {
	return async (dispatch) => {
		(async () => {
			const formData = processFormData({ email: email, code: token });

			try {
				const res = await getApiWithToken()
					.post("auth/verify-email", { body: formData, timeout: false })
					.json();
				if (res.status === true) {
					const acc_token = getCookie("access_token");
					if (acc_token) {
						const user = await getApiWithToken()
							.get("auth/user-profile")
							.json();
						localStorage.setItem("company_id", user.data.company_id);
						localStorage.setItem("user", JSON.stringify(user.data));

						await dispatch({
							type: constants.GET_USER,
							payload: user.data,
						});
						showToast("Email Verified. Redirecting now.", "Success", true);
					} else
						showToast("Email has been Verified. Login Now", "Success", true);
					navigate("/login");
				} else {
					showToast(res.message, "Error");
					const access_token = getCookie("access_token");
					if (access_token) navigate("/verify-email", { replace: true });
					else navigate("/login", { replace: true });
				}
			} catch (e) {
				const error = await e.response.json();
				showToast(error.error, "Error");
				navigate("/login", { replace: true });
			}
		})();
	};
};

export const setAccessToken = (token, twoFactorPassed) => {
	return async (dispatch) => {
		(async () => {
			if (twoFactorPassed) {
				await dispatch({
					type: constants.AUTHENTICATE_TWO_FACTOR,
					payload: {
						access_token: token,
					},
				});
			} else {
				await dispatch({
					type: constants.AUTHENTICATE_USER,
					payload: {
						access_token: token,
					},
				});
			}

			await dispatch(getCurrentUser());
		})();
	};
};

export const getCurrentUser = () => {
	return async (dispatch) => {
		(async () => {
			try {
				const user = await getApiWithToken().get("auth/user-profile").json();

				if (user.data && !isEmptyObj(user.data)) {
					localStorage.setItem("company_id", user.data.company_id);
					localStorage.setItem("user", JSON.stringify(user.data));

					await dispatch({
						type: constants.GET_USER,
						payload: user.data,
					});
					const company = await getApiWithToken()
						.get(
							`companies/${user.data.company_id}?past_projects_published=false`
						)
						.json();

					setCookie("is_admin", user?.data?.is_admin);

					if (!profileType) {
						setCookie("profile_type", company?.data?.profile_type?.id);
					}
					saveRoleToCookie(company?.data?.plan);
					await dispatch({
						type: constants.GET_CURRENT_COMPANY,
						payload: company.data,
					});
					// await createProfileType(company?.data?.profile_type.id)
					await dispatch({
						type: constants.CREATE_PROFILE_TYPE,
						payload: profileType
							? parseInt(profileType)
							: company?.data?.profile_type?.id,
					});
				} else {
					dispatch(logoutUser());
				}
			} catch (e) {
				console.log("error while fetching user", e);
				dispatch(logoutUser());
			}
		})();
	};
};

export const setLoggedInUser = (user) => {
	return async (dispatch) => {
		(async () => {
			await dispatch({
				type: constants.GET_USER,
				payload: user,
			});
			await dispatch(getCompany(user.company_id));
		})();
	};
};

export const logoutUser = () => {
	return async (dispatch) => {
		(async () => {
			try {
				const token = getCookie("access_token");
				const logoutApi = api.extend({
					hooks: {
						beforeRequest: [
							(request) => {
								request.headers.set("Authorization", `Bearer ${token}`);
							},
						],
					},
				});

				const logout = await logoutApi.post("auth/logout").json();

				if (logout.success === true) {
					localStorage.clear();
					removeAllCookie();
					await dispatch({ type: constants.LOGOUT_USER });
					navigate("/login", { replace: true });
				} else {
					showToast(logout.error, "Error");
					removeAllCookie();
					localStorage.clear();
				}
			} catch (e) {
				const error = await e.response?.json();
				showToast(error?.error, "Error");
				localStorage.clear();
			}
		})();
	};
};

export const logoutGuestUser = () => {
	return async (dispatch) => {
		(async () => {
			try {
				localStorage.clear();
				removeAllCookie();
				navigate("/guest/login", { replace: true });
				await dispatch({ type: constants.LOGOUT_GUEST_USER });
			} catch (e) {
				const error = await e.response?.json();
				showToast(error?.error, "Error");
			}
		})();
	};
};

export const guestSignup = (values, setSubmitting, setStatus) => {
	return async (dispatch) => {
		values.password_confirmation = values.password;
		const formData = processFormData(values);
		try {
			const res = await apiV2.post("guest/register", formData);
			if (res.status === 200) {
				showToast(res?.data?.message, "Success", true);
				navigate("/thank-you");
			} else {
				setSubmitting(false);
				return res;
			}
		} catch (error) {
			setSubmitting(false);
			throw error;
		}
	};
};

export const guestLogin = (values) => {
	return async (dispatch) => {
		try {
			const res = await api
				.post("guest/login", { body: processFormData({ ...values }) })
				.json();
			if (res.success) {
				localStorage.setItem("guest_user", JSON.stringify(res.user));
				setCookie("access_token", res?.user?.token);
				localStorage.setItem("role", "guest");
				!getCookie("role") && setCookie("role", "guest");
				await dispatch({
					type: constants.AUTHENTICATE_GUEST_USER,
					payload: res.user,
				});
			}
		} catch (error) {
			throw error;
		}
	};
};

export const shareProfile = (token) => {
	return async () => {
		try {
			const result = await getApiWithToken()
				.get(`auth/share-profile?invitetoken=${token}`)
				.json();
			return result;
		} catch (e) {
			const error = await e.response.json();
			showToast(error.error, "Error");
			return error;
		}
	};
};

export const upgradeAccount = (email) => {
	return async (dispatch) => {
		(async () => {
			try {
				localStorage.clear();
				navigate("/signup", { replace: true, state: { email: email } });
				await dispatch({ type: constants.LOGOUT_GUEST_USER });
			} catch (e) {
				const error = await e.response?.json();
				showToast(error?.error, "Error");
			}
		})();
	};
};

export const enablingTwoFactor = async () => {
	try {
		const result = await getApiWithToken().get("2fa/two-factor-code").json();
		if (result.status) {
			return result.data;
		} else {
			showToast(result.message, "Error");
		}
	} catch (e) {
		const error = e?.response?.data?.errors?.code[0] || "Failed to enable 2FA";
		showToast(error, "Error");
		return error;
	}
};

export const submitTwoFactorCode = async (values) => {
	const data = toFormData({ code: values });
	try {
		const result = await getApiWithToken()
			.post("2fa/two-factor-confirm", { body: data })
			.json();
		if (result.success) {
			showToast("2FA enabled", "Success", true);
			setCookie("access_token", result.access_token);
			localStorage.setItem("two_factor_enabled", true);
			localStorage.setItem("two_factor_verified", true);
			return result;
		} else {
			showToast("Invalid Code", "Error");
		}
	} catch (e) {
		const error = e?.response?.data?.errors?.code[0] || "Failed to enable 2FA";
		showToast(error, "Error");
	}
};

export const enableTwoFactor = () => {
	return async (dispatch) => {
		dispatch({
			type: constants.ENABLED_TWO_FACTOR,
		});
	};
};

export const authTwoFactorLogin = (values, isBackupCode) => {
	return async (dispatch) => {
		const formData = processFormData({ code: values });
		try {
			const result = await getApiWithToken()
				.post(
					isBackupCode
						? "2fa/two-factor-recovery-codes"
						: "2fa/two-factor-confirm",
					{ body: formData }
				)
				.json();
			if (result.success) {
				const user = result;

				saveUserSecret(user);
				localStorage.setItem("two_factor_verified", true);

				const userProfile = await getApiWithToken()
					.get("auth/user-profile")
					.json();

				localStorage.setItem("company_id", userProfile.data.company_id);
				localStorage.setItem("user", JSON.stringify(userProfile.data));

				await dispatch({
					type: constants.AUTHENTICATE_TWO_FACTOR,
					payload: {
						access_token: user.access_token,
					},
				});
				await dispatch({
					type: constants.GET_USER,
					payload: userProfile.data,
				});
				await dispatch(getCurrentUser());
			} else {
				showToast(result.message, "Error");
			}
		} catch (e) {
			const error = e?.response?.data?.errors?.code[0] || "Invalid Code";
			showToast(error, "Error");
			return error;
		}
	};
};

export const deactivateTwoFactor = () => {
	return async (dispatch) => {
		try {
			const result = await getApiWithToken()
				.post("2fa/two-factor-disable")
				.json();
			if (result.status) {
				await dispatch({
					type: constants.DEACTIVATE_TWO_FACTOR,
				});
				localStorage.setItem("two_factor_enabled", false);
				localStorage.setItem("two_factor_verified", false);
				showToast(result.message, "Success");
				return result.data;
			} else {
				showToast(result.message, "Error");
			}
		} catch (e) {
			const error =
				e?.response?.data?.errors?.code[0] || "Failed to disable 2FA";
			showToast(error, "Error");
			return error;
		}
	};
};

export const continueTwoFactorSetup = (user) => {
	return async (dispatch) => {
		saveUserSecret(user);
		localStorage.setItem("two_factor_verified", true);

		const userProfile = await getApiWithToken().get("auth/user-profile").json();

		localStorage.setItem("company_id", userProfile.data.company_id);
		localStorage.setItem("user", JSON.stringify(userProfile.data));

		await dispatch({
			type: constants.AUTHENTICATE_TWO_FACTOR,
			payload: {
				access_token: user.access_token,
			},
		});
		await dispatch({
			type: constants.GET_USER,
			payload: userProfile.data,
		});
		await dispatch(getCurrentUser());
	};
};

export const getBackupCodes = async () => {
	try {
		const response = await getApiWithToken()
			.get("2fa/two-factor-recovery-codes")
			.json();
		if (response.status) {
			return response.data;
		} else {
			showToast(response.message, "Error");
		}
	} catch (e) {
		console.log(e);
	}
};

export const checkAccessToken = async () => {
	const status = await getApiWithToken().post("auth/check-access-token").json();
	return status.status;
};

const saveUserSecret = (user) => {
	setCookie("access_token", user.access_token);
	setCookie("is_admin", user.is_admin);
	setCookie("company_admin", user.company_admin || null);
};

const saveRoleToCookie = (plan) => {
	if (!getCookie("role")) {
		setUserRole(plan);
	}
};

export const acceptTermsAndConditions = () => async (dispatch) => {
	const formData = new FormData();
	formData.append("accept", 1);
	const result = await getApiWithToken()
		.post("auth/accept-term", {
			body: formData,
		})
		.json();

	if (result.status) {
		await dispatch({
			type: constants.GET_CURRENT_COMPANY,
			payload: result?.data,
		});
		return result;
	} else {
		throw new Error(result.message);
	}
};
