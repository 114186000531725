import React from "react";
import { cn } from "utils/helpers";

export const ToggleButton = ({
	size = "default",
	className,
	label,
	description,
	children,
	...props
}) => {
	const sizes = {
		default: "w-9 h-5 after:h-4 after:w-4",
		lg: "w-11 h-6 after:h-5 after:w-5",
	};

	return (
		<div>
			<label
				className={cn(
					"inline-flex cursor-pointer gap-2",
					!description && "items-center align-middle"
				)}
			>
				<div>
					<label
						className={cn(
							"flex h-max cursor-pointer items-center gap-3 text-sm font-semibold",
							description && "items-start"
						)}
					>
						{label}
						<input
							type="checkbox"
							value=""
							className="peer sr-only"
							{...props}
						/>
						<div
							className={cn(
								"peer-focus:ring-brand-50 peer relative rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:rounded-full after:border after:border-gray-300 after:bg-white after:shadow-sm after:transition-all after:content-[''] peer-checked:bg-royal-blue peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 rtl:peer-checked:after:-translate-x-full dark:border-gray-600",
								sizes[size],
								className
							)}
						></div>
						<div>
							<p className="text-sm font-normal">{description}</p>
						</div>
					</label>
				</div>
				{children}
			</label>
		</div>
	);
};
