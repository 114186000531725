import React, { useEffect } from "react";
import { Select } from "../Select";
import { connect } from "react-redux";

import { getCompanySizeOptions } from "actions/companyActions";

const CompanySizeConnected = ({
	name,
	defaultValue,
	value,
	getCompanySizeOptions,
	profileType,
	options,
	filter,
	noOptionsMessage,
	guideLine,
	testId,
	...props
}) => {
	useEffect(() => {
		getCompanySizeOptions(profileType);
	}, [getCompanySizeOptions, profileType]);

	useEffect(() => {}, [options]);
	if ((!filter && (profileType === 3 || profileType === 4)) || props.editForm)
		options.splice(-1);
	if (value) {
		value = options
			? options.find((item) => {
					return item.value === parseInt(value);
				})
			: {};
	}

	if (defaultValue) {
		defaultValue = options
			? options.find((item) => {
					return item.value === parseInt(defaultValue);
				})
			: {};
	}

	return (
		<Select
			required="true"
			options={options}
			name={name}
			label={props.label || "Size of Company"}
			className={props.className}
			guideLine={guideLine}
			// defaultValue={defaultValue}
			value={value}
			debug
			testId={testId}
			noOptionsMessage={() => {
				return noOptionsMessage ? noOptionsMessage : "No options available";
			}}
		/>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		options: state.companyProfile.companySizeOptions
			? state.companyProfile.companySizeOptions.map((option) => ({
					value: option.id,
					label:
						ownProps.filter && option.name === "All of above"
							? "Show All"
							: option.name,
				}))
			: [],
	};
};

export const CompanySize = connect(mapStateToProps, { getCompanySizeOptions })(
	CompanySizeConnected
);
