import { useState, useCallback } from "react";
import { navigate } from "@reach/router";

export const useSmartFieldEditor = ({
	id, // keyPersonnelId or projectId
	rfxId,
	companyId,
	entityType, // "KeyPersonnel" or "Project"
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalSaving, setIsModalSaving] = useState(false);
	const [isInModalFlow, setIsInModalFlow] = useState(false);
	const [errorStatus, setErrorStatus] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [autoSaveDisabled, setAutoSaveDisabled] = useState(true);

	// Navigate to returnable schedules page
	const navigateToReturnableSchedules = useCallback(() => {
		navigate(
			`/account/${companyId}/request-for-quote/${rfxId}/returnable-schedules`
		);
	}, [companyId, rfxId]);

	// Handle cancel button click
	const handleCancelClick = useCallback(() => {
		setIsInModalFlow(true);
		setIsModalOpen(true);
	}, []);

	// Handle modal close
	const handleModalClose = useCallback(() => {
		setIsModalOpen(false);
		setIsInModalFlow(false);
	}, []);

	return {
		// State
		isModalOpen,
		setIsModalOpen,
		isModalSaving,
		setIsModalSaving,
		isInModalFlow,
		setIsInModalFlow,
		errorStatus,
		setErrorStatus,
		isSaving,
		setIsSaving,
		autoSaveDisabled,
		setAutoSaveDisabled,

		// Actions
		navigateToReturnableSchedules,
		handleCancelClick,
		handleModalClose,
	};
};
