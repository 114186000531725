import EmailComponent from "components/email-template/EmailComponent";
import { EmailComponentType } from "components/email-template/EmailComponents.type";
import EmailFooter from "components/email-template/EmailFooter";
import EmailHeader from "components/email-template/EmailHeader";

const EmailPreview: React.FC<EmailComponentType> = ({
	companyName,
	rfqTitle,
	projectTitle,
	additionalMessage,
	date,
	time,
	timeZone,
	isRFQInvitation,
}) => {
	return (
		<div
			style={{
				maxWidth: "664px",
				margin: "0 auto",
				position: "relative",
				height: "auto",
			}}
		>
			<EmailHeader />
			<EmailComponent
				companyName={companyName}
				rfqTitle={rfqTitle}
				projectTitle={projectTitle}
				additionalMessage={additionalMessage}
				date={date}
				time={time}
				timeZone={timeZone}
				isRFQInvitation={isRFQInvitation}
			/>
			<EmailFooter />
		</div>
	);
};

export default EmailPreview;
