import { useField } from "formik";
import { cn } from "utils/helpers";
import { DateInput } from "components/DateInput";

export const DateSelectionQuestionnaire = ({
	className,
	question,
	required,
	fieldValue,
	...props
}) => {
	const [, { error }] = useField(props);

	return (
		<div className={cn(className)}>
			<h1 className="text-lg font-semibold text-secondary-700">
				{question}
				<span
					className={cn(
						"font-normal text-tertiary-600",
						error && props?.touchedField && "font-semibold text-red-600"
					)}
				>
					{required && <span className="text-red-600">{`*`}</span>}
					{required ? " (Required)" : " (Optional)"}
				</span>
			</h1>
			{props?.rfqSubmission && (
				<p className="text-sm text-tertiary-600">Single-date selection</p>
			)}
			<div>
				<DateInput
					icon={true}
					className="!font-regular flex-3 my-4 !border-fg-disabled_subtle"
					required={required}
					applyFormated={"YYYY-MM-DD"}
					{...props}
				/>
			</div>
		</div>
	);
};
