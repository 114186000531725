export default function EmailFooter() {
	return (
		<div style={{ background: "#eaecf0" }}>
			<div
				style={{
					maxWidth: "584px",
					margin: "0 auto",
					fontSize: "10px",
					lineHeight: "16px",
					borderRadius: "0px 0px 16px 16px",
					padding: "48px 40px 48px 40px",
				}}
			>
				<div
					style={{
						display: "flex",
						height: "20px",
						marginBottom: "40px",
						justifyContent: "space-between",
					}}
				>
					<a href="{{ config('app.frontend_url') }}">
						<svg
							width="126"
							height="20"
							viewBox="0 0 126 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0 0.0681152H5.41599C5.65902 0.0681152 5.90204 0.17033 6.07563 0.340687L7.15189 1.3969C7.29076 1.53319 7.39492 1.77169 7.39492 2.01019V10.0511C7.39492 10.2896 7.29076 10.5281 7.11717 10.6984L6.04092 11.7546C5.86733 11.925 5.6243 12.0272 5.38128 12.0272H2.56913V19.9659H0V0.0681152ZM4.82579 8.99486V3.06641C4.82579 2.82791 4.72164 2.58941 4.54805 2.41905C4.37446 2.24869 4.13143 2.14648 3.88841 2.14648H2.56913V9.88072H3.92312C4.16615 9.88072 4.40917 9.7785 4.58276 9.60814C4.72164 9.47186 4.82579 9.23336 4.82579 8.99486Z"
								fill="#101828"
							/>
							<path
								d="M9.26953 0.0681152H14.8244C15.0674 0.0681152 15.3104 0.17033 15.484 0.340687L16.5603 1.3969C16.7339 1.56726 16.838 1.80576 16.838 2.04426V9.57407C16.838 9.81257 16.7339 10.0511 16.5603 10.2214L15.7271 10.971C15.5188 11.1754 15.4146 11.4821 15.484 11.7546L17.0116 19.9659H14.5467L12.9843 11.4821H11.9081V19.9659H9.33897V0.0681152H9.26953ZM14.2342 8.55193V3.06641C14.2342 2.82791 14.13 2.58941 13.9564 2.41905C13.7829 2.24869 13.5398 2.14648 13.2968 2.14648H11.8039V9.40371H13.2968C13.5398 9.40371 13.7829 9.3015 13.9564 9.13114C14.13 8.99486 14.2342 8.79043 14.2342 8.55193Z"
								fill="#101828"
							/>
							<path
								d="M26.0731 18.6371L24.9968 19.6933C24.8232 19.8637 24.5802 19.9659 24.3372 19.9659H20.6571C20.414 19.9659 20.171 19.8637 19.9974 19.6933L18.9212 18.6371C18.7476 18.4667 18.6434 18.2282 18.6434 17.9897V1.97612C18.6434 1.73762 18.7476 1.49912 18.9212 1.32876L19.9974 0.272544C20.2057 0.17033 20.414 0.0681152 20.6571 0.0681152H24.3372C24.5802 0.0681152 24.8232 0.17033 24.9968 0.340687L26.0731 1.3969C26.2467 1.56726 26.3508 1.80576 26.3508 2.04426V18.0579C26.3508 18.2282 26.2467 18.4667 26.0731 18.6371ZM21.525 2.48719C21.3514 2.65755 21.2473 2.89605 21.2473 3.13455V16.9335C21.2473 17.172 21.3514 17.4105 21.525 17.5809C21.6986 17.7512 21.9416 17.8535 22.1847 17.8535H22.9137C23.1568 17.8535 23.3998 17.7512 23.5734 17.5809C23.747 17.4105 23.8511 17.172 23.8511 16.9335V3.10048C23.8511 2.86198 23.747 2.62348 23.5734 2.45312C23.3998 2.28276 23.1568 2.18055 22.9137 2.18055H22.1847C21.9069 2.21462 21.6986 2.31684 21.525 2.48719Z"
								fill="#101828"
							/>
							<path
								d="M42.7726 19.9318H39.2314C38.9883 19.9318 38.7453 19.8296 38.5717 19.6592L37.4955 18.603C37.3219 18.4327 37.2177 18.1942 37.2177 17.9557V0.0681152H39.7868V16.8994C39.7868 17.1379 39.891 17.3764 40.0646 17.5468C40.2382 17.7172 40.4812 17.8194 40.7242 17.8194H41.2797C41.5227 17.8194 41.7658 17.7172 41.9393 17.5468C42.1129 17.3764 42.2171 17.1379 42.2171 16.8994V0.0681152H44.7862V17.9897C44.7862 18.2282 44.6821 18.4667 44.5085 18.6371L43.4322 19.6933C43.2586 19.8637 43.0156 19.9318 42.7726 19.9318Z"
								fill="#101828"
							/>
							<path
								d="M46.661 0.0681152H52.2159C52.4589 0.0681152 52.7019 0.17033 52.8755 0.340687L53.9518 1.3969C54.1254 1.56726 54.2295 1.80576 54.2295 2.04426V9.57407C54.2295 9.81257 54.1254 10.0511 53.9518 10.2214L53.1533 11.0051C52.945 11.2095 52.8408 11.5161 52.9102 11.7887L54.4378 20H51.9729L50.4106 11.5161H49.3343V20H46.7652V0.0681152H46.661ZM51.591 8.55192V3.06641C51.591 2.82791 51.4868 2.58941 51.3132 2.41905C51.1396 2.24869 50.8966 2.14648 50.6536 2.14648H49.1607V9.40371H50.6536C50.8966 9.40371 51.1396 9.3015 51.3132 9.13114C51.5215 8.99485 51.591 8.79042 51.591 8.55192Z"
								fill="#101828"
							/>
							<path
								d="M58.8816 14.8211L58.3608 19.9318H55.7917L58.222 0.0681152H61.9021L64.367 19.9318H61.6938L61.173 14.8211H58.8816ZM60.0273 2.65755L59.0552 12.6746H61.0341L60.0273 2.65755Z"
								fill="#101828"
							/>
							<path
								d="M66.346 1.36285L67.4223 0.30663C67.5959 0.136272 67.8389 0.0340576 68.0819 0.0340576H71.276C71.519 0.0340576 71.762 0.136272 71.9356 0.30663L73.0119 1.36285C73.1855 1.53321 73.2896 1.77171 73.2896 2.01021V6.09879H71.6579C71.1718 6.09879 70.7552 5.68993 70.7552 5.21293V3.06642C70.7552 2.82792 70.6511 2.58942 70.4775 2.41906C70.3039 2.24871 70.0609 2.14649 69.8178 2.14649H69.6095C69.3665 2.14649 69.1235 2.24871 68.9499 2.41906C68.7763 2.58942 68.6721 2.82792 68.6721 3.06642V16.9335C68.6721 17.172 68.7763 17.4105 68.9499 17.5809C69.1235 17.7513 69.3665 17.8535 69.6095 17.8535H69.8178C70.0609 17.8535 70.3039 17.7513 70.4775 17.5809C70.6511 17.4105 70.7552 17.172 70.7552 16.9335V14.787C70.7552 14.31 71.1718 13.9012 71.6579 13.9012H73.2896V18.0238C73.2896 18.2623 73.1855 18.5008 73.0119 18.6712L71.9356 19.7274C71.762 19.8978 71.519 20 71.276 20H68.0472C67.8042 20 67.5612 19.8978 67.3876 19.7274L66.3113 18.6712C66.1377 18.5008 66.0336 18.2623 66.0336 18.0238V2.01021C66.0683 1.77171 66.1724 1.53321 66.346 1.36285Z"
								fill="#101828"
							/>
							<path
								d="M82.4897 18.6371L81.4134 19.6933C81.2398 19.8637 80.9968 19.9659 80.7538 19.9659H77.0737C76.8307 19.9659 76.5876 19.8637 76.4141 19.6933L75.3378 18.6371C75.1642 18.4668 75.0601 18.2283 75.0601 17.9898V1.97615C75.0601 1.73765 75.1642 1.49915 75.3378 1.32879L76.4141 0.272572C76.5876 0.102215 76.8307 0 77.0737 0H80.7538C80.9968 0 81.2398 0.102215 81.4134 0.272572L82.4897 1.32879C82.6633 1.49915 82.7674 1.73765 82.7674 1.97615V17.9898C82.7327 18.2283 82.6633 18.4668 82.4897 18.6371ZM77.9069 2.48722C77.7333 2.65758 77.6292 2.89608 77.6292 3.13458V16.9336C77.6292 17.1721 77.7333 17.4106 77.9069 17.5809C78.0805 17.7513 78.3235 17.8535 78.5666 17.8535H79.2956C79.5387 17.8535 79.7817 17.7513 79.9553 17.5809C80.1289 17.4106 80.233 17.1721 80.233 16.9336V3.10051C80.233 2.86201 80.1289 2.62351 79.9553 2.45315C79.7817 2.28279 79.5387 2.18058 79.2956 2.18058H78.5666C78.3235 2.21465 78.0805 2.31686 77.9069 2.48722Z"
								fill="#101828"
							/>
							<path
								d="M84.6425 0.0681152H87.4199L90.3362 13.833V0.0681152H92.6623V19.9318H89.8849L86.9686 6.37135V19.9318H84.6425V0.0681152Z"
								fill="#101828"
							/>
							<path
								d="M28.5034 1.36283L29.5797 0.306616C29.7533 0.17033 29.9616 0.0681152 30.2046 0.0681152H33.3986C33.6417 0.0681152 33.8847 0.17033 34.0583 0.340687L35.1345 1.3969C35.3081 1.56726 35.4123 1.80576 35.4123 2.04426V6.13284H33.7805C33.2945 6.13284 32.8779 5.72399 32.8779 5.24699V3.06641C32.8779 2.82791 32.7737 2.58941 32.6001 2.41905C32.4265 2.24869 32.1835 2.14648 31.9405 2.14648H31.7322C31.4892 2.14648 31.2461 2.24869 31.0725 2.41905C30.8989 2.58941 30.7948 2.82791 30.7948 3.06641V16.9335C30.7948 17.172 30.8989 17.4105 31.0725 17.5809C31.2461 17.7512 31.4892 17.8534 31.7322 17.8534H31.9405C32.1835 17.8534 32.4265 17.7512 32.6001 17.5809C32.7737 17.4105 32.8779 17.172 32.8779 16.9335V14.787C32.8779 14.31 33.2945 13.9011 33.7805 13.9011H35.4123V18.0238C35.4123 18.2623 35.3081 18.5008 35.1345 18.6712L34.0583 19.7274C33.8847 19.8977 33.6417 20 33.3986 20H30.2046C29.9616 20 29.7185 19.8977 29.5449 19.7274L28.4687 18.6712C28.2951 18.5008 28.1909 18.2623 28.1909 18.0238V2.01019C28.2257 1.77169 28.3298 1.53319 28.5034 1.36283Z"
								fill="#101828"
							/>
							<path
								d="M99.7533 0.988047V3.81598C99.7533 3.9182 99.7186 4.05448 99.6144 4.12263L99.2672 4.46334C99.0936 4.6337 99.0936 4.90627 99.2672 5.07663L99.6144 5.41735C99.6838 5.48549 99.7533 5.62177 99.7533 5.72399V8.75636C99.7533 8.85857 99.7186 8.99486 99.6144 9.063L99.0936 9.57407C99.0242 9.60814 98.92 9.64221 98.8159 9.64221H96.1426V0.0681152H98.8159C98.92 0.0681152 99.0242 0.102187 99.1284 0.204401L99.6491 0.715474C99.7186 0.783617 99.7533 0.885832 99.7533 0.988047ZM98.5381 3.85005V1.49912C98.5381 1.3969 98.5034 1.26062 98.3993 1.19248C98.3298 1.12433 98.2257 1.09026 98.0868 1.09026H97.3925V4.29298H98.1215C98.2257 4.29298 98.3646 4.25891 98.434 4.1567C98.5034 4.08856 98.5381 3.95227 98.5381 3.85005ZM97.3925 5.28106V8.62007H98.1215C98.2257 8.62007 98.3646 8.586 98.434 8.48378C98.5034 8.41564 98.5729 8.27935 98.5729 8.17714V5.72399C98.5729 5.62177 98.5381 5.48549 98.434 5.41735C98.3646 5.3492 98.2257 5.28106 98.1215 5.28106H97.3925Z"
								fill="#101828"
							/>
							<path
								d="M103.19 9.64221H101.489C101.385 9.64221 101.246 9.60814 101.177 9.50593L100.656 9.02893C100.586 8.96078 100.517 8.8245 100.517 8.72228V0.0681152H101.767V8.17714C101.767 8.27935 101.802 8.41564 101.906 8.48378C101.975 8.55193 102.114 8.62007 102.218 8.62007H102.496C102.6 8.62007 102.739 8.586 102.808 8.48378C102.878 8.41564 102.947 8.27935 102.947 8.17714V0.0681152H104.197V8.72228C104.197 8.8245 104.162 8.96078 104.058 9.02893L103.537 9.54C103.433 9.60814 103.294 9.64221 103.19 9.64221Z"
								fill="#101828"
							/>
							<path
								d="M104.996 0.0681152H106.211V9.64221H104.961V0.0681152H104.996Z"
								fill="#101828"
							/>
							<path
								d="M107.079 9.64221V0.0681152H108.294V8.62007H109.925V9.64221H107.079Z"
								fill="#101828"
							/>
							<path
								d="M114.127 0.988047V8.68821C114.127 8.79043 114.092 8.92671 113.988 8.99486L113.502 9.54C113.432 9.60814 113.293 9.67629 113.189 9.67629H110.481V0.0681152H113.189C113.293 0.0681152 113.432 0.102187 113.502 0.204401L114.022 0.715474C114.092 0.783617 114.127 0.885832 114.127 0.988047ZM112.911 8.17714V1.53319C112.911 1.43098 112.877 1.29469 112.773 1.22655C112.703 1.1584 112.599 1.09026 112.46 1.09026H111.696V8.62007H112.46C112.564 8.62007 112.703 8.586 112.773 8.48378C112.877 8.41564 112.911 8.31343 112.911 8.17714Z"
								fill="#101828"
							/>
							<path
								d="M96.1425 10.3578H98.7464C98.8505 10.3578 98.9894 10.3918 99.0589 10.494L99.5796 11.0051C99.6491 11.0733 99.7185 11.2095 99.7185 11.3118V15.1959C99.7185 15.2981 99.6838 15.4344 99.5796 15.5026L99.0589 16.0136C98.9894 16.0818 98.8505 16.1499 98.7464 16.1499H97.3577V20H96.1425V10.3578ZM98.4686 14.6848V11.8228C98.4686 11.7206 98.4339 11.5843 98.3298 11.5162C98.2603 11.448 98.1215 11.3799 98.0173 11.3799H97.3924V15.1278H98.052C98.1562 15.1278 98.2951 15.0937 98.3645 14.9915C98.4339 14.8893 98.4686 14.7871 98.4686 14.6848Z"
								fill="#101828"
							/>
							<path
								d="M103.885 19.3186L103.364 19.8296C103.294 19.8978 103.156 19.9659 103.051 19.9659H101.281C101.177 19.9659 101.038 19.9319 100.968 19.8296L100.448 19.3186C100.378 19.2504 100.309 19.1141 100.309 19.0119V11.3118C100.309 11.2095 100.343 11.0733 100.448 11.0051L100.968 10.494C101.038 10.4259 101.177 10.3578 101.281 10.3578H103.051C103.156 10.3578 103.294 10.3918 103.364 10.494L103.885 11.0051C103.954 11.0733 104.024 11.2095 104.024 11.3118V19.0119C103.989 19.1141 103.954 19.2164 103.885 19.3186ZM101.697 11.5162C101.628 11.5843 101.559 11.7206 101.559 11.8228V18.4668C101.559 18.569 101.593 18.7053 101.697 18.7734C101.767 18.8416 101.906 18.9097 102.01 18.9097H102.357C102.461 18.9097 102.6 18.8756 102.67 18.7734C102.739 18.7053 102.808 18.569 102.808 18.4668V11.8228C102.808 11.7206 102.774 11.5843 102.67 11.5162C102.6 11.448 102.461 11.3799 102.357 11.3799H102.01C101.871 11.3799 101.767 11.448 101.697 11.5162Z"
								fill="#101828"
							/>
							<path
								d="M106.801 11.4821C106.732 11.414 106.593 11.3458 106.489 11.3458H106.419C106.315 11.3458 106.176 11.3799 106.107 11.4821C106.037 11.5503 105.968 11.6865 105.968 11.7888V14.3101L106.211 14.5486H107.426L108.19 15.2981V18.9779C108.19 19.0801 108.155 19.2164 108.051 19.2845L107.565 19.8296C107.495 19.8978 107.357 19.9659 107.252 19.9659H105.655C105.551 19.9659 105.412 19.9319 105.343 19.8296L104.822 19.3186C104.753 19.2504 104.683 19.1141 104.683 19.0119V17.1721H105.933V18.5009C105.933 18.6031 105.968 18.7394 106.072 18.8075C106.141 18.8756 106.28 18.9438 106.384 18.9438H106.523C106.627 18.9438 106.766 18.9097 106.836 18.8075C106.905 18.7394 106.975 18.6031 106.975 18.5009V15.8433L106.732 15.6048H105.516L104.753 14.8552V11.3118C104.753 11.2095 104.787 11.0733 104.892 11.0051L105.412 10.494C105.482 10.4259 105.621 10.3578 105.725 10.3578H107.252C107.357 10.3578 107.495 10.3918 107.565 10.494L108.086 11.0051C108.155 11.0733 108.224 11.2095 108.224 11.3118V13.1516H106.975V11.8228C106.94 11.6865 106.87 11.5843 106.801 11.4821Z"
								fill="#101828"
							/>
							<path
								d="M110.898 11.4821C110.828 11.414 110.689 11.3458 110.585 11.3458H110.516C110.411 11.3458 110.273 11.3799 110.203 11.4821C110.134 11.5503 110.064 11.6865 110.064 11.7888V14.3101L110.307 14.5486H111.522L112.286 15.2981V18.9779C112.286 19.0801 112.252 19.2164 112.147 19.2845L111.661 19.8296C111.592 19.8978 111.453 19.9659 111.349 19.9659H109.752C109.648 19.9659 109.509 19.9319 109.439 19.8296L108.919 19.3186C108.849 19.2504 108.78 19.1141 108.78 19.0119V17.1721H110.03V18.5009C110.03 18.6031 110.064 18.7394 110.168 18.8075C110.238 18.8756 110.377 18.9438 110.481 18.9438H110.62C110.724 18.9438 110.863 18.9097 110.932 18.8075C111.002 18.7394 111.071 18.6031 111.071 18.5009V15.8433L110.828 15.6048H109.613L108.849 14.8552V11.3118C108.849 11.2095 108.884 11.0733 108.988 11.0051L109.509 10.494C109.578 10.4259 109.717 10.3578 109.821 10.3578H111.349C111.453 10.3578 111.592 10.3918 111.661 10.494L112.182 11.0051C112.252 11.0733 112.321 11.2095 112.321 11.3118V13.1516H111.071V11.8228C111.036 11.6865 111.002 11.5843 110.898 11.4821Z"
								fill="#101828"
							/>
							<path
								d="M113.05 10.3578H114.3V19.9319H113.05V10.3578Z"
								fill="#101828"
							/>
							<path
								d="M118.744 11.3118V14.1397C118.744 14.2419 118.709 14.3782 118.605 14.4463L118.258 14.7871C118.084 14.9574 118.084 15.23 118.258 15.4003L118.605 15.7411C118.674 15.8092 118.744 15.9455 118.744 16.0477V19.0801C118.744 19.1823 118.709 19.3186 118.605 19.3867L118.084 19.8296C118.015 19.8978 117.876 19.9659 117.772 19.9659H115.099V10.3578H117.772C117.876 10.3578 118.015 10.3918 118.084 10.494L118.605 11.0051C118.709 11.0733 118.744 11.1755 118.744 11.3118ZM117.494 14.1397V11.7888C117.494 11.6865 117.459 11.5503 117.355 11.4821C117.286 11.414 117.147 11.3458 117.043 11.3458H116.314V14.5486H117.043C117.147 14.5486 117.286 14.5145 117.355 14.4123C117.459 14.3782 117.494 14.2419 117.494 14.1397ZM116.348 15.5707V18.9097H117.077C117.182 18.9097 117.32 18.8756 117.39 18.7734C117.459 18.7053 117.529 18.569 117.529 18.4668V16.0136C117.529 15.9114 117.494 15.7751 117.39 15.707C117.32 15.6388 117.182 15.5707 117.077 15.5707H116.348Z"
								fill="#101828"
							/>
							<path
								d="M119.542 19.9319V10.3578H120.792V18.9097H122.424V19.9319H119.542Z"
								fill="#101828"
							/>
							<path
								d="M122.945 10.3578H126V11.3799H124.195V14.5486H125.792V15.5707H124.195V18.8756H126V19.8978H122.945V10.3578Z"
								fill="#101828"
							/>
						</svg>
					</a>

					<div style={{ display: "flex", gap: "12px" }}>
						<a href="https://www.linkedin.com/company/procuracon">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clip-path="url(#clip0_15897_540)">
									<path
										d="M18.5195 0H1.47656C0.660156 0 0 0.644531 0 1.44141V18.5547C0 19.3516 0.660156 20 1.47656 20H18.5195C19.3359 20 20 19.3516 20 18.5586V1.44141C20 0.644531 19.3359 0 18.5195 0ZM5.93359 17.043H2.96484V7.49609H5.93359V17.043ZM4.44922 6.19531C3.49609 6.19531 2.72656 5.42578 2.72656 4.47656C2.72656 3.52734 3.49609 2.75781 4.44922 2.75781C5.39844 2.75781 6.16797 3.52734 6.16797 4.47656C6.16797 5.42188 5.39844 6.19531 4.44922 6.19531ZM17.043 17.043H14.0781V12.4023C14.0781 11.2969 14.0586 9.87109 12.5352 9.87109C10.9922 9.87109 10.7578 11.0781 10.7578 12.3242V17.043H7.79688V7.49609H10.6406V8.80078H10.6797C11.0742 8.05078 12.043 7.25781 13.4844 7.25781C16.4883 7.25781 17.043 9.23438 17.043 11.8047V17.043Z"
										fill="#101828"
									/>
								</g>
								<defs>
									<clipPath id="clip0_15897_540">
										<rect
											width="20"
											height="20"
											fill="white"
										/>
									</clipPath>
								</defs>
							</svg>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
