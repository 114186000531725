import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getListCorporateDocument } from "actions/RFQ/Supplier/rfqActions";
import {
	SmartFieldQuestionnaireBase,
	useSmartFieldSelection,
	filterSmartFieldOptionForSubmittedRFQ,
} from "./SmartFieldQuestionnaireBase";
import { CorporateDocument } from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/Questionnaire/_components/CorporateDocument";

export const SmartFieldCorporateDocument = ({
	className,
	question,
	required,
	...props
}) => {
	const [search, setSearch] = useState("");
	const [selectedFilter, setSelectedFilter] = useState([]);
	const [filterParams, setFilterParams] = useState({});
	const companyId = useSelector((state) => state?.search?.activeCompany?.id);
	const { value, error, touched, isSelected, selectHandler } =
		useSmartFieldSelection(props);

	// extract questionId from props.name
	const questionId = props.name?.split("-")[1];

	// Get allow_document_categories from props
	useEffect(() => {
		if (!props.fieldValue?.allow_document_categories) return;

		// Extract question ID from props.name (e.g., "question_answer.question-1927" -> "1927")
		const questionId = props.name?.split("-")[1];

		const params = {};
		if (questionId) {
			params[`filters[question_id]`] = questionId;
		}

		props.fieldValue.allow_document_categories.forEach((item, index) => {
			if (item.category_id) {
				params[`filters[category][${index}]`] = item.category_id;
			}
			if (item.subcategory_id) {
				params[`filters[category][${index}]`] = item.subcategory_id;
			}
		});

		setFilterParams(params);
	}, [props.name, props.fieldValue?.allow_document_categories]);

	const queryParams = {
		search,
		...filterParams,
	};

	// Get documents
	const {
		data,
		isLoading,
		error: queryError,
	} = useQuery({
		queryKey: ["corporateDocument", queryParams],
		queryFn: getListCorporateDocument,
		gcTime: 10 * 60 * 1000,
		enabled: Object.keys(filterParams).length > 0,
	});

	const documents = data?.corporate_document || [];
	const filteringList = data?.filters || [];
	const filteredData = filterSmartFieldOptionForSubmittedRFQ(
		documents,
		value,
		props?.disabled
	);

	return (
		<SmartFieldQuestionnaireBase
			className={className}
			question={question}
			required={required}
			description="Please choose the corporate documents that you would like to share."
			searchPlaceholder="Search documents"
			showFilter={false}
			isLoading={isLoading}
			filteringList={filteringList}
			selectedFilter={selectedFilter}
			setSelectedFilter={setSelectedFilter}
			setSearch={setSearch}
			error={error}
			touched={touched}
			{...props}
		>
			{queryError ? (
				<div className="text-red-500">
					Error loading documents: {queryError.message || "Unknown error"}
				</div>
			) : (
				<CorporateDocument
					key={`corporate-doc-${questionId}`}
					values={filteredData.map((item) => ({
						...item,
						key: item.id || item.uuid,
					}))}
					selectHandler={selectHandler}
					selected={isSelected}
					isLoading={isLoading}
					companyId={companyId}
					isPreview={props?.disabled}
					linkStoragePath
					questionId={questionId}
					setFieldValue={props.setFieldValue}
				/>
			)}
		</SmartFieldQuestionnaireBase>
	);
};
