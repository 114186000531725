import { Card, Flex } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { SkeletonBase } from "components/Skeleton";
import { basePathAuth, cn, filenameSpliter } from "utils/helpers";
import { formatBytes } from "utils/formatBytes";

export const CorporateDocument = ({
	values,
	isPreview,
	selected,
	selectHandler,
	isLoading,
	companyId,
	linkStoragePath = false,
	questionId,
	formik,
	...props
}) => {
	const DocumentCard = ({
		file,
		isPreview,
		selected,
		selectHandler,
		linkStoragePath = false,
	}) => {
		const linkTo = linkStoragePath
			? file?.storage_path
			: `${basePathAuth(companyId)}company-profile/documents`;

		return (
			<Card
				className={cn(
					`border-gray-outline hover:text-purple group cursor-pointer border px-4 py-3 hover:border-utility-brand-100 hover:bg-utility-brand-100`
				)}
				radius="sm"
				onClick={() => (selectHandler ? selectHandler(file.id) : null)}
			>
				<Flex
					className="relative items-center justify-between gap-2"
					data-testid={`select-document-${file.id}`}
				>
					<Flex className="relative items-center justify-between gap-4">
						<Icon
							icon={file.ext}
							size="md"
						/>
						<div className="relative">
							<p className="line-clamp-1 max-w-[240px] text-sm font-medium">
								{file.name}
							</p>
							<p className="line-clamp-1 max-w-[240px] text-sm">
								{file.categoryName || file.size}
							</p>
						</div>
					</Flex>
					<Flex className="gap-2">
						{!isPreview && (
							<div
								className={`${selected ? "flex" : "hidden"} cursor-pointer group-hover:flex`}
							>
								<Icon icon="check" />
							</div>
						)}
						{file?.storage_path && isPreview && (
							<a
								href={file?.storage_path || ""}
								target="_blank"
								rel="noreferrer"
								download={file.name ? "download" : null}
								className={cn(
									"hidden rounded-full bg-gray-200 p-2 hover:bg-red-200 group-hover:flex",
									isPreview && "hover:bg-utility-brand-200"
								)}
							>
								<Icon
									icon={isPreview ? "download" : "trash"}
									style={{
										width: "18px",
										height: "18px",
										cursor: "pointer",
										color: "black",
									}}
								/>
							</a>
						)}
						{!isPreview && (
							<a
								href={linkTo}
								target="_blank"
								rel="noreferrer"
								className={cn(
									"-mr-8 rounded-full bg-gray-200 p-2 opacity-0 transition-all hover:bg-utility-brand-200 group-hover:mr-0 group-hover:flex group-hover:opacity-100",
									isPreview && "hover:bg-utility-brand-200"
								)}
							>
								<Icon
									icon={"arrow-right"}
									style={{
										width: "18px",
										height: "18px",
										cursor: "pointer",
										color: "black",
									}}
								/>
							</a>
						)}
					</Flex>
				</Flex>
			</Card>
		);
	};

	const DocumentCardSkeleton = () => {
		return (
			<div className="flex h-16 w-full animate-pulse items-center gap-3 rounded-lg bg-gray-100 px-6">
				<SkeletonBase
					width="36px"
					height="36px"
				/>
				<div className="space-y-2">
					<SkeletonBase
						height="16px"
						width="240px"
					/>
					<SkeletonBase
						height="16px"
						width="180px"
					/>
				</div>
			</div>
		);
	};

	const documents = values.reduce((result, document) => {
		// Handle when it's newly uploaded file
		if (isPreview && document?.fileName) {
			const { filename, ext } = filenameSpliter(document?.fileName);

			const file = {
				name: filename || " - ",
				categoryName:
					document?.category?.name || formatBytes(document.fileSize) || " -",
				ext: ext,
				checked: false,
				storage_path: document?.filePath || null,
				id: document?.id || `temp-${filename}`,
			};

			if (!result["Uploaded"]) {
				result["Uploaded"] = [];
			}
			result["Uploaded"].push(file);
			return result;
		}

		// Original case handling (selected from the list)
		const { filename, ext } = filenameSpliter(document?.file_name);
		const file = {
			name: filename || " - ",
			categoryName: document?.category?.name || " - ",
			ext: ext,
			checked: false,
			storage_path: document?.storage_path || null,
			id: document?.id,
		};
		// Check if the category key exists, if not initialize it as an array
		if (!result[document?.category?.name]) {
			result[document?.category?.name] = [];
		}
		// Push the name into the corresponding category array
		result[document?.category?.name].push(file);
		return result;
	}, {});

	return (
		<div className="w-full">
			{isLoading &&
				[...Array(3)].map((_, index) => <DocumentCardSkeleton key={index} />)}

			{Array.isArray(values) &&
				values.length > 0 &&
				!isLoading &&
				Object.keys(documents)?.map((category) => {
					return (
						<div
							className="w-full"
							key={`category-${category}-${questionId}`}
						>
							<h2 className="text-lg font-bold">{category}</h2>
							<div
								className={cn(
									"mt-4 grid w-full grid-cols-[repeat(auto-fit,minmax(410px,410px))] gap-x-3 gap-y-3"
								)}
							>
								{documents[category]?.map((document, documentIndex) => (
									<DocumentCard
										key={`document-${questionId}-${documentIndex}`}
										file={document}
										isPreview={isPreview}
										selectHandler={selectHandler}
										selected={selected ? selected(document?.id) : null}
										linkStoragePath={linkStoragePath}
									/>
								))}
							</div>
						</div>
					);
				})}
		</div>
	);
};
