import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import SupplierListCard from "components/RFQ/SupplierListCard/SupplierListCard";
import React from "react";
import tw, { styled } from "twin.macro";
import { Flex } from "@chakra-ui/react";
import { Link } from "@reach/router";
import { Button } from "components/RFQ/Button";
import { Icon } from "assets/icons/Icon";

const GridViewStyled = styled.div(() => [
	tw`py-8 grid gap-x-6 gap-y-8 grid-cols-2 min-w-[592px] xlg:grid-cols-3 xxl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6`,
]);

const SkeletonCustom = styled.div(() => [
	tw`animate-pulse bg-gray-300 w-full h-6`,
]);

const CardSupplierSkeleton = () => {
	return (
		<SkeletonCustom className="relative flex !h-[320px] flex-col gap-2 rounded-xl !bg-gray-200 p-6">
			<SkeletonCustom className="!w-48" />
			<SkeletonCustom className="!w-32" />
			<SkeletonCustom className="!h-12" />
			<SkeletonCustom className="mt-20" />
			<SkeletonCustom />
			<SkeletonCustom />
		</SkeletonCustom>
	);
};

const GridViewSupplierLists = ({
	data,
	isLoading,
	archived,
	currPage,
	currentTab,
}) => {
	return (
		<GridViewStyled>
			{isLoading && currPage === 1 && !data ? (
				[...Array(6)].map(() => <CardSupplierSkeleton />)
			) : data?.length > 0 ? (
				<>
					{data.map((supplier, index) => (
						<SupplierListCard
							key={index}
							title={supplier.name}
							description={supplier.description}
							id={supplier.id}
							code={supplier.code}
							endDate={supplier.endDate}
							startDate={supplier.startDate}
							suppliers={supplier.suppliers}
							archived={archived}
							currentTab={currentTab}
							sortTestId={`supplier-list-card-${index}-${supplier.id}`}
						/>
					))}
					{isLoading &&
						currPage >= 1 &&
						data &&
						[...Array(6)].map(() => <CardSupplierSkeleton />)}
				</>
			) : (
				<div className="absolute left-2/4 -translate-x-2/4">
					<NotFoundDatas
						text={`No Supplier Lists found`}
						description={"To get started, create a Supplier List"}
					>
						<Link to={`new-supplier-list`}>
							<Button style={{ maxHeight: "48px" }}>
								<Flex className="items-center gap-[6px]">
									<div className="flex h-5 w-5 items-center justify-center">
										<Icon
											icon="plus"
											style={{
												width: "12px",
												height: "12px",
												color: "#fff",
												fontWeight: "bold",
											}}
										/>
									</div>
									<div className="font-roboto text-base font-semibold capitalize">
										Create Supplier List
									</div>
								</Flex>
							</Button>
						</Link>
					</NotFoundDatas>
				</div>
			)}
		</GridViewStyled>
	);
};

export default GridViewSupplierLists;
