import React from "react";
import { Spinner } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { Button } from "components/Button";

export const LoadingView = ({ entityType = "data" }) => (
	<div className="flex min-h-screen w-full items-center justify-center gap-4">
		<Spinner />
		<Text>Loading {entityType} data...</Text>
	</div>
);

export const ErrorView = ({ error, entityType = "data", onNavigate }) => (
	<div className="flex min-h-screen w-full flex-col items-center justify-center gap-4">
		<Text className="text-lg text-red-500">
			Error loading {entityType} data
		</Text>
		<Text>{error?.message || "Unknown error"}</Text>
		<Button onClick={onNavigate}>Return to Returnable Schedules</Button>
	</div>
);

export const NoDataView = ({ entityType = "data", onNavigate }) => (
	<div className="flex min-h-screen w-full flex-col items-center justify-center gap-4">
		<Text className="text-lg text-red-500">No {entityType} data found</Text>
		<Button onClick={onNavigate}>Return to Returnable Schedules</Button>
	</div>
);
