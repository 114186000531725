import React from "react";
import { Input } from "components/RFQ/Input";
import { Button } from "components/RFQ/Button";
import { Checkbox } from "components/RFQ/Checkbox";
import { saveQuestionnareTemplate } from "actions/RFQ/rfqsAction";
import { useDispatch } from "react-redux";
import { addTemplate } from "actions/RFQ/templateActions";
import {
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Spinner,
} from "@chakra-ui/react";
import { isNull } from "utils/validation";
import { CloseButton } from "components/RFQ/CloseButton";
import EmailPreview from "pages/EmailPreview/EmailPreview";
import moment from "moment";

const SubmitRFQModal = ({
	isOpen,
	setIsOpen,
	rfqId,
	isSaving,
	isSubmitting,
	handleSubmit,
	rfqDetail,
	companyName,
	props,
}) => {
	const [checked, setChecked] = React.useState(false);
	const [templateName, setTemplateName] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);
	const [additionalMessage, setAdditionalMessage] = React.useState("");
	const isEmpty = templateName === "";
	const dispatch = useDispatch();

	const onClose = () => {
		setIsOpen(false);
	};

	const onClickSendHandler = async () => {
		if (isNull(additionalMessage)) {
			return;
		}

		if (checked && !isEmpty) {
			setIsLoading(true);
			if (!isEmpty) {
				const saved = await saveQuestionnareTemplate(rfqId, templateName);
				if (saved) {
					dispatch(addTemplate(saved));
					handleSubmit("submit", additionalMessage);
				}
				setTemplateName("");
			}
			setIsLoading(false);
		} else {
			handleSubmit("submit", additionalMessage);
		}
	};

	const FooterComponent = () => {
		return (
			<div className="flex w-full justify-between gap-2">
				<Button
					onClick={onClose}
					btntype="base"
					className="!w-full"
				>
					Cancel
				</Button>
				<Button
					variant="primary"
					className="flex !w-full gap-3"
					disabled={
						(checked && isEmpty) || isLoading || isSubmitting || isSaving
					}
					onClick={onClickSendHandler}
					data-testid="send-rfq"
				>
					{isLoading ? "Saving template.." : isSaving ? "Sending RFx" : "Send"}
					{(isLoading || isSubmitting || isSaving) && (
						<Spinner
							width={4}
							height={4}
						/>
					)}
				</Button>
			</div>
		);
	};

	return (
		<Drawer
			placement={"right"}
			isOpen={isOpen}
			onClose={onClose}
			size="xl"
			{...props}
			className="bg-white p-11"
		>
			<DrawerOverlay onClick={onClose} />
			<DrawerContent maxWidth={"1280px"}>
				<DrawerHeader>
					<Flex
						justifyContent="space-between"
						align={"center"}
					>
						<div>
							<p className="text-lg font-semibold text-primary-900">
								RFx Invitation Email Preview
							</p>
						</div>
						<CloseButton onClose={onClose} />
					</Flex>
				</DrawerHeader>
				<DrawerBody>
					<div className="grid grid-cols-3">
						<div className="relative">
							<div className="fixed">
								<div className="py-4">
									<Input
										label="Additional Message"
										name="additional_message"
										type="textArea"
										maxLength={500}
										placeholder="Enter additional message you want to send on mail"
										required
										error={
											isNull(additionalMessage) &&
											"Additional message is required"
										}
										onChange={(e) => setAdditionalMessage(e.target.value)}
									/>
								</div>
								<div className="mb-8 space-y-6 rounded-xl bg-[#EEF5FFB2] p-6">
									<Checkbox
										label="I’d like to save this RFx as a template for future use."
										className="text-sm"
										onChange={(e) => setChecked(e.target.checked)}
										checked={checked}
										testId="save-template"
									/>
									{checked && (
										<Input
											label="Template name"
											name="template_name"
											type="text"
											placeholder="Enter Template name"
											onChange={(e) => {
												setTemplateName(e.target.value);
											}}
										/>
									)}
								</div>
								<FooterComponent />
							</div>
						</div>
						<div className="col-span-2">
							<EmailPreview
								companyName={companyName}
								rfqTitle={rfqDetail?.name}
								projectTitle={rfqDetail?.project?.name}
								additionalMessage={additionalMessage}
								time={moment(rfqDetail?.submissionDeadline).format("HH:mm A")}
								date={moment(rfqDetail?.submissionDeadline).format(
									"yyyy/MM/DD"
								)}
								timeZone={rfqDetail?.submissionTimezone}
								isRFQInvitation
							/>
						</div>
					</div>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default SubmitRFQModal;
