import React, { useEffect, useState } from "react";
import {
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
} from "@chakra-ui/react";
import tw, { styled, css } from "twin.macro";
import { TabHeader } from "components/TabHeader";
import { CloseButton } from "components/RFQ/CloseButton";
import { Button } from "components/RFQ/Button";
import apiV2 from "api-v2";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addSupplierList } from "actions/searchActions";
import { navigate } from "@reach/router";
import Select, { components } from "react-select";
import LabelForm from "components/RFQ/LabelForm/LabelForm";
import { Badge } from "components/RFQ/Badge";
import { Icon } from "assets/icons/Icon";
import { showToast } from "utils/helpers";

const DrawerBodyStyled = styled(DrawerBody)(() => [
	tw`p-6 max-h-[calc(100vh - 100px)]`,
]);

const DrawerHeaderStyled = styled(DrawerHeader)(() => [
	tw`text-black py-6 px-0`,
	css`
		.close-text {
			font-weight: 400;
			font-size: 14px;
			cursor: pointer;
		}

		.header-container {
			display: flex;
			justify-content: space-between;
			justify-items: center;
			align-items: center;
		}

		.clear-container {
			display: flex;
			gap: 24px;
			justify-items: center;
			place-items: center;
		}
	`,
]);

const AddSupplierDrawer = ({
	onClose,
	isOpen,
	listCompanies,
	activeSupplierList,
	isAdmin,
	...props
}) => {
	const [availableList, setAvailableList] = useState([]);
	const companyId = useSelector((state) => state.auth.user.company_id);
	const [listSupplierId, setListSupplierId] = useState(activeSupplierList);
	const dispatch = useDispatch();

	const getSupplierList = async () => {
		let res = await apiV2.get(`supplier/list-of-supplier-list`);
		if (res.status === 200) {
			let resultData = [];
			let tempData = res?.data?.data;
			for (let a = 0; a < tempData.length; a++) {
				resultData.push({
					label: tempData[a]?.name,
					value: tempData[a]?.id,
					total: tempData[a]?.total,
					isPanel: tempData[a]?.is_company_approved_list,
				});
			}
			setAvailableList(
				isAdmin ? resultData : resultData.filter((item) => !item.isPanel)
			);
		}
	};

	const CustomOption = (props) => {
		// Format the option text with HTML
		const isChecked = listSupplierId === props.data.value;
		return (
			<components.Option {...props}>
				<div className="flex justify-between">
					<div>
						<b>{props.data.label}</b> <span>{props.data.total}</span>
					</div>
					<div className="flex items-center gap-2">
						<Badge
							type="dot"
							className="!border-0"
						>
							{props.data?.isPanel ? "Panel" : "Suppliers"}
						</Badge>
						<div>
							{isChecked && (
								<Icon
									icon="check-only"
									className="text-royal-blue"
								/>
							)}
						</div>
					</div>
				</div>
			</components.Option>
		);
	};

	useEffect(() => {
		getSupplierList();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		setListSupplierId(parseInt(activeSupplierList));
	}, [activeSupplierList]);

	const submitHandler = async () => {
		let newMember = [];
		for (let a = 0; a < listCompanies.length; a++) {
			newMember.push(listCompanies[a].value);
		}
		try {
			let payload = {
				supplier_id: newMember,
			};
			let res = await apiV2.post(
				`supplier/${listSupplierId}/attach-supplier`,
				payload
			);
			if (res.status === 200) {
				showToast(res?.data?.message, "Success", true);
				dispatch(
					addSupplierList({
						id: newMember,
					})
				);
				onClose();
				let listId = res?.data?.data?.id;
				let listName = res?.data?.data?.name;
				setTimeout(() => {
					navigate(`/account/${companyId}/supplier-list/${listId}/${listName}`);
				}, 100);
			}
		} catch (err) {
			let msg = err?.response?.data?.message;
			showToast(
				msg !== undefined ? msg : "Failed to add supplier to list",
				"Error"
			);
		}
	};

	const changeOption = (value) => {
		setListSupplierId(value?.value);
	};

	const customStyles = {
		control: (provided) => ({
			...provided,
			with: "100%",
			height: "56px",
			minHeight: "56px",
			borderRadius: "6px",
			...(props?.isError
				? tw`focus-within:ring-4 focus-within:!border-utility-error-300 !ring-ring-red-primary`
				: tw`focus-within:ring-4 focus-within:!border-utility-brand-300 !ring-ring-blue-primary`),
		}),
		option: (provided, state) => ({
			...provided,
			// Apply custom styles for hover
			backgroundColor: state.isSelected
				? "#F9FAFB" // Change background for selected option
				: state.isFocused
					? "#F9FAFB" // Change background for hovered option
					: "#fff", // Default background color
			cursor: "pointer", // Change cursor to pointer on hover
			color: "#101828", // Text color
		}),
	};

	return (
		<div>
			<Drawer
				placement={"right"}
				isOpen={isOpen}
				onClose={onClose}
				size="xl"
				{...props}
			>
				<DrawerOverlay onClick={onClose} />
				<DrawerContent>
					<DrawerHeaderStyled borderBottomWidth="1px">
						<div className="mx-6">
							<TabHeader
								noDivider={true}
								heading={"Add to Existing Supplier List"}
								description=""
							>
								<CloseButton onClose={onClose} />
							</TabHeader>
						</div>
					</DrawerHeaderStyled>
					<DrawerBodyStyled>
						<div className="container-content relative h-full min-h-[60vh]">
							<LabelForm label="Supplier List" />
							<Select
								label="Supplier List"
								options={availableList}
								components={{ Option: CustomOption }}
								styles={customStyles}
								onChange={(value) => changeOption(value)}
								value={{
									label: availableList?.find(
										(item) => item.value === listSupplierId
									)?.label,
									value: listSupplierId,
								}}
							/>
							<Button
								style={{ height: "50px", width: "100%" }}
								type="submit"
								className="mb-5 mt-5 disabled:!opacity-50"
								disabled={listSupplierId === ""}
								onClick={submitHandler}
								data-testid="add-to-supplier-list"
							>
								Add to Supplier List
							</Button>
						</div>
					</DrawerBodyStyled>
				</DrawerContent>
			</Drawer>
		</div>
	);
};

export default AddSupplierDrawer;
