import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useField, useFormikContext } from "formik";
import { Error } from "../Error";
import LabelForm from "components/RFQ/LabelForm/LabelForm";
import { createSelectStyles } from "../styles/selectStyles";
import { createMultiSelectCustomTestIdComponents } from "utils/selectHelper";

const animatedComponents = makeAnimated();

export const SectorsMulti = ({
	options,
	name,
	setFieldValue,
	value,
	noLabel,
	maxOptions,
	customOnChange,
	testId,
	...props
}) => {
	//eslint-disable-next-line
	const [field, meta] = useField(name);
	const { setFieldTouched } = useFormikContext();

	const isError = meta.error && meta.touched;
	const customStyles = createSelectStyles({
		isError,
		height: 50,
	});

	const handleOnChange = (options) => {
		// Create array of values for Formik
		const valuesArray = options ? options.map((option) => option.value) : [];
		const sortArray = valuesArray.sort((a, b) => a - b);
		setFieldValue(name, props.sortingValue ? sortArray : valuesArray);
	};

	const customComponents = createMultiSelectCustomTestIdComponents(testId);

	return (
		<div
			className={props.className}
			style={{ position: "relative" }}
		>
			{props.label && !noLabel && (
				<LabelForm
					label={props.label}
					required={props.required}
				/>
			)}

			<Select
				isMulti
				styles={customStyles}
				closeMenuOnSelect={false}
				components={{ ...animatedComponents, ...customComponents }} // Use custom components
				options={value.length === maxOptions ? [] : options}
				noOptionsMessage={() => {
					return value.length === maxOptions
						? `Maximum of ${maxOptions} sectors allowed`
						: "No options available";
				}}
				onChange={customOnChange ? customOnChange : handleOnChange}
				onBlur={() => setFieldTouched(name, true)}
				value={value}
				placeholder={props.placeholder || "Select.."}
			/>

			<Error meta={meta} />
		</div>
	);
};
