import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "@reach/router";
import { IconBoxStyled } from "../IconBox/styled";
import { RFQContext } from "context/RFQContext";
import { CustomModal } from "components/RFQ/Modal/CustomModal";
import { Button } from "components/RFQ/Button";

export const ContextMenuLink = styled((props) => {
	const { setUploadInProgresses, uploadInProgresses } = useContext(RFQContext);
	const [active, setActive] = useState(false);
	const [isWarningModalOpen, setWarningModalOpen] = useState(false);
	const navigate = useNavigate();

	const isActive = ({ isPartiallyCurrent }) => {
		setActive(false);
		let url = new URL(window.location.href);
		const getLastLink = (urls) => urls?.split("/").pop();
		let isExact = getLastLink(url.pathname) === getLastLink(props.to);

		if (props?.exactLink ? isExact : isPartiallyCurrent) {
			if (isExact) {
				setActive(true);
				return {
					style: {
						backgroundColor: "rgba(0, 69, 245, 0.2)",
						color: "#0045F5",
					},
				};
			}
		}
	};

	const handleLinkClick = (e) => {
		if (
			uploadInProgresses?.rfxBidder?.length > 0 ||
			uploadInProgresses?.rfxProcurer?.length > 0
		) {
			e.preventDefault();
			setWarningModalOpen(true);
		}
	};

	const FooterComponent = () => {
		return (
			<div className="flex w-full justify-between gap-2 text-utility-error-50">
				<Button
					onClick={() => {
						setWarningModalOpen(false);
					}}
					btntype="base"
					className="!w-full"
				>
					Cancel
				</Button>
				<Button
					className="flex !w-full gap-3"
					onClick={() => {
						setWarningModalOpen(false);
						if (uploadInProgresses?.rfxBidder?.length) {
							setUploadInProgresses({ ...uploadInProgresses, rfxBidder: [] });
						} else {
							setUploadInProgresses({ ...uploadInProgresses, rfxProcurer: [] });
						}
						navigate(props?.to);
					}}
				>
					Yes
				</Button>
			</div>
		);
	};

	return (
		<>
			{props.newTab ? (
				<a
					href={props.to}
					target="_blank"
					rel="noopener noreferrer"
					className={`${props.className} ${active ? "active-link" : ""}`}
					data-testid={props.testId}
				>
					{props.children}
				</a>
			) : (
				<Link
					getProps={isActive}
					to={props.to}
					className={`${props.className} ${active ? "active-link" : ""}`}
					onClick={(e) => handleLinkClick(e)}
					data-testid={props.testId}
				>
					{props.children}
				</Link>
			)}
			<CustomModal
				isOpen={isWarningModalOpen}
				onClose={() => setWarningModalOpen(false)}
				header={{
					icon: "warning",
					bgColor: "bg-utility-error-50",
					iconColor: "text-utility-error-500",
					title: "Are your sure to leave current page?",
					description:
						"Are you sure you want to leave this page? Some of your changes haven't been saved.",
				}}
				footerComponent={<FooterComponent />}
			/>
		</>
	);
})`
	display: block;
	display: flex;
	color: #667085;
	font-size: ${(props) => props.theme.fontSizes.md};
	font-weight: 500;
	display: flex;
	align-items: center;
	height: ${(props) => (props.height ? props.height : "56px")};
	min-height: ${(props) => (props.height ? props.height : "56px")};
	background-color: transparent;
	&::active {
		background-color: red;
	}
	&::hover {
		background-color: rgba(0, 69, 245, 0.2);
		svg {
			rect {
				stroke: ${(props) => props.theme.colors.royalBlue};
			}
		}
		${(props) =>
			props.admin &&
			`
				background-color: ${props.theme.colors.royalBlue};
			`}
	}

	${IconBoxStyled} {
		margin-right: ${(props) => props.theme.space.xs};
	}
`;
