import { Text } from "@chakra-ui/react";

export const SmartFieldNote = ({ type }) => {
	return (
		<div className="my-8 rounded-md bg-blue-100/70 px-6 py-5">
			<Text className="text-text-secondary-700">
				Please note that any changes made will{" "}
				<span className="font-medium">only affect this RFX</span> and not the
				main {type} in your profile.
			</Text>
		</div>
	);
};
