export default function EmailHeader() {
	return (
		<svg
			width="664"
			height="96"
			viewBox="0 0 664 96"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M257 48.0818H263.448C263.737 48.0818 264.026 48.2044 264.233 48.4089L265.514 49.6763C265.679 49.8399 265.803 50.1261 265.803 50.4123V60.0613C265.803 60.3475 265.679 60.6337 265.473 60.8382L264.192 62.1056C263.985 62.3101 263.696 62.4327 263.406 62.4327H260.058V71.9591H257V48.0818ZM262.745 58.7939V51.6797C262.745 51.3935 262.621 51.1073 262.414 50.9029C262.208 50.6985 261.918 50.5758 261.629 50.5758H260.058V59.8569H261.67C261.96 59.8569 262.249 59.7343 262.456 59.5298C262.621 59.3663 262.745 59.0801 262.745 58.7939Z"
				fill="#003CE9"
			/>
			<path
				d="M268.035 48.0818H274.648C274.937 48.0818 275.227 48.2044 275.433 48.4089L276.715 49.6763C276.921 49.8808 277.045 50.167 277.045 50.4532V59.4889C277.045 59.7751 276.921 60.0613 276.715 60.2658L275.723 61.1653C275.475 61.4106 275.351 61.7785 275.433 62.1056L277.252 71.9591H274.317L272.458 61.7785H271.176V71.9591H268.118V48.0818H268.035ZM273.945 58.2624V51.6797C273.945 51.3935 273.821 51.1073 273.615 50.9029C273.408 50.6985 273.119 50.5758 272.83 50.5758H271.052V59.2845H272.83C273.119 59.2845 273.408 59.1618 273.615 58.9574C273.821 58.7939 273.945 58.5486 273.945 58.2624Z"
				fill="#003CE9"
			/>
			<path
				d="M288.039 70.3646L286.758 71.632C286.551 71.8365 286.262 71.9591 285.973 71.9591H281.592C281.302 71.9591 281.013 71.8365 280.806 71.632L279.525 70.3646C279.319 70.1601 279.195 69.8739 279.195 69.5877V50.3714C279.195 50.0852 279.319 49.799 279.525 49.5946L280.806 48.3271C281.054 48.2044 281.302 48.0818 281.592 48.0818H285.973C286.262 48.0818 286.551 48.2044 286.758 48.4089L288.039 49.6763C288.246 49.8808 288.37 50.167 288.37 50.4532V69.6695C288.37 69.8739 288.246 70.1601 288.039 70.3646ZM282.625 50.9847C282.418 51.1891 282.294 51.4753 282.294 51.7615V68.3203C282.294 68.6065 282.418 68.8927 282.625 69.0971C282.832 69.3015 283.121 69.4242 283.41 69.4242H284.278C284.568 69.4242 284.857 69.3015 285.064 69.0971C285.27 68.8927 285.394 68.6065 285.394 68.3203V51.7206C285.394 51.4344 285.27 51.1482 285.064 50.9438C284.857 50.7394 284.568 50.6167 284.278 50.6167H283.41C283.08 50.6576 282.832 50.7803 282.625 50.9847Z"
				fill="#003CE9"
			/>
			<path
				d="M307.92 71.9182H303.704C303.415 71.9182 303.125 71.7956 302.919 71.5911L301.637 70.3237C301.431 70.1192 301.307 69.833 301.307 69.5468V48.0818H304.365V68.2794C304.365 68.5656 304.489 68.8518 304.696 69.0562C304.903 69.2606 305.192 69.3833 305.481 69.3833H306.143C306.432 69.3833 306.721 69.2606 306.928 69.0562C307.134 68.8518 307.258 68.5656 307.258 68.2794V48.0818H310.317V69.5877C310.317 69.8739 310.193 70.1601 309.986 70.3646L308.705 71.632C308.498 71.8365 308.209 71.9182 307.92 71.9182Z"
				fill="#003CE9"
			/>
			<path
				d="M312.549 48.0818H319.162C319.451 48.0818 319.74 48.2044 319.947 48.4089L321.228 49.6763C321.435 49.8808 321.559 50.167 321.559 50.4532V59.4889C321.559 59.7751 321.435 60.0613 321.228 60.2658L320.278 61.2061C320.03 61.4514 319.906 61.8194 319.988 62.1465L321.807 72H318.872L317.013 61.8194H315.731V72H312.673V48.0818H312.549ZM318.418 58.2624V51.6797C318.418 51.3935 318.294 51.1073 318.087 50.9029C317.881 50.6985 317.591 50.5758 317.302 50.5758H315.525V59.2845H317.302C317.591 59.2845 317.881 59.1618 318.087 58.9574C318.335 58.7939 318.418 58.5486 318.418 58.2624Z"
				fill="#003CE9"
			/>
			<path
				d="M327.097 65.7853L326.477 71.9182H323.419L326.312 48.0818H330.693L333.627 71.9182H330.445L329.825 65.7853H327.097ZM328.461 51.1891L327.304 63.2095H329.66L328.461 51.1891Z"
				fill="#003CE9"
			/>
			<path
				d="M335.983 49.6354L337.265 48.368C337.471 48.1636 337.761 48.0409 338.05 48.0409H341.852C342.142 48.0409 342.431 48.1636 342.638 48.368L343.919 49.6354C344.126 49.8399 344.25 50.1261 344.25 50.4123V55.3186H342.307C341.728 55.3186 341.232 54.8279 341.232 54.2555V51.6797C341.232 51.3935 341.108 51.1073 340.902 50.9029C340.695 50.6985 340.406 50.5758 340.116 50.5758H339.868C339.579 50.5758 339.29 50.6985 339.083 50.9029C338.877 51.1073 338.753 51.3935 338.753 51.6797V68.3203C338.753 68.6065 338.877 68.8927 339.083 69.0971C339.29 69.3015 339.579 69.4242 339.868 69.4242H340.116C340.406 69.4242 340.695 69.3015 340.902 69.0971C341.108 68.8927 341.232 68.6065 341.232 68.3203V65.7445C341.232 65.1721 341.728 64.6814 342.307 64.6814H344.25V69.6286C344.25 69.9148 344.126 70.201 343.919 70.4054L342.638 71.6729C342.431 71.8773 342.142 72 341.852 72H338.009C337.719 72 337.43 71.8773 337.223 71.6729L335.942 70.4054C335.735 70.201 335.611 69.9148 335.611 69.6286V50.4123C335.653 50.1261 335.777 49.8399 335.983 49.6354Z"
				fill="#003CE9"
			/>
			<path
				d="M355.202 70.3646L353.921 71.632C353.714 71.8364 353.425 71.9591 353.135 71.9591H348.754C348.465 71.9591 348.176 71.8364 347.969 71.632L346.688 70.3646C346.481 70.1601 346.357 69.8739 346.357 69.5877V50.3714C346.357 50.0852 346.481 49.799 346.688 49.5945L347.969 48.3271C348.176 48.1227 348.465 48 348.754 48H353.135C353.425 48 353.714 48.1227 353.921 48.3271L355.202 49.5945C355.409 49.799 355.533 50.0852 355.533 50.3714V69.5877C355.491 69.8739 355.409 70.1601 355.202 70.3646ZM349.746 50.9847C349.54 51.1891 349.416 51.4753 349.416 51.7615V68.3203C349.416 68.6065 349.54 68.8927 349.746 69.0971C349.953 69.3015 350.242 69.4242 350.532 69.4242H351.4C351.689 69.4242 351.978 69.3015 352.185 69.0971C352.392 68.8927 352.515 68.6065 352.515 68.3203V51.7206C352.515 51.4344 352.392 51.1482 352.185 50.9438C351.978 50.7394 351.689 50.6167 351.4 50.6167H350.532C350.242 50.6576 349.953 50.7802 349.746 50.9847Z"
				fill="#003CE9"
			/>
			<path
				d="M357.765 48.0818H361.071L364.543 64.5997V48.0818H367.312V71.9182H364.006L360.534 55.6457V71.9182H357.765V48.0818Z"
				fill="#003CE9"
			/>
			<path
				d="M290.933 49.6354L292.214 48.368C292.421 48.2044 292.669 48.0818 292.958 48.0818H296.76C297.05 48.0818 297.339 48.2044 297.546 48.4089L298.827 49.6763C299.033 49.8808 299.157 50.167 299.157 50.4532V55.3595H297.215C296.636 55.3595 296.14 54.8688 296.14 54.2964V51.6797C296.14 51.3935 296.016 51.1073 295.81 50.9029C295.603 50.6985 295.314 50.5758 295.024 50.5758H294.776C294.487 50.5758 294.198 50.6985 293.991 50.9029C293.784 51.1073 293.66 51.3935 293.66 51.6797V68.3203C293.66 68.6065 293.784 68.8927 293.991 69.0971C294.198 69.3015 294.487 69.4242 294.776 69.4242H295.024C295.314 69.4242 295.603 69.3015 295.81 69.0971C296.016 68.8927 296.14 68.6065 296.14 68.3203V65.7445C296.14 65.1721 296.636 64.6814 297.215 64.6814H299.157V69.6286C299.157 69.9148 299.033 70.201 298.827 70.4054L297.546 71.6729C297.339 71.8773 297.05 72 296.76 72H292.958C292.669 72 292.379 71.8773 292.173 71.6729L290.891 70.4054C290.685 70.201 290.561 69.9148 290.561 69.6286V50.4123C290.602 50.1261 290.726 49.8399 290.933 49.6354Z"
				fill="#003CE9"
			/>
			<path
				d="M375.754 49.1857V52.5792C375.754 52.7019 375.713 52.8654 375.589 52.9472L375.175 53.3561C374.969 53.5605 374.969 53.8876 375.175 54.092L375.589 54.5009C375.671 54.5826 375.754 54.7462 375.754 54.8688V58.5077C375.754 58.6303 375.713 58.7939 375.589 58.8756L374.969 59.4889C374.886 59.5298 374.762 59.5707 374.638 59.5707H371.455V48.0818H374.638C374.762 48.0818 374.886 48.1227 375.01 48.2453L375.63 48.8586C375.713 48.9404 375.754 49.063 375.754 49.1857ZM374.307 52.6201V49.799C374.307 49.6763 374.266 49.5128 374.142 49.431C374.059 49.3492 373.935 49.3084 373.77 49.3084H372.943V53.1516H373.811C373.935 53.1516 374.101 53.1107 374.183 52.9881C374.266 52.9063 374.307 52.7428 374.307 52.6201ZM372.943 54.3373V58.3441H373.811C373.935 58.3441 374.101 58.3032 374.183 58.1806C374.266 58.0988 374.349 57.9353 374.349 57.8126V54.8688C374.349 54.7462 374.307 54.5826 374.183 54.5009C374.101 54.4191 373.935 54.3373 373.811 54.3373H372.943Z"
				fill="#003CE9"
			/>
			<path
				d="M379.846 59.5707H377.82C377.696 59.5707 377.531 59.5298 377.448 59.4072L376.828 58.8348C376.746 58.753 376.663 58.5894 376.663 58.4668V48.0818H378.151V57.8126C378.151 57.9353 378.192 58.0988 378.316 58.1806C378.399 58.2624 378.564 58.3441 378.688 58.3441H379.019C379.143 58.3441 379.308 58.3032 379.391 58.1806C379.474 58.0988 379.556 57.9353 379.556 57.8126V48.0818H381.044V58.4668C381.044 58.5894 381.003 58.753 380.879 58.8348L380.259 59.448C380.135 59.5298 379.97 59.5707 379.846 59.5707Z"
				fill="#003CE9"
			/>
			<path
				d="M381.995 48.0818H383.441V59.5707H381.954V48.0818H381.995Z"
				fill="#003CE9"
			/>
			<path
				d="M384.475 59.5707V48.0818H385.921V58.3441H387.864V59.5707H384.475Z"
				fill="#003CE9"
			/>
			<path
				d="M392.865 49.1857V58.4259C392.865 58.5486 392.824 58.7121 392.7 58.7939L392.121 59.448C392.038 59.5298 391.873 59.6116 391.749 59.6116H388.525V48.0818H391.749C391.873 48.0818 392.038 48.1227 392.121 48.2453L392.741 48.8586C392.824 48.9404 392.865 49.063 392.865 49.1857ZM391.418 57.8126V49.8399C391.418 49.7172 391.377 49.5537 391.253 49.4719C391.17 49.3901 391.046 49.3084 390.881 49.3084H389.972V58.3441H390.881C391.005 58.3441 391.17 58.3032 391.253 58.1806C391.377 58.0988 391.418 57.9762 391.418 57.8126Z"
				fill="#003CE9"
			/>
			<path
				d="M371.455 60.4293H374.555C374.679 60.4293 374.845 60.4702 374.927 60.5929L375.547 61.2062C375.63 61.2879 375.712 61.4515 375.712 61.5741V66.2351C375.712 66.3578 375.671 66.5213 375.547 66.6031L374.927 67.2164C374.845 67.2981 374.679 67.3799 374.555 67.3799H372.902V72H371.455V60.4293ZM374.225 65.6218V62.1874C374.225 62.0648 374.183 61.9012 374.059 61.8194C373.977 61.7377 373.811 61.6559 373.687 61.6559H372.943V66.1533H373.729C373.853 66.1533 374.018 66.1125 374.101 65.9898C374.183 65.8671 374.225 65.7445 374.225 65.6218Z"
				fill="#003CE9"
			/>
			<path
				d="M380.672 71.1823L380.052 71.7956C379.97 71.8774 379.804 71.9591 379.68 71.9591H377.572C377.448 71.9591 377.283 71.9182 377.2 71.7956L376.58 71.1823C376.498 71.1005 376.415 70.937 376.415 70.8143V61.5741C376.415 61.4515 376.456 61.2879 376.58 61.2062L377.2 60.5929C377.283 60.5111 377.448 60.4293 377.572 60.4293H379.68C379.804 60.4293 379.97 60.4702 380.052 60.5929L380.672 61.2062C380.755 61.2879 380.838 61.4515 380.838 61.5741V70.8143C380.796 70.937 380.755 71.0596 380.672 71.1823ZM378.068 61.8194C377.986 61.9012 377.903 62.0648 377.903 62.1874V70.1602C377.903 70.2828 377.944 70.4464 378.068 70.5281C378.151 70.6099 378.316 70.6917 378.44 70.6917H378.854C378.978 70.6917 379.143 70.6508 379.226 70.5281C379.308 70.4464 379.391 70.2828 379.391 70.1602V62.1874C379.391 62.0648 379.35 61.9012 379.226 61.8194C379.143 61.7377 378.978 61.6559 378.854 61.6559H378.44C378.275 61.6559 378.151 61.7377 378.068 61.8194Z"
				fill="#003CE9"
			/>
			<path
				d="M384.144 61.7786C384.061 61.6968 383.896 61.615 383.772 61.615H383.689C383.565 61.615 383.4 61.6559 383.317 61.7786C383.235 61.8603 383.152 62.0239 383.152 62.1465V65.1721L383.441 65.4583H384.888L385.797 66.3578V70.7734C385.797 70.8961 385.756 71.0596 385.632 71.1414L385.053 71.7956C384.971 71.8774 384.805 71.9591 384.681 71.9591H382.78C382.656 71.9591 382.491 71.9182 382.408 71.7956L381.788 71.1823C381.706 71.1005 381.623 70.937 381.623 70.8143V68.6065H383.111V70.201C383.111 70.3237 383.152 70.4872 383.276 70.569C383.359 70.6508 383.524 70.7326 383.648 70.7326H383.813C383.937 70.7326 384.103 70.6917 384.185 70.569C384.268 70.4872 384.351 70.3237 384.351 70.201V67.0119L384.061 66.7257H382.615L381.706 65.8263V61.5741C381.706 61.4515 381.747 61.2879 381.871 61.2062L382.491 60.5929C382.574 60.5111 382.739 60.4293 382.863 60.4293H384.681C384.805 60.4293 384.971 60.4702 385.053 60.5929L385.673 61.2062C385.756 61.2879 385.839 61.4515 385.839 61.5741V63.782H384.351V62.1874C384.309 62.0239 384.227 61.9012 384.144 61.7786Z"
				fill="#003CE9"
			/>
			<path
				d="M389.021 61.7786C388.938 61.6968 388.773 61.615 388.649 61.615H388.566C388.442 61.615 388.277 61.6559 388.194 61.7786C388.112 61.8603 388.029 62.0239 388.029 62.1465V65.1721L388.318 65.4583H389.765L390.674 66.3578V70.7734C390.674 70.8961 390.633 71.0596 390.509 71.1414L389.93 71.7956C389.847 71.8774 389.682 71.9591 389.558 71.9591H387.657C387.533 71.9591 387.368 71.9182 387.285 71.7956L386.665 71.1823C386.582 71.1005 386.5 70.937 386.5 70.8143V68.6065H387.988V70.201C387.988 70.3237 388.029 70.4872 388.153 70.569C388.236 70.6508 388.401 70.7326 388.525 70.7326H388.69C388.814 70.7326 388.979 70.6917 389.062 70.569C389.145 70.4872 389.227 70.3237 389.227 70.201V67.0119L388.938 66.7257H387.492L386.582 65.8263V61.5741C386.582 61.4515 386.624 61.2879 386.748 61.2062L387.368 60.5929C387.45 60.5111 387.616 60.4293 387.74 60.4293H389.558C389.682 60.4293 389.847 60.4702 389.93 60.5929L390.55 61.2062C390.633 61.2879 390.715 61.4515 390.715 61.5741V63.782H389.227V62.1874C389.186 62.0239 389.145 61.9012 389.021 61.7786Z"
				fill="#003CE9"
			/>
			<path
				d="M391.584 60.4293H393.072V71.9182H391.584V60.4293Z"
				fill="#003CE9"
			/>
			<path
				d="M398.362 61.5741V64.9676C398.362 65.0903 398.32 65.2539 398.196 65.3356L397.783 65.7445C397.577 65.9489 397.577 66.276 397.783 66.4804L398.196 66.8893C398.279 66.9711 398.362 67.1346 398.362 67.2573V70.8961C398.362 71.0188 398.32 71.1823 398.196 71.2641L397.577 71.7956C397.494 71.8774 397.329 71.9591 397.205 71.9591H394.022V60.4293H397.205C397.329 60.4293 397.494 60.4702 397.577 60.5929L398.196 61.2062C398.32 61.2879 398.362 61.4106 398.362 61.5741ZM396.874 64.9676V62.1465C396.874 62.0239 396.833 61.8603 396.709 61.7786C396.626 61.6968 396.461 61.615 396.337 61.615H395.469V65.4583H396.337C396.461 65.4583 396.626 65.4174 396.709 65.2947C396.833 65.2538 396.874 65.0903 396.874 64.9676ZM395.51 66.6849V70.6917H396.378C396.502 70.6917 396.667 70.6508 396.75 70.5281C396.833 70.4464 396.915 70.2828 396.915 70.1602V67.2164C396.915 67.0937 396.874 66.9302 396.75 66.8484C396.667 66.7666 396.502 66.6849 396.378 66.6849H395.51Z"
				fill="#003CE9"
			/>
			<path
				d="M399.312 71.9182V60.4293H400.8V70.6917H402.743V71.9182H399.312Z"
				fill="#003CE9"
			/>
			<path
				d="M403.363 60.4293H407V61.6559H404.851V65.4583H406.752V66.6849H404.851V70.6508H407V71.8774H403.363V60.4293Z"
				fill="#003CE9"
			/>
		</svg>
	);
}
