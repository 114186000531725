import { useField } from "formik";
import { useState } from "react";
import { cn } from "utils/helpers";
import { Flex } from "@chakra-ui/react";
import FilterButton from "components/FilterButton/FilterButton";
import FilterDrawer from "components/RFQ/CustomFilterDrawer/FilterDrawer";
import SearchBar from "components/RFQ/SearchBar/SearchBar";

// Base component with common functionality
export const SmartFieldQuestionnaireBase = ({
	className,
	question,
	required,
	smartField,
	description,
	searchPlaceholder,
	showFilter = true,
	children,
	isLoading,
	filteringList,
	selectedFilter,
	setSelectedFilter,
	setSearch,
	error,
	touched,
	...props
}) => {
	const [openDrawer, setOpenDrawer] = useState(false);

	return (
		<div
			className={cn(
				`${error && touched && "rounded p-2 shadow-md ring-1 ring-red-500 ring-offset-2"} ${className}`
			)}
			{...props}
		>
			<Flex className="items-center justify-between">
				<div>
					<h1 className="text-lg font-semibold text-secondary-700">
						{question}
						<span>
							{required && <span className="text-red-600">{`*`}</span>}
							{required ? " (Required)" : " (Optional)"}
						</span>
					</h1>
					<p className="text-sm text-tertiary-600">{description}</p>
					{error && touched && <span className="text-red-600">{error}</span>}
				</div>
				<Flex className="items-center gap-2">
					<SearchBar
						setState={setSearch}
						placeholder={searchPlaceholder}
						className="min-w-[360px]"
					/>
					{showFilter && (
						<FilterButton
							onClick={() => setOpenDrawer(true)}
							filters={selectedFilter}
						/>
					)}
				</Flex>
			</Flex>
			<div>
				<Flex className="w-full gap-5 overflow-x-auto py-6 pl-1">
					{children}
					{showFilter && (
						<FilterDrawer
							isOpen={openDrawer}
							onClose={() => setOpenDrawer(false)}
							filterOptions={filteringList}
							filters={selectedFilter}
							setFilters={setSelectedFilter}
						/>
					)}
				</Flex>
			</div>
		</div>
	);
};

// Helper hook for field selection logic
export const useSmartFieldSelection = (props) => {
	// eslint-disable-next-line no-unused-vars
	const [field, meta, helper] = useField(props);
	const { value, error, touched } = meta;
	const { setValue, setTouched } = helper;

	const isSelected = (listValue) =>
		Array.isArray(value) && value.length > 0
			? value?.includes(listValue)
			: false;

	const selectHandler = (listValue) => {
		setTouched(true);
		isSelected(listValue)
			? setValue(value.filter((val) => val !== listValue))
			: setValue([...value, listValue]);
	};

	return {
		value,
		error,
		touched,
		isSelected,
		selectHandler,
	};
};

// Helper function to filter data for preview mode
export const filterSmartFieldOptionForSubmittedRFQ = (
	smartFieldData,
	value,
	isDisabled
) => {
	if (isDisabled) {
		return smartFieldData.filter((data) => value.includes(data.id));
	}
	return smartFieldData;
};
