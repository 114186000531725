import { Spinner } from "@chakra-ui/react";
import { acceptDecliceSubmissionGuest } from "actions/guestflow/guestRfqActions";
import { navigate } from "@reach/router";
import { acceptDecliceSubmission } from "actions/RFQ/Supplier/rfqActions";
import { useState } from "react";
import { STATUS_ACCEPTED, STATUS_DECLINE } from "utils/constants";
import { Input } from "components/RFQ/Input";
import { isNull } from "utils/validation";

const { Button } = require("components/RFQ/Button");
const { CustomModal } = require("components/RFQ/Modal/CustomModal");

export const AcceptDeclineModal = ({
	showDialog,
	onClose,
	rfqId,
	setSubmissionStatus,
	companyId,
	isGuest,
	guestEmail,
}) => {
	const isAccept = showDialog === "accept";
	const dialogProperty = isAccept
		? {
				icon: "blue-circle-check",
				bgColor: "bg-brand-secondary",
				iconColor: "text-utility-brand-700",
				title: "Accept Request",
				description:
					"By accepting this request, you can start your submission. We will also let the Client know you have accepted.",
			}
		: {
				icon: "request-for-quote",
				bgColor: "bg-utility-error-50",
				iconColor: "text-utility-error-500",
				title: "Decline Request",
				description:
					"Are you sure you want to decline this request? This action cannot be undone.",
			};

	const [reason, setReason] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const FooterComponent = () => {
		return (
			<div className="flex w-full justify-between gap-2 text-utility-error-50">
				<Button
					onClick={onClose}
					btntype="base"
					className="!w-full"
					disabled={isLoading}
				>
					{isAccept ? "Cancel" : "Cancel"}
				</Button>
				<Button
					danger={!isAccept}
					className="flex !w-full gap-3"
					onClick={onClickAcceptDeclineHandler}
					disabled={isLoading}
					data-testid="submit-button-modal"
				>
					{isLoading ? <Spinner /> : isAccept ? "Accept" : "Decline"}
				</Button>
			</div>
		);
	};

	const onClickAcceptDeclineHandler = async () => {
		if (!isAccept && isNull(reason)) {
			return;
		}
		setIsLoading(true);
		(isGuest
			? acceptDecliceSubmissionGuest(rfqId, isAccept ? 1 : 0, guestEmail)
			: acceptDecliceSubmission(rfqId, isAccept ? 1 : 0, reason)
		)
			.then((response) => {
				if (response) {
					if (isAccept) {
						setSubmissionStatus(STATUS_ACCEPTED);
					} else {
						setSubmissionStatus(STATUS_DECLINE);
						setTimeout(() => {
							navigate(
								isGuest ? "." : `/account/${companyId}/request-for-quote`
							);
						}, 100);
					}
					setIsLoading(false);
					onClose();
				}
			})
			.catch((error) => {
				console.log(error);
				setIsLoading(false);
			});
	};

	return (
		<CustomModal
			onClose={onClose}
			isOpen={showDialog}
			header={dialogProperty}
			size="md"
			footerComponent={<FooterComponent />}
		>
			<div>
				{!isAccept && (
					<Input
						label="Reason"
						name="reason"
						type="textArea"
						placeholder="Enter reason for decline"
						required
						error={isNull(reason) && "Reason is required"}
						onChange={(e) => {
							setReason(e.target.value);
						}}
					/>
				)}
			</div>
		</CustomModal>
	);
};
