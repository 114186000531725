import React, { useContext, useEffect, useRef, useState } from "react";
import { cn, showToast } from "utils/helpers";

import Dropzone from "react-dropzone";
import { Tooltip } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { FileCard } from "../FileCard";
import { useField } from "formik";
import { FileCardSelect } from "../FileCard/FileCardSelect";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorderList } from "utils/reorderList";
import LabelForm from "../LabelForm/LabelForm";
import { RFQContext } from "context/RFQContext";
import {
	addProgressToUploading,
	removeProgressFromUploading,
} from "utils/rfxLargeFileUploadHelper";

const typeChecker = (types) => {
	const fileTypesObj = {};
	for (const type of types) {
		switch (type) {
			case "jpg":
				fileTypesObj["image/jpeg"] = [".jpg", ".jpeg"];
				break;
			case "png":
				fileTypesObj["image/png"] = [".png"];
				break;
			case "gif":
				fileTypesObj["image/gif"] = [".gif"];
				break;
			case "svg":
				fileTypesObj["image/svg+xml"] = [".svg"];
				break;
			case "pdf":
				fileTypesObj["application/pdf"] = [".pdf"];
				break;
			case "doc":
				fileTypesObj["application/msword"] = [".doc"];
				break;
			case "docx":
				fileTypesObj[
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				] = [".docx"];
				break;
			case "xls":
				fileTypesObj["application/vnd.ms-excel"] = [".xls"];
				break;
			case "xlsx":
				fileTypesObj[
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				] = [".xlsx"];
				break;
			case "ppt":
				fileTypesObj["application/vnd.ms-powerpoint"] = [".ppt"];
				break;
			case "pptx":
				fileTypesObj[
					"application/vnd.openxmlformats-officedocument.presentationml.presentation"
				] = [".pptx"];
				break;
			case "zip":
				fileTypesObj["application/zip"] = [".zip"];
				break;
			default:
				break;
		}
	}
	return fileTypesObj;
};

const regexType = /\.([a-zA-Z0-9]+)(?=\?|$)/;

export const FileUploadSelect = ({
	label,
	multiple = false,
	children,
	className,
	hint,
	description,
	required,
	types = [],
	maxFileSize,
	defaultFileName,
	selectOptions,
	selectPlaceholder,
	selectName,
	subtitle,
	customMessage,
	...props
}) => {
	const [field, meta, helpers] = useField(props);
	const [, /**/ selectMeta] = useField(selectName);
	const [files, setFiles] = useState(meta.value.length > 0 ? meta.value : null);
	const { value } = field;
	const selectedCategory = selectMeta.value;
	const { rfqId, setUploadInProgresses } = useContext(RFQContext);

	const inputRef = useRef(null);
	useEffect(() => {
		if (files) {
			const filesSorted = files
				.filter((file) => !file.isRemove)
				.map((file, i) => ({ ...file, sort_order: i + 1 }));
			const filesRemoved = files.filter((file) => file.isRemove);
			helpers.setValue([...filesSorted, ...filesRemoved]);
		}
		//eslint-disable-next-line
	}, [files]);

	useEffect(() => {
		if (files && files.length === 0 && meta.value.length > 0) {
			setFiles(meta.value);
		}
		//eslint-disable-next-line
	}, [meta]);

	const dropHandler = (acceptedFiles, rejectedFiles) => {
		if (!selectedCategory) {
			showToast("Please select a category", "Error");
			return;
		}
		if (rejectedFiles.length > 0) {
			rejectedFiles.forEach((file) => {
				file?.errors?.length > 0 &&
					file.errors[0].code === "file-invalid-type" &&
					showToast("Invalid file type", "Error");
			});
		}
		if (multiple) {
			acceptedFiles.forEach((file, i) => {
				const documentsObj = {
					id: file?.id || null,
					category_id: selectedCategory || 1,
					sort_order: files ? i + 1 + files.length : 1,
					isRemove: 0,
					document: file,
				};
				if (file.size > maxFileSize * 1024 * 1024) {
					showToast(`Maximum file size is ${maxFileSize}MB.`, "Error");
				} else {
					if (files) {
						setFiles((prev) => [...prev, documentsObj]);
					} else {
						setFiles([documentsObj]);
					}
				}
			});
		} else {
			if (acceptedFiles[0].size > maxFileSize * 1024 * 1024) {
				showToast(`Maximum file size is ${maxFileSize}MB.`, "Error");
			} else {
				setFiles(acceptedFiles);
			}
		}
	};

	const url = (value) => (typeof value === "string" ? value : "");
	const fileExt = (url) =>
		url && typeof url === "string" ? url?.match(regexType) : null;

	const deleteHandler = (index, isRemove) => {
		if (!isRemove || !files[index]?.id) {
			const newFiles = files.filter((file, i) => i !== index);
			setFiles(newFiles);
		} else {
			const newFiles = files.map((file, i) =>
				i === index ? { ...file, isRemove: 1, sort_order: 0 } : file
			);
			setFiles(newFiles);
		}
		if (files.filter((file) => !file.isRemove).length <= 1) {
			helpers.setTouched(true);
			helpers.setError("At least one file is required");
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			if (inputRef.current) {
				inputRef.current.click();
			}
		}
	};

	// replace form file input values with uploaded file values
	const handleFileUploadComplete = (uploadedFile) => {
		setFiles((prev) => {
			return prev?.map((file) => {
				const fileName = file?.document?.name?.trim()?.replace(/\s+/g, "_");

				if (fileName === uploadedFile?.fileName) {
					return {
						category_id: uploadedFile.categoryId,
						document: uploadedFile.fileName,
						file_size: uploadedFile.fileSize,
						id: uploadedFile.id,
						isRemove: 0,
						path: uploadedFile.documentPath,
						sort_order: uploadedFile.sortOrder,
					};
				}

				return file;
			});
		});

		setUploadInProgresses((prev) => removeProgressFromUploading(prev, true));
	};

	return (
		<div className={cn("w-full", className)}>
			{label && (
				<LabelForm
					label={label}
					required={required}
				>
					{hint && (
						<Tooltip
							dataTip={`input-${props.name}`}
							content={hint}
						>
							<Icon
								icon="info-circle"
								className="text-gray-500"
								size="sm"
							/>
						</Tooltip>
					)}
				</LabelForm>
			)}
			{subtitle && (
				<p className="-mt-1 mb-4 text-sm text-tertiary-600">{subtitle}</p>
			)}
			{description && <p className="text-gray-text mb-2">{description}</p>}

			<div className="cursor-pointer">
				<Dropzone
					onDrop={(acceptedFiles, rejectedFiles) =>
						dropHandler(acceptedFiles, rejectedFiles)
					}
					accept={typeChecker(types)}
					multiple={multiple}
				>
					{({
						getRootProps,
						getInputProps,
						isDragAccept,
						isDragReject,
						isDragActive,
					}) => (
						<section>
							<div
								{...getRootProps()}
								className={cn(
									"h-max w-full rounded-2xl border bg-white py-10 [text-align:-webkit-center]",
									isDragAccept &&
										selectedCategory &&
										"border-utility-brand-700",
									(isDragReject || (isDragActive && !selectedCategory)) &&
										"border-utility-error-500",
									meta.error && meta.touched && "border-red-400"
								)}
							>
								<div ref={inputRef}>
									<input
										{...getInputProps()}
										onClick={(e) => e.stopPropagation()}
										data-testid={`${props?.testId}`}
									/>
								</div>
								<Icon
									icon="upload"
									className="icon-download"
								/>
								<p className="text-sm">
									<strong>{customMessage}</strong>
								</p>
								{children}
								<p className="text-sm">
									<button
										className="focusable font-semibold text-royal-blue"
										onKeyDown={handleKeyDown}
									>
										Click to upload
									</button>{" "}
									or drag and drop
								</p>
								<p className="text-xs">
									<span className="uppercase">
										{types.length > 0 && types.join(", ")}
									</span>
									{maxFileSize && ` (Max file size ${maxFileSize}MB)`}
								</p>
							</div>
						</section>
					)}
				</Dropzone>
			</div>
			{typeof value === "string" && fileExt(url(value)) && !multiple ? (
				<>
					{
						<FileCard
							file={{ name: `${defaultFileName}${fileExt[0]}` }}
							setFiles={() => helpers.setValue("")}
						/>
					}
				</>
			) : (
				<DragDropContext
					onDragEnd={(result) => reorderList(files, result, setFiles)}
				>
					<Droppable
						droppableId="questioner-container"
						direction="vertical"
					>
						{(provided) => (
							<div
								id="questioner-container"
								className="my-8"
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{files &&
									files.length > 0 &&
									files.map((file, i) => {
										if (!file.isRemove) {
											return (
												<Draggable
													key={`file${i}`}
													draggableId={`file${i}`}
													index={i}
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className="py-1"
														>
															<FileCardSelect
																uploadProgress={0}
																className="my-1"
																file={file.document ? file.document : file}
																deleteHandler={() =>
																	deleteHandler(
																		i,
																		file?.document ? true : false
																	)
																}
																fileSize={file.file_size || null}
																selectOptions={selectOptions}
																selectValue={Number(file?.category_id)}
																setFiles={setFiles}
																index={i}
																sort_id={file.sort_order}
																draggableProps={provided.dragHandleProps}
																fileExtraData={{
																	category_id: file?.category_id,
																	sort_order: i + 1,
																	rfq_id: rfqId,
																	model: "documentation_document",
																}}
																handleFileUploadComplete={
																	handleFileUploadComplete
																}
																handleFileUploadStart={() => {
																	setUploadInProgresses((prev) =>
																		addProgressToUploading(prev, true)
																	);
																}}
															/>
														</div>
													)}
												</Draggable>
											);
										} else return null;
									})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			)}

			{meta.error && meta.touched && (
				<p className="my-2 -mt-5 font-roboto text-sm !text-utility-error-500">
					{meta.error}
				</p>
			)}
		</div>
	);
};
