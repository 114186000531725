import React from "react";
import { FileCard } from "components/RFQ/FileCard";
import { FileUpload } from "components/RFQ/FileUpload";
import LabelForm from "components/RFQ/LabelForm/LabelForm";

export default function ReturnableSchedulesFileUploadComp({
	templateReturnableScheduleFile,
	companyName,
	disabled,
	rfqId,
}) {
	const fileData = {
		name: "Returnable schedules template.xlsx",
		path: templateReturnableScheduleFile?.file_path,
		description: `${companyName} has submitted a returnable schedules template. Please download it and re-upload below once it has been completed`,
	};

	return (
		<>
			<LabelForm label="Download quotation template" />
			<FileCard
				file={fileData}
				isPreview
			/>

			<FileUpload
				type="file"
				label="Attach returnable schedules response"
				name="returnable_schedule_file"
				className="my-4 w-full"
				types={["pdf", "docx", "doc"]}
				required
				multiple={false}
				maxFileSize={100}
				disabled={disabled}
				fileExtraData={{
					model: "rfq_returnable_schedule_file_response",
					rfq_id: rfqId,
				}}
				testId="returnable-schedules-file-upload"
			/>
		</>
	);
}
