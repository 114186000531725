import { CustomModal } from "components/RFQ/Modal/CustomModal";
import React, { useState } from "react";
import {
	getProjects,
	setProjectArchived,
	setProjectUnArchived,
} from "actions/RFQ/projectsAction";
import { useDispatch } from "react-redux";
import { Button } from "components/RFQ/Button";

export default function ProjectArchiveModal({
	isArchived,
	projectData,
	isModalOpen,
	setIsModalOpen,
	onSuccessHandler,
}) {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const dialogProperty = {
		icon: !isArchived ? "archive" : "blue-circle-check",
		bgColor: "bg-brand-secondary",
		iconColor: "text-utility-brand-700",
		title: `${!isArchived ? "Archive" : "Retrieve"} "${projectData?.name}"?`,
		description: !isArchived ? (
			<>
				Archiving the project will:
				<ul className="mt-2 list-disc space-y-1 pl-5">
					<li>Move it to archived projects</li>
					<li>Stop all team notifications</li>
					<li>Preserve all project data</li>
				</ul>
			</>
		) : (
			`Are you sure you want to Retrieve "${projectData?.name}"?`
		),
	};

	const archivedHandler = () => {
		setIsLoading(true);
		console.log("datatatat", isArchived);
		if (isArchived) {
			dispatch(setProjectUnArchived(projectData?.id)).then((data) => {
				if (data?.status) {
					dispatch(getProjects({ reset: true }));
					setIsLoading(false);
					setIsModalOpen(false);

					onSuccessHandler();
				}
			});
		} else {
			dispatch(setProjectArchived(projectData?.id)).then((data) => {
				if (data?.status) {
					dispatch(getProjects({ reset: true }));
					setIsLoading(false);
					setIsModalOpen(false);
					onSuccessHandler();
				}
			});
		}
	};

	const FooterComponent = () => {
		return (
			<div className="flex w-full justify-between gap-2">
				<Button
					className="flex !w-full gap-3"
					onClick={archivedHandler}
					disabled={isLoading}
					data-testid="confirm-archive-modal-button"
				>
					{isLoading
						? !isArchived
							? "Archiving"
							: "Retrieving"
						: "Archive Project"}
				</Button>
				<Button
					btntype="plain-border"
					onClick={() => setIsModalOpen(false)}
					className="!w-full"
				>
					Cancel
				</Button>
			</div>
		);
	};
	return (
		<CustomModal
			header={dialogProperty}
			isOpen={isModalOpen}
			onClose={() => setIsModalOpen(false)}
			footerComponent={<FooterComponent />}
		/>
	);
}
