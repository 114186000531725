import { useCallback, useContext } from "react";
import { SkeletonBase } from "components/Skeleton";
import { RFQContext } from "context/RFQContext";
import { Icon } from "assets/icons/Icon";
import { Button } from "components/RFQ/Button";
import { basePathAuth } from "utils/helpers";
import { navigate } from "@reach/router";
import { SubmissionSmartFieldPreviewContext } from "context/RFQSubmittedResponseContext";

export const ProjectExperienceCardSkeleton = ({ rfqSubmission }) => {
	return (
		<div className="my-3 min-w-[410px] animate-pulse overflow-hidden rounded-md bg-gray-100 pb-6">
			<SkeletonBase
				width="100%"
				height="145px"
			/>
			<div className="space-y-6 px-6 py-2">
				<div className="mb-3 mt-10 flex items-center justify-start gap-3">
					<SkeletonBase width="160px" />
				</div>
				<div className="space-y-4 pb-2 pt-3">
					<SkeletonBase width="100%" />
					<SkeletonBase width="100%" />
					<SkeletonBase width="100%" />
					<SkeletonBase width="100%" />
				</div>
				{rfqSubmission && (
					<SkeletonBase
						width="100%"
						height="46px"
					/>
				)}
			</div>
		</div>
	);
};

export const ProjectExperienceCard = ({
	values,
	rfqSubmission,
	selected,
	selectHandler,
	companyId,
	isPreview,
	supplierSmartField,
}) => {
	const { rfqId } = useContext(RFQContext);
	const submissionSmartFieldContext = useContext(
		SubmissionSmartFieldPreviewContext
	);

	function formatCurrency(value) {
		if (value >= 1e6) {
			return (value / 1e6).toFixed(1) + "M";
		} else if (value >= 1e3) {
			return (value / 1e3).toFixed(1) + "K";
		} else if (value > 0) {
			return value.toFixed(1);
		} else {
			return " - ";
		}
	}

	const handleProjectExperienceOpen = () => {
		if (supplierSmartField && rfqId) {
			navigate(
				`${basePathAuth(companyId)}request-for-quote/${rfqId}/rfx-project-editor/${values.id}`
			);
		} else {
			navigate(
				`${basePathAuth(companyId)}company-profile/projects/edit-project/${values.id}`
			);
		}
	};

	const handleViewClick = useCallback(() => {
		submissionSmartFieldContext.setModalDetail({
			data: values,
			type: "project",
		});
		submissionSmartFieldContext.setIsModalOpen(true);
	}, [submissionSmartFieldContext, values]);

	return (
		<div className="my-3 h-max min-w-[410px] overflow-hidden rounded-md bg-white pb-3 shadow-md">
			<div
				className="relative flex items-center justify-between"
				style={{ height: "145px" }}
			>
				<div className="absolute left-0 top-0 h-[6px] w-full bg-royal-blue2"></div>
				<div
					className="bg-cover"
					style={{
						width: "100%",
						height: "145px",
						backgroundImage: `url(${values?.images?.length > 0 && values?.images[0]})`,
					}}
				></div>
			</div>
			<div className="space-y-6 pb-3 pl-5 pr-5 pt-3">
				<div>
					<h3 className="mt-8 line-clamp-1 overflow-hidden font-roboto text-[20px] font-semibold capitalize text-primary-900">
						{values?.name || " - "}
					</h3>
				</div>
				<div>
					<div
						className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Construction Value
						</div>
						<div className="font-roboto text-[14px] font-semibold text-primary-900">
							${formatCurrency(values?.value)}
						</div>
					</div>
					<div
						className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Completion
						</div>
						<div className="font-roboto text-[14px] font-semibold text-primary-900">
							{values?.year_completed || " - "}
						</div>
					</div>
					<div
						className="flex items-center justify-between space-x-0 border-b border-solid border-[#EAECF0]"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Client Name
						</div>
						<div className="font-roboto text-[14px] font-semibold text-primary-900">
							{values?.client_name || " - "}
						</div>
					</div>
					<div
						className="flex items-center justify-between space-x-0"
						style={{ height: "40px" }}
					>
						<div className="font-roboto text-[14px] font-normal text-primary-500">
							Procurement Model
						</div>
						<div className="font-roboto text-[14px] font-semibold text-primary-900">
							{values?.procurement_methods[0]?.name || " - "}
						</div>
					</div>
				</div>
				<div className="flex gap-3">
					{rfqSubmission && !isPreview && (
						<>
							<Button
								type="button"
								btntype={selected ? "primary" : "secondary"}
								className="!w-full items-center gap-1.5"
								onClick={() => selectHandler(values?.id)}
								data-testid={`select-project-${values?.id}`}
							>
								{selected ? "Selected" : "Select"}
								{selected && (
									<Icon
										icon="check-only"
										width={20}
										height={20}
									/>
								)}
							</Button>
							<Button
								btntype="secondary"
								className={supplierSmartField ? "w-[42px]" : "!w-full"}
								onClick={handleProjectExperienceOpen}
							>
								{supplierSmartField ? (
									<Icon icon="pencil-02" />
								) : (
									"Open Project"
								)}
							</Button>
						</>
					)}

					{isPreview && (
						<Button
							btntype="secondary"
							className="!w-full"
							type="button"
							onClick={handleViewClick}
						>
							View Project
						</Button>
					)}
				</div>
			</div>
			{rfqSubmission && !isPreview && (
				<div className="absolute right-3 top-3 z-10">
					<div
						className={`flex h-6 w-6 cursor-pointer items-center justify-center rounded-md border ${
							selected
								? "border-royal-blue2 bg-royal-blue2"
								: "border-gray-300 bg-white"
						}`}
						onClick={(e) => {
							e.stopPropagation();
							selectHandler(values?.id);
						}}
					>
						{selected && (
							<svg
								width="14"
								height="14"
								viewBox="0 0 14 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.6667 3.5L5.25 9.91667L2.33333 7"
									stroke="white"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
