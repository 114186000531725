import { EmailComponentType } from "./EmailComponents.type";
import { RFQInvitationEmailContent } from "./RFQInvitationEmailContent";
import { SubmissionDeadlineExtensionEmailContent } from "./SubmissionDeadlineExtensionEmailContent";

const EmailComponent: React.FC<EmailComponentType> = ({
	companyName,
	rfqTitle,
	projectTitle,
	additionalMessage,
	date,
	time,
	timeZone,
	isRFQInvitation,
}) => {
	return isRFQInvitation ? (
		<RFQInvitationEmailContent
			companyName={companyName}
			rfqTitle={rfqTitle}
			projectTitle={projectTitle}
			additionalMessage={additionalMessage}
			date={date}
			time={time}
			timeZone={timeZone}
		/>
	) : (
		<SubmissionDeadlineExtensionEmailContent
			date={date}
			time={time}
			timeZone={timeZone}
			rfqTitle={rfqTitle}
			additionalMessage={additionalMessage}
			projectTitle={projectTitle}
		/>
	);
};
export default EmailComponent;
