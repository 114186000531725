import React from "react";

import { TableBody } from "./styled";
import { DataRow } from "../../components/CompanyResultCardTable/DataRow";
import { DataHeadings } from "../SearchTemplate/styled";

export const SearchResultsTable = ({
	noDataTable,
	compareTable,
	searchCriteria,
	directCompare,
	...props
}) => {
	return (
		<TableBody paddingTop="0px">
			{!noDataTable && (
				<DataHeadings compareTable={compareTable}>
					<DataRow
						noBorder={compareTable}
						contentType="suburb-city"
					>
						<h3>Suburb / City</h3>
					</DataRow>
					<DataRow
						contentType="lga"
						noBorder={compareTable}
					>
						<h3>Local Government Area</h3>
					</DataRow>
					<DataRow
						noBorder={compareTable}
						data-testid="company-size-result"
						contentType="company-size"
					>
						<h3>Company Size</h3>
					</DataRow>
					{searchCriteria.profile_type !== 1 &&
						searchCriteria.profile_type !== 4 &&
						searchCriteria.profile_type !== 3 && (
							<DataRow
								noBorder={compareTable}
								contentType="anual-turnover"
							>
								<h3>Annual Turnover</h3>
							</DataRow>
						)}
					{searchCriteria.profile_type === 3 && (
						<DataRow
							contentType="sector"
							sectorHeight={props?.sectorHeight2}
							compareTable={compareTable}
						>
							<h3>Specialty</h3>
						</DataRow>
					)}
					{!directCompare && (
						<DataRow
							contentType="sector"
							sectorHeight={props?.sectorHeight}
							compareTable={compareTable}
						>
							{searchCriteria.profile_type !== 1 && (
								<>
									{searchCriteria.profile_type === 4 && (
										<h3>Expertise & Disciplines</h3>
									)}
									{searchCriteria.profile_type === 3 && <h3>Trade/s</h3>}
									{searchCriteria.profile_type === 2 && <h3>Specialty</h3>}
								</>
							)}
						</DataRow>
					)}
					{searchCriteria.profile_type !== 3 &&
						(compareTable ? (
							<DataRow contentType="avatar-list">
								<h3>No. of key personnel with relevant sector experience</h3>
							</DataRow>
						) : (
							<DataRow contentType="avatar-group">
								<h3>No. of key personnel with relevant sector experience</h3>
							</DataRow>
						))}
					{compareTable ? (
						<DataRow contentType="project-list">
							<h3>
								{directCompare
									? "No. of latest completed projects from the current year"
									: "No. of projects relevant to sector"}
							</h3>
						</DataRow>
					) : (
						<DataRow contentType="project-images">
							<h3>No. of projects relevant to sector</h3>
						</DataRow>
					)}

					<DataRow contentType="documents">
						<h3>Corporate Info</h3>
					</DataRow>
				</DataHeadings>
			)}
			{props.children}
		</TableBody>
	);
};
