import { connect } from "react-redux";
import { ProjectForm } from "components/ProjectForm/ProjectForm";
import {
	saveProjectChangesForRfx,
	getPastProjectForRfx,
} from "actions/RFQ/Supplier/rfqActions";
import { Box, Flex } from "@chakra-ui/react";
import { Button } from "components/Button";
import { removeCarriage } from "utils/helpers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { SmartFieldCancelModal } from "./_components/SmartFieldCancelModal";
import { useSelector } from "react-redux";
import { useSmartFieldEditor } from "./hooks/useSmartFieldEditor";
import {
	LoadingView,
	ErrorView,
	NoDataView,
} from "./_components/SmartFieldStatusViews";

export const SmartFieldProjectEditorComponent = ({
	projectId,
	rfxId,
	companyId,
	...props
}) => {
	const {
		isModalOpen,
		isModalSaving,
		setIsModalSaving,
		isInModalFlow,
		setIsInModalFlow,
		errorStatus,
		setErrorStatus,
		autoSaveDisabled,
		setAutoSaveDisabled,
		navigateToReturnableSchedules,
		handleCancelClick,
		handleModalClose,
	} = useSmartFieldEditor({
		id: projectId,
		rfxId,
		companyId,
		entityType: "Project",
	});

	// Get trade values from Redux store
	const tradeValues = useSelector((state) => state.companyProfile.tradeValues);

	const {
		data: projectData,
		isLoading,
		error: projectError,
	} = useQuery({
		queryKey: ["pastProjectForRfx", { projectId, rfqId: rfxId }],
		queryFn: getPastProjectForRfx,
		enabled: !!projectId && !!rfxId,
	});

	// Setup mutation for saving project changes
	const saveProjectMutation = useMutation({
		mutationFn: (values) => {
			return saveProjectChangesForRfx(projectId, rfxId, values);
		},
		onSuccess: (data) => {
			// reset modal saving state
			setIsModalSaving(false);
			navigateToReturnableSchedules();
		},
		onError: (error) => {
			setIsModalSaving(false);
		},
	});

	// helper function to map trade value string to ID (similar to checkTrade in EditProject.js)
	const mapTradeValueToId = (tradeValueString) => {
		if (!tradeValues || !tradeValueString) return 1;

		const optionIndex = tradeValues.findIndex(
			(option) => tradeValueString === option.name
		);
		return optionIndex === -1 ? 1 : tradeValues[optionIndex].id;
	};

	const handleSubmit = async (values, { setSubmitting }) => {
		// Only proceed with submission if not in modal flow or explicitly saving
		if (!isInModalFlow || isModalSaving) {
			try {
				// Create a copy of values to avoid modifying the original
				const formattedValues = { ...values };

				// 1. Fix location if it's an object
				if (
					typeof formattedValues.location === "object" &&
					formattedValues.location !== null
				) {
					// If it's already a string representation, keep it as is
					if (typeof formattedValues.location === "string") {
						// Do nothing, it's already a string
					} else {
						// Otherwise, stringify it properly
						formattedValues.location = JSON.stringify(formattedValues.location);
					}
				}

				// 2. Ensure scope and trade_ids arrays exist
				formattedValues.scope = formattedValues.scope || [];
				formattedValues.trade_ids = formattedValues.trade_ids || [];

				await saveProjectMutation.mutateAsync(formattedValues);
				// Navigation is now handled in onSuccess callback
			} catch (error) {
				console.error("Error in handleSubmit:", error);
			} finally {
				setSubmitting(false);
			}
		} else {
			// If in modal flow but not explicitly saving, just set submitting to false
			setSubmitting(false);
		}

		// Reset the modal flow flag if we're not in the modal anymore
		if (!isModalOpen) {
			setIsInModalFlow(false);
		}
	};

	const handleSaveFromModal = () => {
		setIsModalSaving(true);

		// instead of looking for a submit button, directly trigger form submission
		const form = document.querySelector("form");
		if (form) {
			form.dispatchEvent(
				new Event("submit", { cancelable: true, bubbles: true })
			);
		} else {
			setIsModalSaving(false);
		}
	};

	const initialValues = projectData
		? {
				name: projectData.name || "",
				location: projectData.location || "",
				summary: removeCarriage(projectData.summary) || "",
				value: projectData.value?.toString() || "0",
				// Map the trade_value string to its corresponding ID
				trade_value: mapTradeValueToId(projectData.trade_value) || 1,
				year_completed: projectData.year_completed?.toString() || "",
				funding_type: projectData.funding_type || "",
				site_area: projectData.site_area || "",
				gross_floor_area: projectData.gross_floor_area || "",
				scope: projectData.scope || [],
				client_name: projectData.client_name || "",
				head_contractor: projectData.head_contractor || "",
				sector_id: projectData.sector?.id || "",
				images: projectData.images || [],
				innovation_value_summary:
					removeCarriage(projectData.innovation_value_summary) || "",
				awards: removeCarriage(projectData.awards) || "",
				testimonials: projectData.testimonials || "",
				status: projectData.status,
				procurement_method_ids: (projectData.procurement_methods || []).map(
					(method) => method.id
				),
				trade_ids: Array.isArray(projectData.trades)
					? projectData.trades.map((trade) => trade.id)
					: [],
				additional_notes: projectData.additional_notes || "",
				...(console.log("[debug] projectData:", projectData) && {}),
			}
		: null;

	if (isLoading) {
		return <LoadingView entityType="project" />;
	}

	if (projectError) {
		return (
			<ErrorView
				error={projectError}
				entityType="project"
				onNavigate={navigateToReturnableSchedules}
			/>
		);
	}

	if (!initialValues) {
		return (
			<NoDataView
				entityType="project"
				onNavigate={navigateToReturnableSchedules}
			/>
		);
	}

	return (
		<Box p={4}>
			<ProjectForm
				initialValues={initialValues}
				handleSubmit={handleSubmit}
				isRequesting={saveProjectMutation.isPending}
				supplierSmartField={true}
				autoUpdateForm={autoSaveDisabled}
				setAutoUpdateForm={setAutoSaveDisabled}
				errorStatus={errorStatus}
				setErrorStatus={setErrorStatus}
				additionalFields={
					<div className="my-4">
						<FormatedInput
							label="Additional Information for RFX"
							name="additional_notes"
							type="text"
							placeholder="Add any additional information specific to this RFX..."
							characterLimit={5000}
							rfq={true}
							inputTestId="additional_notes"
						/>
					</div>
				}
				customButtons={
					<Flex
						justifyContent="flex-end"
						gap={4}
						mt={6}
					>
						<Button
							btntype="link"
							onClick={handleCancelClick}
						>
							Cancel
						</Button>
						<Button
							type="button"
							isLoading={saveProjectMutation.isPending}
							onClick={() => {
								const form = document.querySelector("form");
								if (form) {
									form.dispatchEvent(
										new Event("submit", { cancelable: true, bubbles: true })
									);
								}
							}}
						>
							Save Changes to RFX
						</Button>
					</Flex>
				}
				hideDefaultButtons={true}
			/>
			<SmartFieldCancelModal
				isOpen={isModalOpen}
				onClose={handleModalClose}
				onDiscard={navigateToReturnableSchedules}
				onSave={handleSaveFromModal}
				isSaving={isModalSaving}
			/>
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		companyId: state.search.activeCompany.id,
	};
};

export const SmartFieldProjectEditor = connect(mapStateToProps)(
	SmartFieldProjectEditorComponent
);

export default SmartFieldProjectEditor;
