import React from "react";
import { Button } from "../Button";
import { cn } from "utils/helpers";
import { Flex } from "@chakra-ui/react";
import { Field, useField } from "formik";
import { Icon } from "assets/icons/Icon";

const tableField = [
	{
		field: "sort_order",
		placeholder: "",
	},
	{
		field: "name",
		placeholder: "",
	},
	{
		field: "unit_id",
		placeholder: "",
	},
	{
		field: "total",
		placeholder: "0",
	},
	{
		field: "rate",
		placeholder: "$0.00",
	},
	{
		field: "cost",
		placeholder: "$0.00",
	},
];

const EditableTable = ({
	data,
	handleChangeInput,
	placeholder,
	tableVariable,
	className,
	isPreview,
	type = "submission",
	children,
	index,
	unitMeasures,
	disable,
	removeRow,
	addRow,
	copyRow,
	...props
}) => {
	const fieldname = (number, name) => `quote[${index}]items[${number}]${name}`;
	const [, { value: itemsValue }] = useField(`quote[${index}]items`);
	const isSubmission = type === "submission";

	const autoCalculate = (index) => {
		if (itemsValue[index]?.total && itemsValue[index]?.rate) {
			return (
				itemsValue[index]?.total * itemsValue[index]?.rate
			).toLocaleString("en-US", { style: "currency", currency: "USD" });
		} else {
			return "$0.00";
		}
	};

	const FieldDefaultClasses = cn(
		"py-2 focus:outline-none bg-transparent border-none px-3 w-full disabled:bg-gray-50 bg-white placeholder:text-gray-400",
		!isSubmission && "invalid:bg-red-50"
	);

	return (
		<div
			className={cn("mx-auto mt-4 px-3", className)}
			{...props}
		>
			<table className="w-full border-collapse">
				<thead>
					<tr>
						{tableVariable?.map((item, index) => (
							<th
								key={index}
								className="w-1/6 border border-gray-200 bg-bg-gray-200 px-4 py-3 text-left text-xs font-medium text-tertiary-600"
								style={{
									width:
										index === 0
											? "4%"
											: index === 1
												? "32%"
												: index === 2
													? "18%"
													: "10%",
									textAlign: index === 0 && "center",
								}}
							>
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data?.map((item, index) => {
						const inputCells = tableVariable?.map((_, columnIndex) => (
							<td
								className={cn(
									"border border-gray-300",
									columnIndex === 0 && "w-[1%] bg-bg-gray-200 text-center",
									(isSubmission ? columnIndex < 2 : columnIndex > 2) &&
										"bg-bg-gray-200/70"
								)}
								key={columnIndex}
							>
								{columnIndex === 0 ? (
									<Field
										className={cn(
											FieldDefaultClasses,
											columnIndex === 0 && "max-w-[50px] text-center"
										)}
										value={index + 1}
										disabled
										type="text"
									/>
								) : columnIndex === 5 && isSubmission ? (
									<Field
										className={cn(
											FieldDefaultClasses,
											columnIndex === 0 && "max-w-[50px] text-center"
										)}
										name={fieldname(index, [tableField[columnIndex].field])}
										disabled
										placeholder={tableField[columnIndex].placeholder}
										value={autoCalculate(index)}
									/>
								) : columnIndex === 4 ? (
									<>
										<Field
											className={cn(
												FieldDefaultClasses,
												columnIndex === 0 && "max-w-[50px] text-center"
											)}
											name={fieldname(index, [tableField[columnIndex].field])}
											disabled
											placeholder={tableField[columnIndex].placeholder}
											value={parseFloat(item?.rate || 0).toLocaleString(
												"en-US",
												{ style: "currency", currency: "USD" }
											)}
										/>
									</>
								) : columnIndex !== 2 ? (
									<div className="flex items-center justify-between pe-5">
										<Field
											className={cn(
												FieldDefaultClasses,
												"scrollbar row-2 flex w-full resize-none items-center overflow-hidden",
												columnIndex === 0 && "max-w-[50px] text-center"
											)}
											name={fieldname(index, [tableField[columnIndex].field])}
											disabled={
												(isSubmission ? disable : columnIndex > 2) ||
												columnIndex === 0 ||
												columnIndex === 5
											}
											placeholder={
												columnIndex === 1
													? item?.placeholder
													: tableField[columnIndex].placeholder
											}
											type={columnIndex > 2 ? "number" : "text"}
											required
											onKeyUp={(e) => {
												e.preventDefault();
												if (e.key === "Enter") {
													copyRow(item, columnIndex);
												}
											}}
										/>
										{!isSubmission && columnIndex === 5 && (
											<div className="grid grid-cols-2 gap-4">
												<button
													type="button"
													onClick={() => {
														copyRow(item, index);
													}}
												>
													<Icon
														icon="copy-icon"
														style={{ cursor: "pointer", height: 15 }}
													/>
												</button>
												<button
													type="button"
													onClick={() => {
														removeRow(item, index);
													}}
												>
													<Icon
														icon="trash-stroke"
														style={{ cursor: "pointer", height: 15 }}
													/>
												</button>
											</div>
										)}
									</div>
								) : (
									<div className="relative">
										<Field
											as="select"
											className={cn(
												"focusable mr-4 block w-full appearance-none border-none bg-transparent px-4 py-2 invalid:bg-red-50 invalid:text-gray-400 focus-within:bg-white focus:outline-none",
												columnIndex === 0 && "max-w-[50px] text-center",
												disable && "disabled:bg-gray-50"
											)}
											disabled={disable ? disable : false}
											name={fieldname(index, [tableField[columnIndex].field])}
											placeholder={tableField[columnIndex].placeholder}
											required
										>
											<option
												className="!bg-white"
												value=""
												disabled
											>
												Select unit of measurement
											</option>
											{unitMeasures?.map((unit) => (
												<option
													key={unit.value}
													value={unit.value.toString()}
													data-testid={`${fieldname(index, unit?.label)}`}
												>
													{unit?.label}
												</option>
											))}
										</Field>
										{!isPreview ? (
											<div className="pointer-events-none absolute inset-y-0 right-0 top-1/2 mr-3 flex -translate-y-1/2 transform items-center px-2 text-gray-700">
												<svg
													className="h-4 w-4"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M19 9l-7 7-7-7"
													/>
												</svg>
											</div>
										) : null}
									</div>
								)}
							</td>
						));
						if (!isSubmission && item?.isRemove === 1) {
							return null;
						} else {
							return <tr key={index}>{inputCells}</tr>;
						}
					})}
					{!isPreview && (
						<tr>
							<td colSpan={tableVariable?.length || 1}>
								<Button
									btntype={"plain"}
									onClick={(event) => {
										event.preventDefault();
										addRow();
									}}
									type="button"
									style={{ paddingLeft: "8px" }}
									data-testid={`quote[${index}]add-row`}
								>
									<Flex
										gap={1}
										align={"center"}
									>
										<span className="text-royal-blue-500">+</span>
										<span>Add row</span>
									</Flex>
								</Button>
							</td>
						</tr>
					)}
					{children}
				</tbody>
			</table>
		</div>
	);
};

export default EditableTable;
