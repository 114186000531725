import { Box, Flex } from "@chakra-ui/react";
import { DateInput } from "components/DateInput";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { Location } from "components/FormInputs/Location";
import { FileCard } from "components/RFQ/FileCard";
import { FileUpload } from "components/RFQ/FileUpload";
import { FieldForm, Input } from "components/RFQ/Input";
import { cn } from "utils/helpers";
import { CorporateDocument } from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/Questionnaire/_components/CorporateDocument";
import { ProjectExperienceCard } from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/Questionnaire/_components/ProjectExperience";
import { KeyPersonnel } from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/Questionnaire/_components/KeyPersonnel";
import { CheckboxField } from "components/RFQ/Checkbox";
import tw, { styled, css } from "twin.macro";
import { QUESTION_TYPES } from "components/Pages/CreateRFQ/ReturnableSchedulesCard/QuestionTypeComponent";
import { SubmissionSmartFieldPreviewContextProvider } from "context/RFQSubmittedResponseContext";
import { DetailModal } from "components/Pages/RFQDetail/RFQSubmission/RFQSubmissionView";
import ReactQuill from "react-quill";

const TextContainer = styled.div(() => [
	tw`w-full max-w-[1144px]`,
	css`
		* {
			word-wrap: break-word;
			max-width: "1144px";
			color: #5b6b88 !important;
		}
	`,
]);

const SectionComponent = ({
	className,
	sectionTitle,
	preview,
	description,
	...props
}) => {
	return (
		<div
			className={cn("mt-6 min-h-14 border-b", preview && "mt-10", className)}
			{...props}
		>
			<h1 className="text-xl font-bold text-secondary-700">{sectionTitle}</h1>
			{description && !props?.preview && (
				<div
					className="whitespace-pre text-sm text-tertiary-600"
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			)}
		</div>
	);
};

const TextAreaQuestionnaire = ({
	className,
	description,
	question,
	typeQuestion,
	required,
	disable,
	...props
}) => {
	return (
		<div
			className={cn("space-y-2", className)}
			{...props}
		>
			<div className="space-y-0">
				<h1 className="mt-4 text-lg font-semibold text-secondary-700">
					{question}
				</h1>
				{description && !props?.preview && (
					<p className="text-sm text-tertiary-600">{description}</p>
				)}
			</div>
			<div>
				{props?.preview ? (
					<TextContainer
						dangerouslySetInnerHTML={{ __html: props?.values }}
						className="break-words"
					/>
				) : (
					<FormatedInput
						characterLimit={typeQuestion === "short" ? 500 : 1500}
						rfq
						className="my-4 w-full"
						required={required}
						placeholder="Enter a description..."
						disabled={disable ?? false}
						subTitle={
							typeQuestion === "short"
								? "Maximum of 500 characters."
								: "Maximum of 1500 characters."
						}
						{...props}
					/>
				)}
			</div>
		</div>
	);
};

const SingleChoiceQuestionnaire = ({
	className,
	description,
	question,
	required,
	fieldValue,
	disable,
	...props
}) => {
	return (
		<div
			className={cn("space-y-2", className)}
			{...props}
		>
			<h1 className="mt-4 text-lg font-semibold text-secondary-700">
				{question}
			</h1>
			{description && !props?.preview && (
				<p className="text-secondary-600">{description}</p>
			)}
			<div className={`${!props?.preview && "ml-2 pb-0.5"}`}>
				{props?.preview ? (
					<p>{props?.values}</p>
				) : (
					<Input
						type="radio"
						className="my-3 w-full"
						options={fieldValue.map((item, i) => ({
							label: item,
							value: i + 1,
						}))}
						disabled={disable ?? false}
						name={"radio"}
					/>
				)}
			</div>
		</div>
	);
};

const MultipleChoiceQuestionnaire = ({
	className,
	description,
	question,
	required,
	options,
	fieldValue,
	disable,
	...props
}) => {
	return (
		<div className={cn("space-y-2", className)}>
			<h1 className="mt-4 text-lg font-semibold text-secondary-700">
				{question}
			</h1>
			{description && !props?.preview && (
				<p className="text-secondary-600">{description}</p>
			)}
			<div className={`${!props?.preview && "ml-2 py-3"}`}>
				{props?.preview ? (
					<p>{props?.values}</p>
				) : (
					<CheckboxField
						type="checkbox"
						name="questionnaire"
						option={fieldValue?.map((item) => ({ label: item, value: item }))}
						disabled={disable ?? false}
						className="-mt-1 w-full"
					/>
				)}
			</div>
		</div>
	);
};

const DropdownQuestionnaire = ({
	className,
	question,
	options,
	required,
	fieldValue,
	disable,
	...props
}) => {
	return (
		<div className={cn("space-y-2", className)}>
			<h1 className="mt-4 text-lg font-semibold text-secondary-700">
				{question}
			</h1>
			<div>
				{props?.preview ? (
					<p>{props?.values}</p>
				) : (
					<FieldForm
						type="select"
						options={fieldValue.map((item, i) => ({
							label: item,
							value: item,
						}))}
						className="my-4 w-full"
						required={required}
						disabled={disable ?? false}
						name={props.name}
					/>
				)}
			</div>
		</div>
	);
};

const DateSelectionQuestionnaire = ({
	className,
	question,
	required,
	fieldValue,
	disable,
	...props
}) => {
	return (
		<div className={cn("space-y-2", className)}>
			<h1 className="mt-4 text-lg font-semibold text-secondary-700">
				{question}
			</h1>
			<div>
				{props?.preview ? (
					<p>{props?.values}</p>
				) : (
					<DateInput
						icon={true}
						className="!font-regular flex-3 my-4 !border-fg-disabled_subtle"
						required={required}
						disabled={disable ?? false}
						{...props}
					/>
				)}
			</div>
		</div>
	);
};

const AddressFinderQuestionnaire = ({
	className,
	question,
	required,
	fieldValue,
	disable,
	...props
}) => {
	return (
		<div className={cn("space-y-2", className)}>
			<h1 className="mt-4 text-lg font-semibold text-secondary-700">
				{question}
			</h1>
			<div>
				{props?.preview ? (
					<p>{props?.values}</p>
				) : (
					<Location
						errorBottom={"-3px"}
						rfq
						className="relative my-4"
						required={required}
						disabled={disable ?? false}
						{...props}
					/>
				)}
			</div>
		</div>
	);
};

const SmartFieldQuestionnaire = ({
	className,
	question,
	values,
	required,
	fieldValue,
	smartField,
	disable,
	preview,
	...props
}) => {
	const renderSmartField = (values) => {
		switch (smartField) {
			case "Project Experience":
				return (
					<ProjectExperienceCard
						values={values}
						isPreview
					/>
				);
			case "Key Personel":
			case "Key Personnel":
				return (
					<KeyPersonnel
						values={values}
						isPreview
					/>
				);
			case "Corporate Documents":
				return <CorporateDocument values={values} />;
			default:
				return null;
		}
	};

	return (
		<div
			className={cn("space-y-2", className)}
			{...props}
		>
			<h1 className="mt-4 text-lg font-semibold text-secondary-700">
				{smartField}
			</h1>
			<div>
				{preview ? (
					<Flex className="gap-8 overflow-x-auto">
						{smartField === "Corporate Documents" ? (
							<CorporateDocument
								values={values}
								isPreview={preview}
							/>
						) : (smartField === "Key Personnel" ||
								smartField === "Project Experience") &&
						  typeof values === "string" ? (
							<ReactQuill
								readOnly
								value={values}
								theme="bubble"
								className="section summary"
							/>
						) : (
							values?.length > 0 &&
							Array.isArray(values) &&
							values.map((value) => <>{renderSmartField(value)}</>)
						)}
					</Flex>
				) : (
					<Input
						type="text"
						className="my-4 w-full"
						disabled={true}
						placeholder={question}
					/>
				)}
			</div>
		</div>
	);
};

const FileInputQuestionnaire = ({
	className,
	question,
	required,
	fieldValue,
	disable,
	...props
}) => {
	return (
		<div
			className={cn("space-y-2", className)}
			{...props}
		>
			<h1 className="mt-4 text-lg font-semibold text-secondary-700">
				{question}
			</h1>
			<div>
				{props?.preview ? (
					Array.isArray(props?.values) &&
					props?.values.length > 0 &&
					props?.values?.map((file) => {
						return (
							<FileCard
								file={{
									name: file?.fileName || "File",
									file_size: file?.fileSize || null,
									path: file?.filePath,
								}}
								isPreview
								className="!w-1/3"
							/>
						);
					})
				) : (
					<FileUpload
						type="file"
						name="questionnaire"
						className="my-4 w-full"
						types={["pdf", "docx", "xlsx", "jpeg", "jpg"]}
						defaultFileName="Document"
						disabled={disable}
					/>
				)}
			</div>
		</div>
	);
};

const TableQuestionnaire = ({ question, tableQuestions }) => {
	const headers = tableQuestions?.map((row) => row.header);
	const values =
		tableQuestions &&
		tableQuestions[0]?.values?.map((_, index) =>
			tableQuestions?.map((row) => (row?.values ? row.values[index] : ""))
		);

	return (
		<div className="py-2">
			<h1 className="mt-4 pb-2 text-lg font-semibold text-secondary-700">
				{question}
			</h1>

			<table className="w-full table-auto rounded-xl border-b border-solid border-[#EAECF0] bg-white">
				<thead className="py-4">
					<tr>
						{headers?.map((header, index) => (
							<th
								key={index}
								className="w-1/5 border-[#EAECF0] bg-[#F9FAFB] p-2 text-left font-roboto font-medium text-tertiary-600"
							>
								<h2 className="font-bold">{header}</h2>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{values?.map((row, rowIndex) => (
						<tr
							key={rowIndex}
							className="border-b border-[#EAECF0]"
						>
							{row.map((value, colIndex) => (
								<td
									key={colIndex}
									className="border border-[#EAECF0] px-3 py-2 text-left align-top font-roboto font-normal text-primary-500"
								>
									<p>
										<div
											className="rfx-response-table"
											dangerouslySetInnerHTML={{
												__html: value,
											}}
										></div>
									</p>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const fieldType = {
	[QUESTION_TYPES.SHORT_ANSWER]: {
		type: "short",
		description: "Maximum of 500 characters.",
	},
	[QUESTION_TYPES.PARAGRAPH]: {
		type: "long",
		description: "Maximum of 1500 characters.",
	},
	[QUESTION_TYPES.SINGLE_OPTION]: {
		type: "singleChoice",
		description: null,
	},
	[QUESTION_TYPES.MULTIPLE_CHOICE]: {
		type: "multipleChoice",
		description: null,
	},
	[QUESTION_TYPES.DROP_DOWN]: {
		type: "dropdown",
		description: null,
	},
	[QUESTION_TYPES.DATE_SELECTION]: {
		type: "date",
		description: "Single-date selection",
	},
	[QUESTION_TYPES.ADDRESS_FINDER]: {
		type: "address",
		description: null,
	},
	[QUESTION_TYPES.PROJECT_EXPERIENCE]: {
		type: "projectexp",
		description: null,
	},
	[QUESTION_TYPES.KEY_PERSONNEL]: {
		type: "keypersonel",
		description: null,
	},
	[QUESTION_TYPES.CORPORATE_DOCUMENTS]: {
		type: "corporatedoc",
		description: null,
	},
	[QUESTION_TYPES.FILE_UPLOAD]: {
		type: "file",
		description: null,
	},
};

// Client - Procurer
const Questionnaire = ({
	questionnaire,
	preview = false,
	disable = false,
	isGuest,
	returnableScheduleFile,
	returnableScheduleFileType,
	handleSmartFieldDetailView,
	procurementMethods,
	company,
	...props
}) => {
	const renderQuestion = (question, index) => {
		switch (question.type) {
			case QUESTION_TYPES.SHORT_ANSWER:
				return (
					<TextAreaQuestionnaire
						key={index}
						{...question}
						preview={preview}
						disable={disable}
					/>
				);
			case QUESTION_TYPES.PARAGRAPH:
				return (
					<TextAreaQuestionnaire
						key={index}
						{...question}
						preview={preview}
						disable={disable}
					/>
				);
			case QUESTION_TYPES.SINGLE_OPTION:
				return (
					<SingleChoiceQuestionnaire
						key={index}
						{...question}
						preview={preview}
						disable={disable}
					/>
				);
			case QUESTION_TYPES.MULTIPLE_CHOICE:
				return (
					<MultipleChoiceQuestionnaire
						key={index}
						{...question}
						preview={preview}
						disable={disable}
					/>
				);
			case QUESTION_TYPES.DROP_DOWN:
				return (
					<DropdownQuestionnaire
						key={index}
						{...question}
						preview={preview}
						disable={disable}
					/>
				);
			case QUESTION_TYPES.DATE_SELECTION:
				return (
					<DateSelectionQuestionnaire
						key={index}
						{...question}
						preview={preview}
						disable={disable}
					/>
				);
			case QUESTION_TYPES.ADDRESS_FINDER:
				return (
					<AddressFinderQuestionnaire
						key={index}
						{...question}
						preview={preview}
						disable={disable}
					/>
				);
			case QUESTION_TYPES.PROJECT_EXPERIENCE:
				return isGuest ? (
					<TextAreaQuestionnaire
						key={index}
						{...question}
						preview={preview}
						disable={disable}
						question="Project Experience"
					/>
				) : (
					<SmartFieldQuestionnaire
						key={index}
						smartField="Project Experience"
						{...question}
						preview={preview}
					/>
				);
			case QUESTION_TYPES.KEY_PERSONNEL:
				return isGuest ? (
					<TextAreaQuestionnaire
						key={index}
						{...question}
						preview={preview}
						disable={disable}
						question="Key Personnel"
					/>
				) : (
					<SmartFieldQuestionnaire
						key={index}
						smartField="Key Personnel"
						{...question}
						preview={preview}
					/>
				);
			case QUESTION_TYPES.CORPORATE_DOCUMENTS:
				return isGuest ? (
					<FileInputQuestionnaire
						key={index}
						{...question}
						preview={preview}
						disable={disable}
						question="Corporate Document"
					/>
				) : (
					<SmartFieldQuestionnaire
						key={index}
						smartField="Corporate Documents"
						{...question}
						preview={preview}
					/>
				);
			case QUESTION_TYPES.FILE_UPLOAD:
				return (
					<FileInputQuestionnaire
						key={index}
						{...question}
						disable={disable}
						preview={preview}
					/>
				);
			case QUESTION_TYPES.TABLE:
				const getTableValue = () => {
					if (question?.fieldValue?.length > 0) {
						return question?.fieldValue;
					}

					if (typeof question?.fieldValue === "object") {
						if (Array.isArray(question?.values)) {
							return question?.values;
						}
						return question?.fieldValue?.table;
					}
				};

				return (
					<TableQuestionnaire
						tableQuestions={getTableValue()}
						{...question}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<SubmissionSmartFieldPreviewContextProvider>
			<Box
				className="min-h-80 w-full rounded-md border p-10 pt-0 shadow-xl"
				{...props}
			>
				{!returnableScheduleFileType ||
				returnableScheduleFileType === "form" ? (
					questionnaire?.map((section, sectionID) => {
						const questions = section.question || section.questionnaire;
						return !section.isRemove ? (
							<div key={section.id}>
								<SectionComponent
									sectionTitle={section?.name || " - "}
									description={section?.description || "-"}
									preview={preview}
								/>
								{questions?.map((question, index) => {
									const questionProps = {
										type:
											question.rfq_question_type_id ||
											question?.type ||
											"table",
										name:
											`questionnaire[${sectionID}]question[${index}]` ||
											question?.name,
										question: question?.name,
										typeQuestion:
											question?.type ||
											fieldType[question.rfq_question_type_id]?.type ||
											"table",
										fieldValue:
											question?.fields?.option || question?.fields || [],
										// description: fieldType[question.rfq_question_type_id].description || "-",
										values: question?.values || " - ",
										required: question?.required,
									};
									return renderQuestion(questionProps, index);
								})}
							</div>
						) : null;
					})
				) : (
					<div className="py-8">
						<h1 className="text-md h-max font-bold">Uploaded Template</h1>
						{returnableScheduleFile && (
							<FileCard
								file={returnableScheduleFile}
								isPreview
								className="h-10"
							/>
						)}
					</div>
				)}
			</Box>
			<DetailModal
				procurementMethods={procurementMethods}
				company={company}
			/>
		</SubmissionSmartFieldPreviewContextProvider>
	);
};

export default Questionnaire;
