import React from "react";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import styled from "styled-components";
import { useField, useFormikContext } from "formik";
import { ReactComponent as SearchIcon } from "./search.svg";
import { Error } from "../Error";
import { Icon } from "assets/icons/Icon";
import tw from "twin.macro";
import LabelForm from "components/RFQ/LabelForm/LabelForm";

export const InputWrap = styled.div`
	position: relative;
	border: ${(props) =>
		props?.isError
			? "1px solid #dd382b"
			: props.rfq
				? "1px solid #D0D5DD"
				: ""};
	border-radius: ${(props) => props.rfq && "6px"};
	padding: ${(props) => props.rfq && "16px"};
	background: ${(props) => (props.disabled ? "#f9f9f9" : "white")};
	svg {
		position: absolute;
		right: 1.25rem;
		top: 50%;
		transform: translateY(-50%);

		path {
			stroke: ${(props) => !props.rfq && "#336AF7"};
		}
	}
`;

export const LocationStyled = styled.input`
	display: block;
	border-radius: ${(props) => props.theme.radii.default};
	background: ${(props) => (props.disabled ? "#f9f9f9" : "white")};
	border: ${(props) => (props.rfq ? "none" : "1px solid #e7eaee")};
	padding: ${(props) => (props.rfq ? "0" : "16px")};
	width: 100%;
	padding-left: ${(props) => (props.rfqIcon ? "1.5rem" : "0px")};
	margin-bottom: ${(props) => (props.rfq ? "0" : "1rem")};
	color: ${(props) => props.theme.colors.gray};
	position: relative;
	padding-right: 50px;
	height: ${(props) => (props.rfq ? "24px" : "60px")};
	border-color: ${(props) => (props.isError ? "#E11900" : "")};
	outline: ${(props) => props.rfq && "none"};
	${tw`!ring-transparent placeholder:text-placeholder`}
	& > .google-places-autocomplete {
		font-size: 24px;
		color: rgb(255, 0, 0);
	}
`;

export const Suggestions = styled.div`
	position: absolute;
	top: 5.4rem;
	padding: 0.5rem 0;
	display: block;
	border-bottom-left-radius: ${(props) => props.theme.radii.default};
	border-bottom-right-radius: ${(props) => props.theme.radii.default};
	border: 1px solid #e7eaee;
	border-top: none;
	background: white;
	border: 1px solid #e7eaee;
	width: 100%;
	color: inherit;
	z-index: 3;
	width: calc(100% - 30px);
	font-size: ${(props) => props.theme.fontSizes.md};
	display: ${(props) => (props.children[1].length ? "block" : "none")};
`;

export const SuggestionItem = styled.div`
	cursor: pointer;
	transition: ${(props) => props.theme.transitions.default};
	padding: 0.65rem 1rem;

	&::hover {
		background-color: #deebff;
	}
`;

export const LocationWrapper = styled.div`
	position: relative;
	transition: 0.15s all ease;
	padding-bottom: ${(props) => (props?.rfq ? "0" : "2px")};
	width: 100%;
`;

export const PlaceAutoCompleteWrapper = styled.div`
	${(props) =>
		!props?.isError
			? tw`focus-within:ring-4 focus-within:!border-utility-brand-300 focus-within:ring-ring-blue-primary`
			: tw`focus-within:ring-4 focus-within:!border-utility-error-300 focus-within:ring-ring-red-primary`}
`;

export const Location = ({
	icon,
	iconColor,
	iconPosition,
	customIcon,
	label,
	required,
	errorBottom,
	name,
	disabled,
	initialValue = "",
	rfq = true,
	projectForm,
	rfqIcon,
	className,
	allowInternational = false,
	inputTestId,
	dropdownTestId,
	...props
}) => {
	// eslint-disable-next-line
	const [field, meta] = useField(name);
	const { setFieldValue, setFieldTouched } = useFormikContext();

	const handleChange = (address = "") => {
		if (props?.applyValue) {
			setFieldValue(name, address);
		} else {
			setFieldValue(name, {
				value: address,
				latitude: field.value?.latitude,
				longitude: field.value?.longitude,
			});
		}
	};

	const handleSelect = (address) => {
		if (props?.applyValue) {
			setFieldValue(name, address);
		} else {
			setFieldValue(name, { value: address, latitude: null, longitude: null });
		}

		let postcode = "";
		let streetNumber = "";
		let route = "";

		let addressSplit = address.split(",");
		let addressShortened = Array.isArray(addressSplit)
			? addressSplit.splice(0, 2).join()
			: "";

		geocodeByAddress(address)
			.then((results) => {
				postcode =
					results[0].address_components.find(
						(a) => a.types[0] === "postal_code"
					)?.short_name || "";
				streetNumber = results[0].address_components.find(
					(a) => a.types[0] === "street_number"
				)
					? results[0].address_components.find(
							(a) => a.types[0] === "street_number"
						)?.short_name
					: "";
				route = results[0].address_components.find(
					(a) => a.types[0] === "route"
				)
					? results[0].address_components.find((a) => a.types[0] === "route")
							?.long_name
					: "";
				let city = "city";
				let state = "state";
				let postcodeField = "postcode";
				if (name.includes("[") && name.includes("]")) {
					let toFind = name.slice(0, name.indexOf("["));
					city = name.replace(toFind, city);
					state = name.replace(toFind, state);
					postcodeField = name.replace(toFind, postcodeField);
				}
				if (!projectForm && !props?.applyValue) {
					setFieldValue(
						"address_line_1",
						streetNumber.length > 0 ? streetNumber + " " + route : route
					);
					setFieldValue(
						city,
						results[0].address_components.find((a) => a.types[0] === "locality")
							?.short_name || ""
					);
					setFieldValue(
						state,
						results[0].address_components.find(
							(a) => a.types[0] === "administrative_area_level_1"
						)?.short_name || ""
					);
					setFieldValue(postcodeField, postcode);
				}
				return getLatLng(results[0]);
			})
			.then((loc) => {
				if (props?.applyValue) {
					setFieldValue(name, addressShortened);
				} else {
					setFieldValue(name, {
						value: addressShortened,
						latitude: loc.lat,
						longitude: loc.lng,
					});
				}
			})
			.catch((error) => console.error("Error", error));
	};

	const searchOptions = {
		componentRestrictions: allowInternational ? {} : { country: "au" },
		language: "en",
	};

	return (
		<LocationWrapper
			className={className}
			rfq={rfq}
		>
			{label && (
				<LabelForm
					htmlFor={props.id || props.name}
					label={label}
					required={required}
				/>
			)}
			<PlaceAutoCompleteWrapper>
				<PlacesAutocomplete
					value={
						props?.applyValue && field.value
							? field.value
							: field.value?.value
								? field.value.value
								: ""
					}
					onChange={handleChange}
					onSelect={handleSelect}
					searchOptions={searchOptions}
					autoComplete={false}
					highlightFirstSuggestion={true}
				>
					{({
						getInputProps,
						suggestions,
						getSuggestionItemProps,
						loading,
					}) => (
						<div className="relative !ring-0">
							<InputWrap
								rfq={rfq}
								isError={props?.isErrorValue || (meta.touched && meta.error)}
								disabled={disabled}
							>
								{rfqIcon && (
									<Icon
										icon={rfqIcon}
										width={20}
										height={20}
										color={iconColor ? iconColor : "#1f1f1f"}
										className="absolute left-8 top-1/2 z-50 h-5 w-5 -translate-y-1/2"
									/>
								)}
								<LocationStyled
									rfq={rfq}
									rfqIcon={rfqIcon}
									isError={meta.touched && meta.error}
									{...getInputProps({
										placeholder: "Search address",
										className: "location-search-input",
										autoComplete: "new-password",
										list: "autocompleteOff",
										disabled: disabled,
										"data-testid": inputTestId,
									})}
									onBlur={() => setFieldTouched(name, true)}
								/>
								{!rfq && <SearchIcon />}
							</InputWrap>
							<Suggestions data-testid={dropdownTestId}>
								{loading && <p>Loading...</p>}
								{suggestions.map((suggestion, index) => {
									const className = suggestion.active
										? "suggestion-item--active"
										: "suggestion-item";
									return (
										<SuggestionItem
											key={index}
											{...getSuggestionItemProps(suggestion, {
												className,
												key: index,
											})}
										>
											<span>{suggestion.description}</span>
										</SuggestionItem>
									);
								})}
							</Suggestions>
						</div>
					)}
				</PlacesAutocomplete>
			</PlaceAutoCompleteWrapper>
			<Error
				errorBottom={errorBottom}
				meta={meta}
				className={rfq && "!mt-1 text-sm !text-utility-error-500"}
			/>
		</LocationWrapper>
	);
};
