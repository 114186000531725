import { Spinner } from "@chakra-ui/react";
import { Button } from "components/Button";
import { CustomModal } from "components/RFQ/Modal/CustomModal";

export const SmartFieldCancelModal = ({
	isOpen,
	onClose,
	onDiscard,
	onSave,
	isSaving,
}) => {
	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			header={{
				title: "Unsaved Changes",
				description: "Do you want to save or discard changes?",
				icon: "save-01",
				bgColor: "bg-brand-secondary",
				iconColor: "text-utility-brand-700",
			}}
			footerComponent={
				<div className="flex w-full gap-4 [&_button]:w-full">
					<Button
						btntype="cancel"
						onClick={onDiscard}
					>
						Discard
					</Button>
					<Button
						type="button"
						isLoading={isSaving}
						onClick={onSave}
					>
						{isSaving ? <Spinner /> : "Save Changes to RFX"}
					</Button>
				</div>
			}
		></CustomModal>
	);
};
