import { TabHeader } from "components/TabHeader";
import React, { useEffect, useState, useCallback } from "react";
import { navigate } from "@reach/router";
import { Form, Formik } from "formik";
import { Flex, Spinner, useToast } from "@chakra-ui/react";
import { FieldForm } from "components/RFQ/Input/Input";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { FileUpload } from "components/RFQ/FileUpload";
import { sectorFilter } from "helpers/Enum/FilterProjectList";
import { Location } from "components/FormInputs/Location";
import { useDispatch, useSelector } from "react-redux";
import {
	getProjectById,
	storeNewProject,
	updateProject,
} from "actions/RFQ/projectsAction";
import { toFormData } from "axios";
import { Submit } from "components/FormInputs/Submit";
import { BackButton } from "components/RFQ/BackButton";
import TextButton from "components/RFQ/Button/TextButton";
import { VStack, Grid, GridItem } from "@chakra-ui/react";
import * as yup from "yup";
import { Icon } from "assets/icons/Icon";
import UserSelectDropDown from "components/RFQ/UserSelectDropDown/UserSelectDropDown";

const validationSchema = yup.object().shape({
	name: yup
		.string()
		.required("Enter a project name")
		.max(100, "Project name must be 100 characters or less"),

	sector: yup
		.array()
		.required("Select at least one sector")
		.min(1, "Select at least one sector"),

	project_lead_id: yup.number().required("Enter a project lead"),

	description: yup.string().required("Add a project description"),

	address: yup.object().shape({
		value: yup.string().required("Enter a project location").nullable(),
	}),

	logo_path: yup
		.mixed()
		.test("is-string-or-file", "Upload a client logo", (value) => {
			return typeof value === "string" || value?.[0] instanceof File;
		})
		.test("file-size", "Logo must be less than 10MB", (value) => {
			if (typeof value === "string") return true;
			if (!value?.[0]) return true;
			return value[0].size <= 10 * 1024 * 1024;
		})
		.test("file-type", "Logo must be JPG or PNG format", (value) => {
			if (typeof value === "string") return true;
			if (!value?.[0]) return true;
			return ["image/jpeg", "image/png"].includes(value[0].type);
		})
		.required("Upload a client logo"),

	background_image_path: yup
		.mixed()
		.test("is-string-or-file", "Upload a project image", (value) => {
			return typeof value === "string" || value?.[0] instanceof File;
		})
		.test("file-size", "Project image must be less than 10MB", (value) => {
			if (typeof value === "string") return true;
			if (!value?.[0]) return true;
			return value[0].size <= 10 * 1024 * 1024;
		})
		.test("file-type", "Project image must be JPG or PNG format", (value) => {
			if (typeof value === "string") return true;
			if (!value?.[0]) return true;
			return ["image/jpeg", "image/png"].includes(value[0].type);
		})
		.required("Upload a project image"),
});

const formValidationMessages = {
	locationError: "Select a location from the suggestions",
	invalidFileType: "Only JPG and PNG files are allowed",
	fileTooLarge: "File size must be less than 10MB",
	characterLimitExceeded: "Maximum character limit exceeded",
	loading: "Creating project...",
	success: "Project created successfully",
	error: "Failed to create project. Please try again.",
};

const ProjectForm = ({
	projectId,
	newProject,
	editProject,
	newProjectFromSupplierList,
	supplierListId,
}) => {
	const isLoading = useSelector((state) => state.rfq.projects.loading);
	const dispatch = useDispatch();
	const toast = useToast();
	const [project, setProject] = useState(null);
	const [loadProject, setLoadProject] = useState(false);

	const handleBackButtonClick = useCallback(() => {
		newProject
			? navigate("./")
			: window.history.length > 1
				? window.history.back()
				: navigate("../");
	}, [newProject]);

	useEffect(() => {
		const fetchProject = async () => {
			if (projectId && !newProject) {
				setLoadProject(true);
				try {
					const response = await getProjectById(projectId);
					setProject(response);
				} catch (error) {
					toast({
						title: "Error",
						description: "Failed to load project details",
						status: "error",
						duration: 5000,
						isClosable: true,
					});
				} finally {
					setLoadProject(false);
				}
			}
		};

		fetchProject();
	}, [projectId, newProject, toast]);

	const initialValues = {
		name: "",
		sector: [],
		project_lead_id: "",
		description: "",
		address: { latitude: "", longitude: "", value: "" },
		location: "",
		location_latitude: "",
		location_longitude: "",
		logo_path: "",
		background_image_path: "",
	};

	const initialValuesProject = {
		name: project?.name || "",
		sector: project?.sector?.map((item) => item.id) || [],
		project_lead_id: project?.projectLead?.id || "",
		description: project?.description || "",
		address: {
			latitude: project?.locationLatitude,
			longitude: project?.locationLongitude,
			value: project?.location,
		} || { value: "" },
		location: project?.location || "",
		location_latitude: project?.locationLatitude || "",
		location_longitude: project?.locationLongitude || "",
		logo_path: project?.logoPath || "",
		background_image_path: project?.backgroundImagePath || "",
	};

	const handleSubmit = async (values, { setSubmitting }) => {
		const data = {
			isSubmitted: 0,
			name: values.name || null,
			description: values.description || null,
			project_lead_id: values.project_lead_id || null,
			location: values.address?.value || null,
			location_latitude: values.address?.latitude || null,
			location_longitude: values.address?.longitude || null,
			state: values.state || null,
			sector: values.sector || [],
			logo_path:
				typeof values.logo_path === "string"
					? null
					: values.logo_path.length > 0
						? values.logo_path[0]
						: null,
			background_image_path:
				typeof values.background_image_path === "string"
					? null
					: values.background_image_path.length > 0
						? values.background_image_path[0]
						: null,
			supplier_list_ids: [supplierListId] || [],
		};

		const formData = toFormData(data, undefined, { indexes: true });

		try {
			if (newProject || newProjectFromSupplierList) {
				await dispatch(storeNewProject(formData, newProjectFromSupplierList));
				toast({
					title: "Success",
					description: formValidationMessages.success,
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			}
			if (editProject) {
				await dispatch(updateProject(projectId, formData));
				toast({
					title: "Success",
					description: "Project updated",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (err) {
			toast({
				title: "Error",
				description: formValidationMessages.error,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			console.error("Project submission error:", err);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<div className="relative">
			<TabHeader
				icon={<BackButton handleBackButtonClick={handleBackButtonClick} />}
				heading={
					newProject || newProjectFromSupplierList
						? "Create Project/Asset"
						: `Update Project/Asset ${project?.name ? `"${project.name}"` : ""}`
				}
				isLoading={loadProject}
			/>
			<div className="container-content relative h-full min-h-[60vh]">
				{!loadProject ? (
					<Formik
						initialValues={newProject ? initialValues : initialValuesProject}
						onSubmit={handleSubmit}
						validationSchema={validationSchema}
						enableReinitialize
					>
						{({ values, setErrors, setFieldError, isValid }) => (
							<Form>
								<VStack
									id="wrapper__project-form"
									spacing={2}
									align="stretch"
								>
									<Grid
										templateColumns="repeat(2, 1fr)"
										gap={6}
										columnGap={6}
									>
										<FieldForm
											label="Project Name"
											placeholder="Add Project Name"
											required={true}
											name="name"
											maxLength={100}
										/>
										<FieldForm
											label="Sector (Select one or more)"
											placeholder="Add sectors"
											name="sector"
											type="select"
											required={true}
											isMultiple={true}
											options={sectorFilter}
											isSearchable={false}
											testId="sector"
										/>
										<Location
											label="Project Location"
											name="address"
											required={true}
											errorBottom={"unset"}
											rfq
											className="relative"
											maxLength={255}
											allowInternational
										/>

										<UserSelectDropDown
											label="Project Lead"
											placeholder="Select Project Lead"
											name="project_lead_id"
										/>
										<GridItem colSpan={2}>
											<FormatedInput
												label="Project Description"
												name="description"
												required={true}
												characterLimit={1500}
												rfq
												className="w-full"
												placeholder="Describe the project scope, objectives and key details"
												setErrors={setErrors}
												setFieldError={setFieldError}
												errorMessage="Add a project description"
											/>
										</GridItem>
										<FileUpload
											label="Client Logo"
											description="Upload a square logo (recommended size 300x300px). Accepted formats: JPG, PNG (max 10MB)"
											name="logo_path"
											types={["jpg", "png"]}
											maxFileSize={10}
											required={true}
											defaultFileName="Client Logo"
											value={values.logo_path || ""}
											testId="client-logo"
										/>
										<FileUpload
											label="Project Image"
											description="Upload a banner image (recommended size 730x400px). Accepted formats: JPG, PNG (max 10MB)"
											name="background_image_path"
											types={["jpg", "png"]}
											maxFileSize={10}
											defaultFileName="Project Image"
											required={true}
											value={values.background_image_path || ""}
											testId="project-image"
										/>
									</Grid>
								</VStack>

								<Flex
									justifyContent="end"
									className="gap-8 py-12"
								>
									<TextButton
										onClick={handleBackButtonClick}
										className="cursor-pointer"
									>
										Cancel
									</TextButton>
									<div className="buttons-container flex">
										<Submit
											className="max-h-12 capitalize"
											type="submit"
											isSubmitting={isLoading}
											spinner
											text={
												newProject ? (
													<>
														<Icon
															icon="plus"
															className="mr-1.5 [&>svg]:h-3 [&>svg]:w-3"
														/>
														Create Project/Asset
													</>
												) : (
													"Update Project/Asset"
												)
											}
											submittingText={
												newProject
													? "Creating Project/Asset..."
													: "Updating Project/Asset..."
											}
											disabled={isLoading || !isValid}
										/>
									</div>
								</Flex>
							</Form>
						)}
					</Formik>
				) : (
					<Spinner
						className="absolute left-1/2 top-[30vh] -translate-x-1/2"
						size="xl"
					/>
				)}
			</div>
		</div>
	);
};

export default ProjectForm;
