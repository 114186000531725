import React, { useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { CustomModal } from "components/RFQ/Modal/CustomModal";
import { Button } from "components/RFQ/Button";

export const RestartSubscriptionModal = ({
	isOpen,
	setIsOpen,
	restart_subs,
	currentInvoice,
}) => {
	const [sending, setSending] = useState(false);

	const handleClose = () => {
		setIsOpen(false);
	};

	const restartHandle = async () => {
		setSending(true);
		try {
			await restart_subs().then((res) => {
				setSending(false);
				setIsOpen(false);
			});
		} catch (e) {
			const error = await e;
			setSending(false);
			setIsOpen(false);
			console.log(error);
		}
	};

	const options = {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	};
	const formatSeperator = (number) => {
		return Number(number).toLocaleString("en", options);
	};

	const dialogProperty = {
		icon: `blue-circle-check`,
		bgColor: "bg-brand-secondary",
		iconColor: "text-utility-brand-700",
		title: `Confirmation`,
		description: `We're delighted to have you back! Your new subscription begins today for another year, and the total amount for this period is $ ${formatSeperator(
			currentInvoice?.charged_amount / 100 - currentInvoice?.gst
		)} (Excl. GST) \n Please confirm to proceed with this renewal.`,
	};

	const FooterComponent = () => {
		return (
			<div className="flex w-full justify-between gap-2">
				<Button
					className="!w-full"
					btntype={"secondary"}
					onClick={handleClose}
				>
					Close
				</Button>
				<Button
					className="!w-full !normal-case"
					btntype="primary"
					onClick={restartHandle}
					disabled={sending}
					data-testid={"confirm-restart-subscription-button"}
				>
					{sending ? <Spinner /> : "Confirm"}
				</Button>
			</div>
		);
	};

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			header={dialogProperty}
			footerComponent={<FooterComponent />}
		/>
	);
};
