import React from "react";
import { cn } from "utils/helpers";

type MenuTermsPropsType = {
	className?: string;
};

export default function MenuTerms() {
	return (
		<div className="ml-5 flex gap-4 pb-2 text-xs text-fq-quinary-400">
			<a
				href=" https://www.procuracon.com.au/"
				target="_blank"
				rel="noreferrer"
				className="text-fq-quinary-400"
			>
				About Procuracon
			</a>
			<TermsAndConditionLink />
			<PrivacyPolicyLink />
		</div>
	);
}

export const TermsAndConditionLink: React.FC<MenuTermsPropsType> = ({
	className,
}) => {
	return (
		<a
			href="https://procuracon-44031413.hubspotpagebuilder.com/terms-conditions"
			target="_blank"
			rel="noreferrer"
			className={cn("text-fq-quinary-400", className)}
		>
			Terms & Conditions
		</a>
	);
};

export const PrivacyPolicyLink: React.FC<MenuTermsPropsType> = ({
	className,
}) => {
	return (
		<a
			href="https://procuracon-44031413.hubspotpagebuilder.com/privacy-policy"
			target="_blank"
			rel="noreferrer"
			className={cn("text-fq-quinary-400", className)}
		>
			Privacy Policy
		</a>
	);
};
