import React from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { RefineResultsStyled } from "./styled";
import { Heading } from "../Heading";
import { SearchTagRow } from "../SearchTagRow";
import { CompanySize } from "../FormInputs/CompanySize";
import { Region } from "../FormInputs/Region";
import { Submit } from "../FormInputs/Submit";
import { searchCompanies } from "../../actions/searchActions";
import { LocalGovtAssociation } from "../FormInputs/LocalGovtAssociation";
import { Checkbox } from "../FormInputs/Checkbox/Checkbox";

const RefineResultsConnected = ({
	open,
	handleClick,
	searchCriteria,
	disciplines,
	sectors,
	types,
	searchCompanies,
	groupId,
	trades,
	specialities,
}) => {
	const initialValues = {
		location: searchCriteria.location || "",
		sector_id: searchCriteria.sector_id || "",
		size: searchCriteria.size || "",
		discipline: searchCriteria.discipline || "",
		expertise: searchCriteria.expertise || "",
		profile_type: searchCriteria.profile_type || "",
		trades: searchCriteria.trades,
		specialities: searchCriteria.specialities,
		company_id: searchCriteria.company_id || "",
		project_id: 1,
		group_id: parseInt(groupId),
		local_govt_association_id: searchCriteria.local_govt_association_id || "",
		indigenous_buisness:
			searchCriteria.indigenous_buisness === 1 ? true : false || false,
	};

	const handleRefineSubmit = (values) => {
		if (values.indigenous_buisness === true) values.indigenous_buisness = 1;
		else values.indigenous_buisness = null;
		searchCompanies(values, true);
		handleClick();
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={(values) => handleRefineSubmit(values)}
		>
			{(props) => {
				const { isSubmitting, values } = props;
				return (
					<>
						<RefineResultsStyled open={open}>
							<div className="refineResults__container">
								<div
									className="refineResults__row"
									style={{
										margin: "0px 0px ",
									}}
								>
									<Heading fontSize="5.25rem">Refine Results</Heading>
								</div>
								<SearchTagRow
									searchCriteria={searchCriteria}
									disciplines={disciplines}
									sectors={sectors}
									types={types}
									trades={trades}
									specialities={specialities}
									open={open}
									handleRefineButtonClick={handleClick}
								/>
								<Form>
									<div className="input-row flex items-center gap-2">
										<Region
											name="location"
											label="Location"
											className="flex-1"
											value={values.location}
											testId="refine-location"
										/>

										<CompanySize
											name="size"
											required="true"
											className="flex-1"
											label="Size"
											value={values.size}
											profileType={values.profile_type}
											guideLine="This is the size of the business relevant to your State or Territory (include all other offices located within the same State or Territory)."
											testId="refine-size"
										/>

										<LocalGovtAssociation
											name="local_govt_association_id"
											profileType={values.profile_type}
											value={values.local_govt_association_id}
											className="flex-1"
											required={true}
											state={values.location}
											clearAble
										/>
										<Checkbox
											label="Indigenous Business"
											name="indigenous_buisness"
											className="flex-1"
											style={{ marginTop: "auto", marginBottom: "auto" }}
										/>
										<div className="-mt-6 ml-auto">
											<Submit
												type="submit"
												className="refineResults__submit"
												isSubmitting={isSubmitting}
												text="Update"
												refineResults
												submittingText="Updating..."
												mt="xs"
												testId="refine-submit"
											/>
										</div>
									</div>
								</Form>
							</div>
						</RefineResultsStyled>
					</>
				);
			}}
		</Formik>
	);
};

export const RefineResults = connect(null, { searchCompanies })(
	RefineResultsConnected
);
