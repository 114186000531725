import React from "react";
import { cn } from "utils/helpers";
import { styled, css } from "twin.macro";

export const ProgressCircular = ({
	size = "lg",
	bottom,
	progress,
	detail,
	half,
	className,
}) => {
	const sizes = {
		xs: {
			width: 64,
			height: 64,
			stroke: 6,
			style: "!text-xs ",
		},
		sm: {
			width: 160,
			height: 160,
			stroke: 16,
			style: "!text-2xl ",
		},
		md: {
			width: 200,
			height: 200,
			stroke: 20,
			style: "!text-3xl ",
		},
		lg: {
			width: 240,
			height: 240,
			stroke: 24,
			style: "!text-4xl ",
		},
		xl: {
			width: 280,
			height: 280,
			stroke: 28,
			style: "!text-5xl ",
		},
	};

	const radius = (sizes[size].width - sizes[size].stroke) / 2;
	const halfsize = sizes[size].width / 2;
	const circumference = radius * 3.14 * 2;
	const dasharrayValue = (progress * circumference) / 100;
	const StyledSvg = styled.svg(() => [
		css`
			circle {
				cx: ${halfsize};
				cy: ${half ? halfsize * 2 : halfsize};
				r: ${radius};
				stroke-width: ${sizes[size].stroke};
				fill: none;
				stroke-linecap: round;
			}
			circle.bg {
				stroke: #ddd;
			}

			circle.fg {
				transform: rotate(${half ? "180deg" : "-90deg"});
				transform-origin: ${halfsize}px ${half ? halfsize * 2 : halfsize}px;
				stroke-dasharray: ${half ? dasharrayValue / 2 : dasharrayValue}
					${circumference - dasharrayValue};
				transition: stroke-dasharray 0.3s linear 0s;
				stroke: ${progress === 0 ? "#ddd" : `#0045F5`};
			}
		`,
	]);

	const isHalfandSmall = size === "xs" && half;

	return (
		<div
			className={cn(
				"relative flex w-max items-center justify-between gap-2",
				size === "xs" && "flex-col items-end",
				className
			)}
		>
			<StyledSvg
				width={sizes[size].width}
				height={sizes[size].height}
				viewBox={`0 0 ${sizes[size].width} ${sizes[size].height}`}
				class="circular-progress"
			>
				<circle className="bg"></circle>
				<circle className="fg"></circle>
			</StyledSvg>
			<div
				className={cn(
					"absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center !font-semibold",
					half && "top-[85%]",
					size === "xs" && "top-[38%]",
					isHalfandSmall && "top-[65%]",
					sizes[size].style
				)}
			>
				{size !== "xs" && (
					<p
						className={cn(
							"text-xs font-normal",
							(size === "lg" || size === "xl") && "text-sm"
						)}
					>
						{detail}
					</p>
				)}
			</div>
			{size === "xs" && (
				<p
					className={cn(
						"absolute left-1/2 top-[60%] w-max -translate-x-[50%] translate-y-1/2 text-xs font-bold",
						(size === "lg" || size === "xl") && "text-sm"
					)}
				>
					{detail}
				</p>
			)}
		</div>
	);
};
