import { Heading } from "components/Heading";
import { cn } from "utils/helpers";

export const SectionComponent = ({
	className,
	sectionTitle,
	description,
	fieldValue,
	rfqSubmission = false,
	...props
}) => {
	return (
		<>
			{rfqSubmission ? (
				<div className="space-y-2 border-b border-fg-senary-300 py-6">
					<Heading className="text-4xl !text-royal-blue">
						{sectionTitle}
					</Heading>
					<p className="text-lg text-tertiary-600">{description}</p>
				</div>
			) : (
				<div
					className={cn("mt-6 min-h-14 border-b", className)}
					{...props}
				>
					<h1 className="text-xl font-bold">{sectionTitle}</h1>
					<p>{description}</p>
				</div>
			)}
		</>
	);
};
