import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
	LoginWrapper,
	LeftSection,
	RightSection,
	LoginSection,
	Logo,
	Tagline,
} from "./styled";
import { Heading } from "../Heading";

import { verifyEmail } from "actions/authActions";
import { logoutUser } from "../../actions/authActions";
import logo from "./../../icons/logo-text.svg";
import tagline from "./../../icons/tagline.svg";
import rightImg from "./../../icons/login-right-img.jpg";
import { Button } from "../Button";
import { navigate } from "@reach/router";

export const VerifyEmailComponent = ({ user, ...props }) => {
	useEffect(() => {
		if (!user) navigate("/login");
		if (user.email_verified) navigate("/search");
	}, [user]);
	const logout = async () => {
		await props.logoutUser();
	};

	return (
		<LoginWrapper>
			<LeftSection>
				<a href="/">
					<Logo
						src={logo}
						alt="Procuracon Logo"
					/>
				</a>
				<Tagline
					src={tagline}
					alt="Build Possible"
				/>

				<LoginSection>
					<Heading marginBottom="50px">
						<span>Please Verify your email to login</span>
					</Heading>
					<p className="instructions">
						Please check your inbox to verify email. If you can't find
						verification link please check your spam.
					</p>
					<Button
						marginTop="12px"
						onClick={logout}
						data-testid={"logout-button"}
					>
						<span className="px-4">Logout</span>
					</Button>
				</LoginSection>
			</LeftSection>
			<RightSection
				style={{ backgroundImage: `url(${rightImg})` }}
			></RightSection>
		</LoginWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export const VerifyEmail = connect(mapStateToProps, {
	logoutUser,
	verifyEmail,
})(VerifyEmailComponent);
