import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { PaymentFormWrapper, FormLabel } from "../SignUpForm/styled";
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from "@stripe/react-stripe-js";
import { FlashError } from "../FormInputs/FlashError";
import { navigate } from "@reach/router";
import LabelForm from "components/RFQ/LabelForm/LabelForm";

export const UpdateCardDetails = ({ disableHeading, ...props }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [focusField, setFocusField] = React.useState(null);
	const user_id = props.user.id ? props.user.id : null;
	const CardStyle = {
		base: {
			fontSize: "18px",
			color: "#6F7C8F",
			border: "1px solid rgba(132, 146, 166, 0.2)",
			"::placeholder": {
				color: "#6F7C8F",
			},
		},
		invalid: {
			color: "#6F7C8F",
		},
		complete: {
			color: "#6F7C8F",
		},
	};

	const handleSubmit = async (values, setStatus, setSubmitting) => {
		setSubmitting(true);
		setStatus(null);

		if (!stripe || !elements) {
			setSubmitting(false);
			return;
		}
		const cardNumber = elements.getElement(CardNumberElement);

		const token = await stripe.createToken(cardNumber, {
			name: values.card_name,
		});
		const { paymentMethod, error } = await stripe.createPaymentMethod({
			type: "card",
			card: cardNumber,
			billing_details: {
				name: token.name,
				address: {
					city: token.address_city,
					country: token.country,
					postal_code: token.address_zip,
					state: token.address_state,
				},
			},
		});
		if (error) {
			setStatus(error.message);
		} else {
			try {
				values.token_id = token.token.id;
				values.id = user_id;
				const result = await props.handleEditDetails(
					values,
					setSubmitting,
					paymentMethod.card.last4
				);
				if (!result.success) setStatus(result.message);
				if (props.company.account_status === "cancelled") {
					navigate("./?tab=Account Management");
				}
			} catch (e) {
				const error = await e;
				console.log(error);
				setSubmitting(false);
			}
		}
		setSubmitting(false);
	};

	const isFocus = (field) => field === focusField;
	const focusHandler = (field) => setFocusField(field);
	const blurHandler = () => setFocusField(null);

	const fieldClassName = {
		default: "!border-utility-brand-300 !ring-4 ring-ring-blue-primary",
		error: "!border-utility-error-300 !ring-4 ring-ring-red-primary",
	};

	return (
		<>
			{!disableHeading && (
				<div className="mb-5">
					<div className="font-roboto text-[18px] font-semibold text-primary-900">
						Update Card details
					</div>
					<p className="mt-2 font-roboto text-sm text-tertiary-600">
						Please enter your card details below
					</p>
				</div>
			)}
			<Formik
				enableReinitialize
				initialValues={{ card_value: "" }}
				validationSchema={Yup.object({
					card_name: Yup.string().required(),
				})}
				onSubmit={(values, { setStatus, setSubmitting }) => {
					handleSubmit(values, setStatus, setSubmitting);
				}}
			>
				{(props) => {
					const { setFieldValue, isSubmitting, status, setStatus } = props;
					const onSubmitValidationError = () => {
						setStatus("required");
					};
					return (
						<Form>
							<div className="row">
								<TextInput
									label="Name on Card"
									placeholder="Enter Name On Card"
									name="card_name"
									type="text"
									required
									className="col xl-12 text-mr"
									setFieldValue={setFieldValue}
								/>
								<PaymentFormWrapper>
									<div className="col xl-12">
										<LabelForm
											label="Credit Card Number"
											required={true}
										/>
										<div
											className={`card-number ${isFocus("number") && fieldClassName.default}`}
											data-testid={"payment-card-number"}
										>
											<CardNumberElement
												onFocus={() => focusHandler("number")}
												onBlur={blurHandler}
												className="stripe_card_field"
												options={{
													showIcon: true,
													placeholder: "xxxx xxxx xxxx xxxx",
													style: CardStyle,
													disabled: isSubmitting,
												}}
											/>
										</div>
									</div>

									<div className="flex gap-6">
										<div className="flex-1">
											<LabelForm
												label="Expiry Date"
												required={true}
											/>
											<div
												className={`card-number ${isFocus("expiry") && fieldClassName.default}`}
												data-testid={"payment-card-expiry"}
											>
												<CardExpiryElement
													onFocus={() => focusHandler("expiry")}
													onBlur={blurHandler}
													className="stripe_card_field"
													options={{
														placeholder: "mm / yy",
														style: CardStyle,
														disabled: isSubmitting,
													}}
												/>
											</div>
										</div>
										<div className="flex-1">
											<FormLabel>CVC *</FormLabel>
											<div
												className={`card-number ${isFocus("cvc") && fieldClassName.default}`}
												data-testid={"payment-card-cvc"}
											>
												<CardCvcElement
													onFocus={() => focusHandler("cvc")}
													onBlur={blurHandler}
													className="stripe_card_field"
													options={{
														placeholder: "xxx",
														style: CardStyle,
														disabled: isSubmitting,
													}}
												/>
											</div>
										</div>
									</div>
								</PaymentFormWrapper>
								<div className="col xl-12">
									{status && (
										<FlashError
											heading={
												errorMessages[status]?.heading ||
												"Invalid Card Information"
											}
											text={errorMessages[status]?.description || status}
											margin="20px 0px 0px 0px"
										/>
									)}
								</div>
								<div className="col xl-12">
									<Submit
										type="submit"
										widthExpand
										isSubmitting={isSubmitting}
										text="Update Card Details"
										marginTop="26px"
										submittingText="Sending..."
									/>
								</div>
							</div>
							<OnSubmitValidationError callback={onSubmitValidationError} />
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

const errorMessages = {
	required: {
		heading: "* Required Field",
		description: "There are incomplete required fields. Please complete them.",
	},
	backend: {
		heading: "Request Not Processed",
		description: "Unable to process your request",
	},
};
