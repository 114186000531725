import { Flex } from "@chakra-ui/react";
import { DateInput } from "components/DateInput";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { Location } from "components/FormInputs/Location";
import { Heading } from "components/Heading";
import { FieldForm } from "components/RFQ/Input/Input";
import UserSelectDropDown from "components/RFQ/UserSelectDropDown/UserSelectDropDown";
import TimePickerField from "components/TimePicker/TimePicker";
import { useFormikContext } from "formik";
import React from "react";
import tw, { styled } from "twin.macro";

const ProjectDetailContainer = styled.div(() => [tw`flex flex-col w-full`]);

const ProjectDetail = ({ titlePage, subTitlePage, rfqTypes }) => {
	const { setFieldError } = useFormikContext();
	return (
		<ProjectDetailContainer>
			<div
				id="subheader-page"
				className="py-8"
			>
				<div
					id="subheader-page-title"
					className="text-xl font-semibold text-primary-900"
				>
					<Heading
						fontSize={"36px"}
						color="#003CE9"
					>
						{" "}
						{titlePage}
					</Heading>
				</div>
				<div
					id="subheader-page-subtitle"
					className="mt-1 font-roboto text-sm text-tertiary-600"
				>
					{subTitlePage}
				</div>
			</div>
			<div
				id="project-detail-content"
				className="relative h-full min-h-[60vh]"
			>
				<Flex
					gap={8}
					className="items-baseline"
				>
					<FieldForm
						label="Request Title"
						placeholder="Eg. Request for Lead Design and Engineering Services"
						name="name"
						options={rfqTypes}
						required={true}
						className="mb-6"
						maxLength={255}
					/>
					<FieldForm
						label="RFx ID Number"
						placeholder="RFx123"
						name="rfq_id_number"
						className="mb-6"
						maxLength={50}
					/>
				</Flex>
				<Flex
					gap={8}
					className="items-baseline"
				>
					<FieldForm
						label="Type of Request"
						placeholder="Select type"
						name="type"
						type="select"
						checkIcon={true}
						options={rfqTypes}
						required={true}
						className="mb-6"
						testId={"rfx_type_select"}
					/>
					<Location
						label="Project Location"
						name="address"
						errorBottom={"-3px"}
						rfq
						rfqIcon="search-sm"
						className="relative"
						required={true}
						allowInternational
						inputTestId={"project_location"}
						dropdownTestId={"location_auto_complete"}
					/>
				</Flex>
				<Flex
					gap={8}
					className="items-baseline pb-4"
				>
					<UserSelectDropDown
						label="RFx Lead"
						name="rfx_lead_id"
						description={
							"The RFx Lead manages this RFx, including receiving notifications, updates, and responding to Bidder queries.\nThe RFx Lead email address and phone number will also be displayed on the RFx documents shared to all participants."
						}
					/>
				</Flex>

				<Flex>
					<FormatedInput
						label="Project / Asset Description"
						name="description"
						characterLimit={1500}
						rfq
						className="w-full"
						placeholder="Enter a description..."
						subTitle={
							"Briefly describe the project or asset. Consider the scale, size, quantity or staging and any other important features."
						}
						setFieldError={setFieldError}
					/>
				</Flex>
				<Flex gap={8}>
					<FieldForm
						label="Client"
						placeholder="Enter full name"
						name="client"
						required={true}
						className="mb-6"
					/>
				</Flex>
				<Flex className="items-baseline gap-8">
					<DateInput
						icon={true}
						label="Submission Deadline"
						name="deadline_date"
						minDate={new Date()}
						className="!font-regular flex-3 w-[80%] !border-fg-disabled_subtle"
						required={true}
					/>
					<div className="sm:w-[25%]">
						<TimePickerField
							className="mt-9 w-full flex-1"
							name="deadline_time"
							nameTimezone="submission_deadline_timezone"
							label=""
							hasDefaultValue
						/>
					</div>
				</Flex>
				<Flex>
					<FormatedInput
						label="Requested Services"
						name="escope_of_service"
						characterLimit={1500}
						rfq
						className="w-full"
						placeholder="Enter a description..."
						subTitle={
							"Provide a brief description of the scope of services needed for this project."
						}
						containerClassName="!mt-4"
					/>
				</Flex>
			</div>
		</ProjectDetailContainer>
	);
};

export default ProjectDetail;
