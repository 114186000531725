import { TextAreaQuestionnaire } from "./_components/TextAreaQuestionnaire";
import QuestionTypeTableBidder from "./QuestionTypeTableBidder";
import { SingleChoiceQuestionnaire } from "./_components/SingleChoiceQuestionnaire";
import { MultipleChoiceQuestionnaire } from "./_components/MultipleChoiceQuestionnaire";
import { SectionComponent } from "./_components/SectionComponent";
import { SmartFieldQuestionnaire } from "./_components/SmartFieldQuestionnaire";
import { DropdownQuestionnaire } from "./_components/DropdownQuestionnaire";
import { DateSelectionQuestionnaire } from "./_components/DateSelectionQuestionnaire";
import { AddressFinderQuestionnaire } from "./_components/AddressFinderQuestionnaire";
import { FileInputQuestionnaire } from "./_components/FileInputQuestionnaire";
import { SubmissionSmartFieldPreviewContextProvider } from "context/RFQSubmittedResponseContext";
import { DetailModal } from "components/Pages/RFQDetail/RFQSubmission/RFQSubmissionView";
// supplier - bidder
export const renderQuestion = (
	questionProps,
	index,
	hasDocuments,
	hasResources,
	hasPasProjects,
	disabled,
	procurementMethods,
	company
) => {
	const { ...question } = questionProps;
	const { question: val, description, ...questionGuest } = questionProps;

	switch (question.type) {
		case "short":
			return (
				<TextAreaQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "long":
			return (
				<TextAreaQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "singleChoice":
			return (
				<SingleChoiceQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "multipleChoice":
			return (
				<MultipleChoiceQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "dropdown":
			return (
				<DropdownQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "date":
			return (
				<DateSelectionQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "address":
			return (
				<AddressFinderQuestionnaire
					key={index}
					{...question}
				/>
			);
		case "projectexp":
			return question?.isGuest || !hasPasProjects ? (
				<TextAreaQuestionnaire
					description="Maximum of 4500 characters."
					question={question?.question}
					{...questionGuest}
					typeQuestion="4500"
				/>
			) : (
				<SubmissionSmartFieldPreviewContextProvider>
					<SmartFieldQuestionnaire
						key={index}
						smartField="Project Experience"
						{...question}
					/>
					<DetailModal
						procurementMethods={procurementMethods}
						company={company}
					/>
				</SubmissionSmartFieldPreviewContextProvider>
			);
		case "keypersonel":
			return question?.isGuest || !hasResources ? (
				<TextAreaQuestionnaire
					description="Maximum of 4500 characters."
					question={question?.question}
					{...questionGuest}
					typeQuestion="4500"
				/>
			) : (
				<SubmissionSmartFieldPreviewContextProvider>
					<SmartFieldQuestionnaire
						key={index}
						smartField="Key Personnel"
						{...question}
					/>
					<DetailModal
						procurementMethods={procurementMethods}
						company={company}
					/>
				</SubmissionSmartFieldPreviewContextProvider>
			);
		case "corporatedoc":
			return question?.isGuest || !hasDocuments ? (
				<FileInputQuestionnaire
					question={question?.question}
					{...questionGuest}
					singleFile={!question?.isGuest}
					isCorporateDocument
				/>
			) : (
				<SmartFieldQuestionnaire
					key={index}
					smartField="Corporate Documents"
					{...question}
				/>
			);
		case "file":
			return (
				<FileInputQuestionnaire
					key={index}
					{...question}
					singleFile={false}
				/>
			);
		case "section":
			return (
				<SectionComponent
					key={index}
					{...question}
				/>
			);
		case "table":
			return (
				<QuestionTypeTableBidder
					key={index}
					tableQuestions={question?.fieldValue}
					{...question}
				/>
			);
		default:
			return null;
	}
};
