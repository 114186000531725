import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Select } from "../Select";
import { getDocumentCategories } from "../../../actions/companyActions";

export const DocumentCategoryComponent = ({
	name,
	options,
	setFieldValue,
	defaultValue,
	label,
	required,
	values,
	getDocumentCategories,
	companyId,
	testId,
	...props
}) => {
	useEffect(() => {
		getDocumentCategories(companyId || "");
	}, [getDocumentCategories, companyId]);

	let optionsArray = [];
	if (options.length) {
		optionsArray = options
			.filter((o) => o.parent_id === 0 && o.id !== 8)
			.map((option) => ({ value: option.id, label: option.name }));
	}

	let value = optionsArray.find(
		(option) => option.value === parseInt(values[name])
	);

	return (
		<Select
			label={label || "Category"}
			required={required}
			options={optionsArray}
			name={name}
			setFieldValue={setFieldValue}
			value={value}
			className={props.className}
			testId={testId}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		companyId: state.search.activeCompany.profile_type.id,
		options: state.companyProfile.documentCategories,
	};
};

export const DocumentCategory = connect(mapStateToProps, {
	getDocumentCategories,
})(DocumentCategoryComponent);
