import {
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Button } from "components/RFQ/Button";
import { Input } from "components/RFQ/Input/Input";
import React, { useState } from "react";

const AddSectionModal = ({
	isOpen,
	onClose,
	OptionSections,
	addSectionHandler,
	addQuestionHandler,
	modalType,
	sections,
}) => {
	const isSection = modalType === "section" ? true : false;

	const questionOptions = sections
		?.map((section, index) => ({
			value: index,
			label: section.name ? section.name : "Section " + (index + 1),
			isRemove: section.isRemove || 0,
		}))
		.filter((section) => !section.isRemove || section?.isRemove !== 1);

	const [selected, setSelected] = useState(null);

	const onClickAddSection = () => {
		if (isSection) {
			addSectionHandler();
		} else {
			addQuestionHandler(selected);
		}
		onClose();
		const cardElement = document.getElementById(
			`question-${selected}-${sections[selected]?.questionnaire?.length - 2 || 0}`
		);

		if (cardElement) {
			cardElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			autoFocus={false}
			returnFocusOnClose={false}
			isCentered
		>
			<ModalOverlay />
			<ModalContent containerProps={{ sx: { height: "100vh" } }}>
				<ModalHeader>
					<div>
						<h2 className="text-lg font-semibold text-primary-900">{`Add ${isSection ? "section" : "question"}`}</h2>
						<p className="text-sm font-normal text-tertiary-600">
							{isSection
								? "Create a new section to group your questions."
								: "Create a new question within the selected section below:"}
						</p>
					</div>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Input
						label="Select section"
						placeholder={
							<Flex
								gap={3}
								align={"center"}
							>
								<Icon icon="section" />{" "}
								<div>Select {isSection ? "section" : "section"}</div>{" "}
							</Flex>
						}
						name="section"
						options={isSection ? OptionSections : questionOptions}
						type="select"
						onChange={(e) => setSelected(e.value)}
					/>
				</ModalBody>

				<ModalFooter>
					<Flex
						gap={3}
						className="!w-full"
					>
						<Button
							btntype={"base"}
							style={{ width: "100%" }}
							onClick={onClose}
						>
							Cancel
						</Button>
						<Button
							style={{ width: "100%" }}
							onClick={onClickAddSection}
							data-testid="submit-button"
						>
							Confirm
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default AddSectionModal;
