import React, { useEffect } from "react";
import { FieldArray, useField, useFormikContext } from "formik";
import { connect } from "react-redux";

import {
	ExpertiseRepeaterStyled,
	ExpertiseRepeaterRow,
	RemoveButton,
} from "./styled";
import { Select } from "../Select";
import { MultiSelect } from "../MultiSelect";
import { RepeaterButton } from "../../RepeaterButton";
import {
	getExpertiseDisciplines,
	getDisciplinesByProfileType,
} from "../../../actions/companyActions";

export const ExpertiseRepeaterConnected = ({
	className,
	name,
	values,
	options,
	profileType,
	getDisciplinesByProfileType,
	getExpertiseDisciplines,
	required,
	testId,
	...props
}) => {
	const { setFieldValue } = useFormikContext();
	// eslint-disable-next-line
	const [field, meta] = useField(name);
	const isError = meta.touched && meta.error;
	//receive these from server
	const handleExpertiseChange = (option, field, disciplineField) => {
		setFieldValue(disciplineField, []);
		setFieldValue(field.name, option.value);
	};

	useEffect(() => {
		if (profileType) {
			getDisciplinesByProfileType(profileType);
		}
	}, [getExpertiseDisciplines, getDisciplinesByProfileType, profileType]);

	if (!options) {
		return <h4>Loading disciplines..</h4>;
	}

	return (
		<ExpertiseRepeaterStyled className={className}>
			<FieldArray
				name={name}
				render={(arrayHelpers) => (
					<div>
						{values &&
							values.length > 0 &&
							values.map((values, index) => {
								return (
									<div key={index}>
										{index !== 0 && (
											<RemoveButton
												type="button"
												remove
												onClick={() => arrayHelpers.remove(index)}
												data-testid={`${testId}-remove-button`}
											>
												Remove Row
											</RemoveButton>
										)}

										<ExpertiseRepeaterRow className="!grid grid-cols-2 gap-6">
											<Select
												label={profileType === 3 ? "Service" : "Expertise"}
												name={`${name}.${index}.expertise`}
												className="col-span-1"
												options={Object.keys(options)}
												onChange={(option, field) =>
													handleExpertiseChange(
														option,
														field,
														`${name}.${index}.disciplines`
													)
												}
												freeText
												testId={`${testId}-single`}
												required={required}
												error={isError}
											/>

											<MultiSelect
												label={profileType === 3 ? "Category" : "Discipline"}
												name={`${name}.${index}.disciplines`}
												className="col-span-1"
												options={options[values.expertise]}
												defaultValue={values.disciplines}
												required={required}
												testId={`${testId}-multi`}
												errorLeftAlign
											/>
										</ExpertiseRepeaterRow>
									</div>
								);
							})}

						<RepeaterButton
							small
							text="Add Expertise Row"
							testId={`${testId}-add-row-button`}
							handleAddClick={() => {
								arrayHelpers.push({ expertise: "", disciplines: [] });
							}}
						/>
					</div>
				)}
			/>
		</ExpertiseRepeaterStyled>
	);
};

ExpertiseRepeaterConnected.defaultProps = {
	required: false,
};

const mapStateToProps = (state) => {
	return {
		options: state.companyProfile.expertiseDisciplinesByProfile,
	};
};

export const ExpertiseRepeater = connect(mapStateToProps, {
	getDisciplinesByProfileType,
	getExpertiseDisciplines,
})(ExpertiseRepeaterConnected);
