import React, { useState, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import { Input } from "components/RFQ/Input";
import { useQuery } from "@tanstack/react-query";
import { getDocumentCategories } from "actions/RFQ/rfqsAction";
import { Button } from "components/Button";
import { Icon } from "assets/icons/Icon";
import { useField } from "formik";

const DocumentCategorySelections = ({ formik, name }) => {
	// initialize with one empty selection set
	const [categorySelections, setCategorySelections] = useState([
		{ id: Date.now(), category: "", subcategory: "" },
	]);

	// Get the field path for fields
	const fieldsPath =
		typeof name === "function" ? name("fields") : `${name}.fields`;

	// eslint-disable-next-line no-unused-vars
	const [field, meta, helper] = useField(fieldsPath);

	// check if there are any valid selections with categories
	const hasValidCategories = meta.value?.allow_document_categories?.length > 0;

	const hasError = meta.touched && !hasValidCategories;

	const {
		data: rawResponse,
		isLoading,
		error,
		isError,
	} = useQuery({
		queryKey: ["documentCategories"],
		queryFn: getDocumentCategories,
		staleTime: 5 * 60 * 1000,
		gcTime: 10 * 60 * 1000,
	});

	// Initialize from existing values when component mounts
	useEffect(() => {
		// Check if we have existing values in the field
		const currentValue = meta.value || {};

		if (
			currentValue.allow_document_categories &&
			Array.isArray(currentValue.allow_document_categories) &&
			currentValue.allow_document_categories.length > 0
		) {
			// convert the API format to component state format
			const initialSelections = currentValue.allow_document_categories.map(
				(item) => ({
					id: Date.now() + Math.random(),
					category: item.category_id?.toString() || "",
					subcategory: item.subcategory_id?.toString() || "",
				})
			);
			setCategorySelections(initialSelections);
		}
	}, [meta.value]);

	// extract the categories array from the response
	const categoriesData = React.useMemo(() => {
		if (!rawResponse) return [];

		// API returns { data: [...] }
		if (rawResponse.data && Array.isArray(rawResponse.data)) {
			return rawResponse.data;
		}
		return [];
	}, [rawResponse]);

	// Process main categories (parent_id === 0)
	const mainCategories = React.useMemo(() => {
		if (!Array.isArray(categoriesData)) return [];

		const filtered = categoriesData
			.filter((cat) => cat && cat.parent_id === 0)
			.map((cat) => ({
				label: cat.name,
				value: cat.id.toString(),
			}));

		return filtered;
	}, [categoriesData]);

	// Get subcategories for a specific parent category
	const getSubcategories = (categoryId) => {
		if (!Array.isArray(categoriesData) || !categoryId) {
			return [];
		}

		// extract the actual ID value if an object was passed
		const parentId =
			typeof categoryId === "object" && categoryId.value
				? categoryId.value
				: categoryId;

		const filtered = categoriesData
			.filter(
				(cat) =>
					cat &&
					cat.parent_id &&
					cat.parent_id.toString() === parentId.toString()
			)
			.map((cat) => ({
				label: cat.name,
				value: cat.id.toString(),
			}));

		return filtered;
	};

	// Extract value from various event types (common function)
	const extractValue = (event) => {
		if (event && event.target && event.target.value) {
			// Handle DOM event
			return event.target.value;
		} else if (
			event &&
			typeof event === "object" &&
			event.value !== undefined
		) {
			// Handle select component object
			return event.value;
		} else {
			// Handle direct value
			return event;
		}
	};

	// Handle field change (generic handler for both category and subcategory)
	const handleFieldChange = (id, field, event) => {
		const value = extractValue(event);

		const updatedSelections = categorySelections.map((selection) => {
			if (selection.id === id) {
				// If changing category, reset subcategory
				if (field === "category") {
					return { ...selection, category: value, subcategory: "" };
				}
				// Otherwise just update the specified field
				return { ...selection, [field]: value };
			}
			return selection;
		});

		setCategorySelections(updatedSelections);
		updateFormikValues(updatedSelections);
	};

	// Use the generic handler with specific field names
	const handleCategoryChange = (id, event) => {
		handleFieldChange(id, "category", event);
	};

	const handleSubcategoryChange = (id, event) => {
		handleFieldChange(id, "subcategory", event);
	};

	// Add a new category selection set
	// const addCategorySelection = () => {
	// 	setCategorySelections([
	// 		...categorySelections,
	// 		{ id: Date.now(), category: "", subcategory: "" },
	// 	]);
	// };

	// Remove a category selection set
	const removeCategorySelection = (id) => {
		if (categorySelections.length > 1) {
			const updatedSelections = categorySelections.filter(
				(selection) => selection.id !== id
			);

			setCategorySelections(updatedSelections);
			updateFormikValues(updatedSelections);
		}
	};

	const updateFormikValues = (selections) => {
		// check if there are any valid selections (with category)
		const validSelections = selections.filter(({ category }) => category);

		// format the selections for the API - ALWAYS include "allow_document_categories" array - so even it's empty Yup can validate it properly
		const formattedSelections = {
			type: "corporate_documents",
			allow_document_categories: validSelections.map(
				({ category, subcategory }) => ({
					category_id: category,
					...(subcategory ? { subcategory_id: subcategory } : {}),
				})
			),
		};

		helper.setValue(formattedSelections);
		helper.setTouched(true);

		// also set the entire form as touched
		if (formik && formik.setFieldTouched) {
			formik.setFieldTouched(fieldsPath, true);
		}
	};

	return (
		<div className="mb-6">
			{isLoading && (
				<div className="mb-2 text-blue-500">Loading categories...</div>
			)}
			{isError && (
				<div className="mb-2 text-red-500">
					Error loading categories: {error?.message || "Unknown error"}
				</div>
			)}

			{categorySelections.map((selection, index) => (
				<Flex
					key={selection.id}
					gap={4}
					mb={4}
				>
					<div className="flex-1">
						<Input
							type="select"
							placeholder="Select Document Category*"
							options={mainCategories}
							value={selection.category}
							onChange={(e) => handleCategoryChange(selection.id, e)}
							required
							className="text-secondary-700"
							isLoading={isLoading}
							error={hasError ? "Document category is required" : ""}
						/>
					</div>

					<div className="flex-1">
						<Input
							type="select"
							placeholder="Select Document Sub-Category (Optional)"
							options={getSubcategories(selection.category)}
							value={selection.subcategory}
							onChange={(e) => handleSubcategoryChange(selection.id, e)}
							disabled={!selection.category}
							className="text-secondary-700"
						/>
					</div>

					{categorySelections.length > 1 && (
						<Button
							type="button"
							btntype="linkDanger"
							className="inline-flex cursor-pointer items-center rounded-md"
							onClick={() => removeCategorySelection(selection.id)}
						>
							<Icon
								icon="close"
								className="ml-1"
							/>
						</Button>
					)}
				</Flex>
			))}

			{/* <Button
				type="button"
				btntype="link"
				gap={2}
				align="center"
				className="!my-4 !mb-0 inline-flex cursor-pointer"
				onClick={addCategorySelection}
			>
				<Icon
					icon="plus"
					style={{ color: "#0045F5", width: "18px", height: "18px" }}
				/>
				<span className="text-sm font-medium">
					Add required document category
				</span>
			</Button> */}
		</div>
	);
};

export default DocumentCategorySelections;
