import { SmartFieldProjectExperience } from "./SmartFieldProjectExperience";
import { SmartFieldKeyPersonnel } from "./SmartFieldKeyPersonnel";
import { SmartFieldCorporateDocument } from "./SmartFieldCorporateDocument";

export const SmartFieldQuestionnaire = ({ smartField, ...props }) => {
	// Render the appropriate component based on smartField
	switch (smartField) {
		case "Project Experience":
			return <SmartFieldProjectExperience {...props} />;
		case "Key Personnel":
			return <SmartFieldKeyPersonnel {...props} />;
		case "Corporate Documents":
			return <SmartFieldCorporateDocument {...props} />;
		default:
			return <div>Unknown smart field type: {smartField}</div>;
	}
};
