import React, { useEffect } from "react";
import { Select } from "../Select";
import { connect } from "react-redux";

import { getLocalGovtAssociations } from "actions/companyActions";

const LocalGovtAssociationConnected = ({
	name,
	label,
	defaultValue,
	value,
	getLocalGovtAssociations,
	options,
	noOptionsMessage,
	errorBottom,
	required,
	disabled,
	state,
	clearAble,
	testId,
	...props
}) => {
	useEffect(() => {
		getLocalGovtAssociations();
	}, [getLocalGovtAssociations]);

	useEffect(() => {}, [options]);
	if (value) {
		value = options
			? options.find((item) => {
					return item.value === parseInt(value);
				})
			: {};
	}

	if (defaultValue) {
		defaultValue = options
			? options.find((item) => {
					return item.value === parseInt(defaultValue);
				})
			: {};
	}

	return (
		<Select
			required={required}
			options={options}
			name={name}
			label={label || "Local Government Area"}
			className={props.className}
			value={value}
			debug
			errorBottom={errorBottom}
			noOptionsMessage={() => {
				return noOptionsMessage ? noOptionsMessage : "No options available";
			}}
			disabled={disabled}
			clearAble={clearAble}
			testId={testId}
		/>
	);
};

const mapStateToProps = (state, ownProps) => {
	let lgas = state.companyProfile.localGovtAssociations;
	lgas = lgas.filter((item) => item.state === ownProps.state);
	return {
		options: lgas
			? lgas.map((option) => ({ value: option.id, label: option.name }))
			: [],
	};
};

export const LocalGovtAssociation = connect(mapStateToProps, {
	getLocalGovtAssociations,
})(LocalGovtAssociationConnected);
