import { components } from "react-select";

/**
 * Generate custom components for react-select with testId support.
 *
 * @param {string} testId - The base testId for the multiselect component.
 * @returns {object} Custom components for react-select.
 */
export const createMultiSelectCustomTestIdComponents = (testId) => ({
	Control: (baseProps) => (
		<components.Control
			{...baseProps}
			data-testid={testId}
		/>
	),
	ClearIndicator: (baseProps) => (
		<components.ClearIndicator
			{...baseProps}
			data-testid={`${testId}-clear-button`}
		/>
	),
	Input: (baseProps) => (
		<components.Input
			{...baseProps}
			inputClassName="react-select-input"
			data-testid={`${testId}-input`}
		/>
	),
	MenuList: (baseProps) => (
		<components.MenuList
			{...baseProps}
			data-testid={`${testId}-menu-list`}
		/>
	),
});

/**
 * Generate custom components for react-select single select with testId support.
 *
 * @param {string} testId - The base testId for the single-select component.
 * @returns {object} Custom components for react-select.
 */
export const createSingleSelectCustomTestIdComponents = (testId) => ({
	Control: (baseProps) => (
		<components.Control
			{...baseProps}
			data-testid={testId}
		/>
	),
	Input: (baseProps) => (
		<components.Input
			{...baseProps}
			inputClassName="react-select-input"
			data-testid={`${testId}-input`}
		/>
	),
	MenuList: (baseProps) => (
		<components.MenuList
			{...baseProps}
			data-testid={`${testId}-menu-list`}
		/>
	),
});
