import React from "react";
import { Heading } from "components/Heading";
import RFQStatusComp from "components/RFQ/RFQStatusComp/RFQStatusComp";
import RfQResponseComp from "components/RFQ/RfQResponseComp/RfQResponseComp";
import moment from "moment";
import { SkeletonCircle } from "@chakra-ui/react";
import { Avatar } from "components/RFQ/Avatar";
import { TextBoxHTML } from "components/TextBoxHTML";
import { SkeletonBase } from "components/Skeleton";
import { PreviewOnlyComp } from "components/RFQ/PreviewOnlyComp/PreviewOnlyComp";
import { Divider } from "components/Divider";
import { Icon } from "assets/icons/Icon";

const ListHorizontalItem = ({ title, value }) => {
	return (
		<>
			<p
				style={{ width: "200px" }}
				className="text-gray-text-400"
			>
				{title}
			</p>
			<p
				className="font-roboto font-medium text-primary-900"
				style={{ fontSize: "16px" }}
			>
				{value}
			</p>
		</>
	);
};

export const JumbotronComp = ({ data, isLoading, submissionStatus }) => {
	const projectName = data?.project?.name;
	return (
		<div className="min-h-[430px] rounded-lg border border-solid border-[#EAECF0] bg-white p-10 shadow-lg">
			<div className="mb-2 flex w-full items-end justify-start">
				<div className="pb-1">
					{isLoading ? (
						<SkeletonCircle
							width={12}
							height={12}
							startColor="lightgray"
						/>
					) : (
						<Avatar
							avatar={data?.project?.logoPath}
							defaultImage="company"
							size="lg"
						/>
					)}
				</div>
				<div className="flex flex-grow flex-wrap items-center justify-between px-4 !leading-[50px]">
					<div className="">
						{!isLoading ? (
							<>
								{projectName && (
									<p className="text-base text-secondary-500">{projectName}</p>
								)}
								<Heading fontSize="3rem">
									<div className="text-royal-blue">{data?.name}</div>
								</Heading>
							</>
						) : (
							<SkeletonBase
								width="300px"
								height="36px"
							/>
						)}
					</div>
					<div>
						{!isLoading ? (
							<div style={{ float: "right" }}>
								<RfQResponseComp
									response={
										submissionStatus ? submissionStatus?.toUpperCase() : ""
									}
									size="p-4"
								></RfQResponseComp>
							</div>
						) : (
							<SkeletonBase
								width="100px"
								height="32px"
								className="!rounded-full"
							/>
						)}
					</div>
				</div>
			</div>
			<div className="mb-10 mt-4 flex pt-8 text-secondary-700">
				{!isLoading ? (
					<TextBoxHTML
						html={data?.description}
						className="text-lg"
					/>
				) : (
					<SkeletonBase
						width="100%"
						height="48px"
					/>
				)}
			</div>
			<div className="flex flex-col gap-5">
				<div className="border-t border-solid border-[#EAECF0] pt-3">
					{!isLoading ? (
						<>
							<p className="pb-2 font-roboto font-[16px] text-gray-text-400">
								Scope of Service
							</p>
							<TextBoxHTML
								html={data?.escopeOfService}
								className="text-base font-medium text-primary-900"
							/>
						</>
					) : (
						<>
							<SkeletonBase
								width="160px"
								className="mr-8"
							/>
							<SkeletonBase
								width="100%"
								height="100px"
							/>
						</>
					)}
				</div>
				<div className="flex flex-1 flex-wrap justify-between border-t border-solid border-[#EAECF0] pt-3">
					<div className="grid gap-y-3 md:grid-cols-1 lg:grid-cols-2">
						{!isLoading ? (
							<>
								<p
									style={{ width: "200px" }}
									className="text-gray-text-400"
								>
									Project Status
								</p>
								<p>
									<RFQStatusComp
										status={data?.project?.status}
										rounded="rounded-full"
									/>
								</p>

								<ListHorizontalItem
									title="RFx ID"
									value={data?.rfqIdNumber}
								/>
								<ListHorizontalItem
									title="Request Type"
									value={data?.type || "-"}
								/>
								<ListHorizontalItem
									title="Client"
									value={data?.client}
								/>
								<ListHorizontalItem
									title="Contact Name"
									value={data?.companyOwner?.contact_name || " - "}
								/>
								<ListHorizontalItem
									title="Project Location"
									value={data?.location}
								/>
								<ListHorizontalItem
									title="RFx Published"
									value={moment(data?.rfxPublished).format("dddd, D MMMM YYYY")}
								/>
								<ListHorizontalItem
									title="Submission Deadline"
									value={`${moment(data?.submissionDeadline).format(
										"dddd, D MMMM YYYY hh:mm A "
									)} ${data?.submissionTimezone}`}
								/>
							</>
						) : (
							Array(5)
								.fill(0)
								.map((_, index) => (
									<div
										key={`jumbotron-item-${index}`}
										className="flex gap-3"
									>
										<SkeletonBase
											key={`skeleton-left-${index}`}
											width="120px"
											className="mr-10"
										/>
										<SkeletonBase
											key={`skeleton-right-${index}`}
											width="200px"
										/>
									</div>
								))
						)}
					</div>
					{isLoading ? (
						Array(5)
							.fill(0)
							.map((_, index) => (
								<div
									key={`jumbotron-contact-${index}`}
									className="flex gap-3"
								>
									<SkeletonBase
										key={`skeleton-contact-left-${index}`}
										width="120px"
										className="mr-10"
									/>
									<SkeletonBase
										key={`skeleton-contact-right-${index}`}
										width="200px"
									/>
								</div>
							))
					) : (
						<div>
							<div className="flex gap-3 pt-4">
								<Icon icon="user3" /> <p>Contact Person</p>
							</div>
							<div className="grid gap-y-3 py-4 md:grid-cols-1 lg:grid-cols-2">
								<ListHorizontalItem
									title="Name"
									value={data?.rfxLead?.fullName || "-"}
								/>
								<ListHorizontalItem
									title="Email"
									value={data?.rfxLead?.email || "-"}
								/>
								<ListHorizontalItem
									title="Phone Number"
									value={data?.rfxLead?.phoneNumber || "-"}
								/>
							</div>
						</div>
					)}
					{!isLoading && (
						<div className="w-full">
							<Divider />
							{/* id RFx-container is defined in RFQPreview.js file */}
							<div className="mt-4">
								<a
									href="#rfx-container"
									className="mt-5"
								>
									<PreviewOnlyComp
										submissionStatus="submitted"
										label="Preview RFx below"
										icon="arrow-down"
									/>
								</a>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
