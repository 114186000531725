import { Flex, useDisclosure } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import React from "react";
import tw, { styled } from "twin.macro";
import { ToggleButton } from "../ToggleButton";
import ModalDeleteSection from "components/Pages/CreateRFQ/ModalDeleteSection/ModalDeleteSection";
import { useField } from "formik";
import { cn } from "utils/helpers";

const CardQuestionerStyled = styled.div(
	({ className, isSection, isPreview, isQuote }) => [
		tw`w-full flex flex-col bg-white pt-12 pb-0.5 px-8 h-full min-h-20 rounded-md border border-gray-200 relative mb-8 focus-within:ring-4 focus-within:ring-royal-blue focus-within:ring-opacity-30`,
		isSection ? tw`border-t-8 border-t-royal-blue` : tw`mx-auto w-[95%] mb-4`,
		(isPreview || isQuote) && tw`pt-3 mx-0 w-full`,
		className,
	]
);

const CardQuestioner = ({
	children,
	isSection = false,
	isSmartField,
	dragHandle,
	divider = true,
	className,
	index,
	sectionIndex,
	copyQuestionHandler,
	deleteQuestionHandler,
	copySectionHandler,
	deleteSectionHandler,
	copyQuotesHandler,
	deleteQuotesHandler,
	isQuote,
	label,
	isPreview = false,
	isDeletable,
	...props
}) => {
	const { isOpen, onClose } = useDisclosure();

	const fieldname = () =>
		isQuote
			? `quote[${sectionIndex}]`
			: isSection
				? `sections[${sectionIndex}]`
				: `sections[${sectionIndex}]questionnaire[${index}]`;
	const [, meta, helper] = useField(fieldname());

	const setRequiredHandler = () => {
		helper.setValue({
			...meta.value,
			required: isQuote ? 1 : meta.value.required ? 0 : 1,
		});
	};

	return (
		<>
			<CardQuestionerStyled
				className={`${className || ""} card-shadow`}
				isQuote={isQuote}
				isSection={isSection}
				isPreview={isPreview}
				{...props}
			>
				{label && (
					<h4
						className="absolute left-8 top-4 flex gap-1 font-roboto text-xl font-bold text-primary-700"
						data-testid={`${fieldname()}label`}
					>
						{label}{" "}
						<div className="font-roboto font-medium text-required">
							{" "}
							<span>*</span>
						</div>
					</h4>
				)}
				{!isPreview && (
					<div
						className="absolute left-1/2 top-4 -translate-x-1/2"
						{...dragHandle}
					>
						<Icon
							icon="drag-icon"
							style={{
								marginLeft: "auto",
								marginRight: "auto",
								cursor: "grab",
							}}
						/>
					</div>
				)}
				<div className="my-3">{children}</div>
				{divider && (
					<div className="w-full border-b border-gray-200 text-gray-200" />
				)}
				{!isPreview ? (
					<div
						className={cn("min-h-16 flex-grow", isSection && "min-h-[52px]")}
					>
						<Flex
							gap={5}
							align={"center"}
							className={`relative float-right ${isSection ? "my-4" : "my-2"}`}
						>
							<Icon
								icon="copy-icon"
								style={{ cursor: "pointer" }}
								onClick={
									isQuote
										? copyQuotesHandler
										: isSection
											? copySectionHandler
											: copyQuestionHandler
								}
							/>
							{isDeletable && (
								<Icon
									icon="trash-stroke"
									style={{ cursor: "pointer" }}
									onClick={
										isQuote
											? deleteQuotesHandler
											: isSection
												? deleteSectionHandler
												: deleteQuestionHandler
									}
								/>
							)}
							{!isQuote && (
								<>
									{(!isSection || isSmartField) && (
										<div className="border-l p-3 pr-0">
											{" "}
											<ToggleButton
												label={"Required"}
												onChange={setRequiredHandler}
												checked={meta?.value?.required}
											/>{" "}
										</div>
									)}
								</>
							)}
						</Flex>
					</div>
				) : (
					<></>
				)}
			</CardQuestionerStyled>

			<ModalDeleteSection
				isOpen={isOpen}
				onClose={onClose}
			/>
		</>
	);
};

export default CardQuestioner;
