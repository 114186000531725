import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import tw, { styled } from "twin.macro";
import { getCompanyUsers } from "actions/AccountManagementActions";
import { cn } from "utils/helpers";
import moment from "moment";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/RFQ/Table";
import { Icon } from "assets/icons/Icon";
import { Button } from "../Button";
import { Link } from "@reach/router";
import { navigate } from "@reach/router";
export const AccountUsersComponent = ({
	company_users,
	company,
	getCompanyUsers,
	tab,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		getCompanyUsers(company.id);
	}, [getCompanyUsers, company]);

	useEffect(() => {
		if (company_users && company_users.length >= 1) {
			setIsLoading(false);
		}
	}, [company_users]);

	const TableHeadComponent = ({ title, className }) => {
		return (
			<div
				className={cn("th font-roboto text-tertiary-600", className)}
				style={{ gap: "8px", alignItems: "center" }}
			>
				{title}
			</div>
		);
	};

	const SkeletonCustom = styled.div(() => [
		tw`animate-pulse bg-gray-300 w-full h-6`,
	]);

	const SkeleteonRow = () => (
		<TableRow>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
			<TableCell>
				<SkeletonCustom />
			</TableCell>
		</TableRow>
	);

	return (
		<>
			<div className="mb-5 mt-5 w-full rounded-lg border border-gray-200">
				<div className="mb-5 flex justify-between p-5">
					<div>
						<div className="font-roboto text-[18px] font-semibold text-primary-900">
							All Users
						</div>
						<p className="mt-2 font-roboto text-sm text-tertiary-600">
							Manage your company users and their account permissions here.
						</p>
					</div>

					<Button
						style={{ maxHeight: "48px" }}
						data-testid="create-new-user"
					>
						<Link
							className="flex items-center gap-[6px] text-white"
							to="create-user"
						>
							<div className="flex h-5 w-5 items-center justify-center">
								<Icon
									icon="plus"
									style={{
										width: "12px",
										height: "12px",
										color: "#fff",
										fontWeight: "bold",
									}}
								/>
							</div>
							<div className="font-roboto text-base font-semibold capitalize">
								Create User
							</div>
						</Link>
					</Button>
				</div>
				<Table className="border border-utility-gray-200">
					<TableHeader>
						<TableRow>
							<TableHead>
								<TableHeadComponent title="User"></TableHeadComponent>
							</TableHead>
							<TableHead>
								<TableHeadComponent title="Role"></TableHeadComponent>
							</TableHead>
							<TableHead>
								<TableHeadComponent title="Last Active"></TableHeadComponent>
							</TableHead>
							<TableHead>
								<TableHeadComponent title="Date Added"></TableHeadComponent>
							</TableHead>
							<TableHead>
								<TableHeadComponent title=""></TableHeadComponent>
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{!isLoading &&
							company_users.map((company, index) => {
								return (
									<TableRow key={company?.id}>
										<TableCell className="w-[20%] overflow-hidden">
											<div className="flex items-center justify-start gap-2">
												<div className="font-roboto text-sm font-medium text-primary-900">
													<p className="font-semibold">{company?.full_name}</p>
													<p className="mb-1 font-roboto text-sm font-normal text-tertiary-600">
														<a
															href="mailto:{company?.email}"
															className="text-tertiary-600"
														>
															{company?.email}
														</a>
													</p>
													<p className="font-roboto text-sm font-normal text-tertiary-600">
														<a
															href="tel:{company?.phone}"
															className="text-tertiary-600"
														>
															{company?.phone}
														</a>
													</p>
												</div>
											</div>
										</TableCell>
										<TableCell>
											<div className="font-roboto text-sm font-normal capitalize text-tertiary-600">
												{company?.position}
											</div>
										</TableCell>
										<TableCell>
											{company?.last_activity
												? moment(company?.last_activity).format("DD/MM/YYYY")
												: "No Activity"}
										</TableCell>
										<TableCell>
											{company?.created_at
												? moment(company?.created_at).format("DD/MM/YYYY")
												: "--"}
										</TableCell>
										<TableCell>
											<Button
												className="flex gap-2 !px-4 !py-2"
												data-testid={`edit-user-${company?.id}`}
												onClick={() => {
													navigate("edit-user-account", {
														state: {
															user: company,
															company_users: company_users,
														},
													});
												}}
											>
												<Icon
													icon="edit-icon"
													style={{ color: "#D0D5DD" }}
													className="cursor-pointer"
												/>
												Edit
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
						{isLoading && (
							<>
								<SkeleteonRow></SkeleteonRow>
								<SkeleteonRow></SkeleteonRow>
								<SkeleteonRow></SkeleteonRow>
							</>
						)}
					</TableBody>
				</Table>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	const company = state.auth.current_company;
	const company_users = state.accountManagement.companyUsers;
	return {
		company: company,
		company_users: company_users,
	};
};

export const AccountUsers = connect(mapStateToProps, { getCompanyUsers })(
	AccountUsersComponent
);
