import { checkIsNotEmpty, checkIsValidNumber } from "utils/validation";

// Helper functions
export const validateReturnable = (returnable) => {
	return returnable?.every((section) =>
		section?.question?.every(
			(question) =>
				!question.required || checkIsNotEmpty(question.rfqAnswer?.answer?.value)
		)
	);
};

export const validateQuotes = (quotes) => {
	return quotes?.every((quote) =>
		quote.items?.every((item) => {
			const { quantity, rate, unit } = checkIsNotEmpty(item.rqfQuoteAnswer)
				? item.rqfQuoteAnswer?.answer?.value
				: { quantity: 0, rate: 0, unit: 0 };
			return (
				checkIsValidNumber(quantity) &&
				checkIsValidNumber(rate) &&
				checkIsValidNumber(unit)
			);
		})
	);
};

export const getInitialErrors = (
	listReturnable,
	listQuotes,
	quoteData,
	returnableSchedulesData
) => {
	const isReturnableValid = validateReturnable(listReturnable);
	const isQuoteValid = validateQuotes(listQuotes);

	const returnableFormError = !isReturnableValid
		? { question_answer: {} }
		: null;
	const quoteFileError = !quoteData?.savedFile ? { quote_file: {} } : null;
	const returnableFileError = !returnableSchedulesData?.savedFile
		? { returnable_schedule_file: {} }
		: null;
	const quoteTableError = !isQuoteValid ? { quote_answer: {} } : null;

	const initialQuoteError =
		quoteData?.type === "file" ? quoteFileError : quoteTableError;
	const initialReturnableError =
		returnableSchedulesData?.type === "file"
			? returnableFileError
			: returnableFormError;

	return { ...initialReturnableError, ...initialQuoteError };
};

export const hasDocumentsForCategories = (question, documents) => {
	if (
		!documents?.length ||
		!question?.fields?.allow_document_categories?.length
	) {
		return false;
	}

	const allowedCategoryIds = question.fields.allow_document_categories.map(
		(category) => Number(category.category_id)
	);

	return documents.some((document) =>
		allowedCategoryIds.includes(document.category?.id)
	);
};
