import { useField } from "formik";
import { FieldForm } from "components/RFQ/Input";
import { cn } from "utils/helpers";

export const SingleChoiceQuestionnaire = ({
	className,
	description,
	question,
	options,
	required,
	fieldValue,
	...props
}) => {
	const [, { error }] = useField(props);

	return (
		<div
			className={cn(className)}
			{...props}
		>
			<h1 className="text-lg font-semibold text-secondary-700">
				{question}
				<span
					className={cn(
						"font-normal text-tertiary-600",
						error && props?.touchedField && "font-semibold text-red-600"
					)}
				>
					{required && <span className="text-red-600">{`*`}</span>}
					{required ? " (Required)" : " (Optional)"}
				</span>
			</h1>
			{props?.rfqSubmission && (
				<p className="text-sm text-tertiary-600">(Select one option only)</p>
			)}
			<div>
				<FieldForm
					type="radio"
					className="my-4 w-full"
					options={fieldValue.map((item, i) => ({
						label: item,
						value: i.toString(),
					}))}
					name={props?.name || "questionnaire"}
					border
					disabled={props?.disabled}
				/>
			</div>
		</div>
	);
};
